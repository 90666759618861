export const moveRange1HexFlyTestHexIDs = {
  safeAdjacentSameLevel_id: '1,0,-1',
  engagedAdjacentSameLevel_id: '0,0,0',
  noFallDamage_id: '2,-1,-1',
  revealedGlyph_id: '0,2,-2',
  beyondGlyph_id: '0,3,-3',
  peak_id: '3,-2,-1',
  beyondPeak_id: '4,-3,-1',
  overEnemy_id: '-1,-1,2',
}

export const moveRange1HexFlyMap = {
  boardHexes: {
    // ,player 2's test dummy start hex
    '0,1,-1': {
      q: 0,
      r: 1,
      s: -1,
      id: '0,1,-1',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: ['1'],
    },
    // player 1's enemy unit
    '0,-1,1': {
      q: 0,
      r: -1,
      s: 1,
      id: '0,-1,1',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: ['0'],
    },
    [moveRange1HexFlyTestHexIDs.safeAdjacentSameLevel_id]: {
      q: 1,
      r: 0,
      s: -1,
      id: moveRange1HexFlyTestHexIDs.safeAdjacentSameLevel_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    [moveRange1HexFlyTestHexIDs.noFallDamage_id]: {
      q: 2,
      r: -1,
      s: -1,
      id: moveRange1HexFlyTestHexIDs.noFallDamage_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 1,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexFlyTestHexIDs.peak_id]: {
      q: 3,
      r: -2,
      s: -1,
      id: moveRange1HexFlyTestHexIDs.peak_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 40,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexFlyTestHexIDs.beyondPeak_id]: {
      q: 4,
      r: -3,
      s: -1,
      id: moveRange1HexFlyTestHexIDs.beyondPeak_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexFlyTestHexIDs.overEnemy_id]: {
      // second "bad guy" will go here
      q: -1,
      r: -1,
      s: 2,
      id: moveRange1HexFlyTestHexIDs.overEnemy_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexFlyTestHexIDs.engagedAdjacentSameLevel_id]: {
      q: 0,
      r: 0,
      s: 0,
      id: moveRange1HexFlyTestHexIDs.engagedAdjacentSameLevel_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    // '1,-1,0': {
    //   q: 1,
    //   r: -1,
    //   s: 0,
    //   id: '1,-1,0',
    //   occupyingUnitID: '',
    //   isUnitTail: false,
    //   altitude: 15,
    //   terrain: 'grass',
    //   startzonePlayerIDs: [],
    // },
    [moveRange1HexFlyTestHexIDs.revealedGlyph_id]: {
      q: 0,
      r: 2,
      s: -2,
      id: moveRange1HexFlyTestHexIDs.revealedGlyph_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    [moveRange1HexFlyTestHexIDs.beyondGlyph_id]: {
      q: 0,
      r: 3,
      s: -3,
      id: moveRange1HexFlyTestHexIDs.beyondGlyph_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
  },
  hexMap: {
    mapId: 'moveRange1HexFlyMap',
    mapShape: 'hexagon',
    mapName: 'Move Range 1-Hex Fly Test Map',
    flat: false,
    mapSize: 4,
    hexSize: 10,
    mapWidth: 1,
    mapHeight: 1,
    glyphs: {
      '0,2,-2': {
        hexID: '0,2,-2',
        glyphID: 'move',
        isRevealed: true,
      },
    },
  },
}
