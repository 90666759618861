export const moveRange1HexWalkTestHexIDs = {
  safeAdjacentSameLevel_id: '1,0,-1',
  engagedAdjacentSameLevel_id: '0,0,0',
  disengageOne_id: '-2,1,1',
  basicAdjacentFall_id: '-1,1,0',
  majorAdjacentFall_id: '-1,2,-1',
  cannotClimbOver_id: '-2,3,-1',
  disengageOneAndFall_id: '-2,0,2',
  revealedGlyph_id: '0,2,-2',
  beyondRevealedGlyph_id: '-1,3,-2',
}

export const moveRange1HexWalkMap = {
  boardHexes: {
    '1,1,-2': {
      q: 1,
      r: 1,
      s: -2,
      id: '1,1,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    '0,1,-1': {
      // player 2's test dummy start hex
      q: 0,
      r: 1,
      s: -1,
      id: '0,1,-1',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: ['1'],
    },
    '0,-1,1': {
      // player 1's enemy unit
      q: 0,
      r: -1,
      s: 1,
      id: '0,-1,1',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: ['0'],
    },
    [moveRange1HexWalkTestHexIDs.basicAdjacentFall_id]: {
      // adjacent fall, jumping into a hole
      q: -1,
      r: 1,
      s: 0,
      id: moveRange1HexWalkTestHexIDs.basicAdjacentFall_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 11,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexWalkTestHexIDs.majorAdjacentFall_id]: {
      // adjacent fall, jumping into a hole
      q: -1,
      r: 2,
      s: -1,
      id: moveRange1HexWalkTestHexIDs.majorAdjacentFall_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 1,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexWalkTestHexIDs.cannotClimbOver_id]: {
      // adjacent fall, jumping into a hole
      q: -2,
      r: 3,
      s: -1,
      id: moveRange1HexWalkTestHexIDs.cannotClimbOver_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexWalkTestHexIDs.disengageOne_id]: {
      // the disengagement of bad-guy-1
      q: -2,
      r: 1,
      s: 1,
      id: moveRange1HexWalkTestHexIDs.disengageOne_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexWalkTestHexIDs.disengageOneAndFall_id]: {
      // the disengagement of bad-guy-1
      q: -2,
      r: 0,
      s: 2,
      id: moveRange1HexWalkTestHexIDs.disengageOneAndFall_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 11,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    '-1,-1,2': {
      // second "bad guy" will go here
      q: -1,
      r: -1,
      s: 2,
      id: '-1,-1,2',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    '-1,0,1': {
      q: -1,
      r: 0,
      s: 1,
      id: '-1,0,1',
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [''],
    },
    [moveRange1HexWalkTestHexIDs.engagedAdjacentSameLevel_id]: {
      q: 0,
      r: 0,
      s: 0,
      id: moveRange1HexWalkTestHexIDs.engagedAdjacentSameLevel_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    // '1,-1,0': {
    //   q: 1,
    //   r: -1,
    //   s: 0,
    //   id: '1,-1,0',
    //   occupyingUnitID: '',
    //   isUnitTail: false,
    //   altitude: 15,
    //   terrain: 'grass',
    //   startzonePlayerIDs: [],
    // },
    [moveRange1HexWalkTestHexIDs.safeAdjacentSameLevel_id]: {
      q: 1,
      r: 0,
      s: -1,
      id: moveRange1HexWalkTestHexIDs.safeAdjacentSameLevel_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    [moveRange1HexWalkTestHexIDs.revealedGlyph_id]: {
      q: 0,
      r: 2,
      s: -2,
      id: moveRange1HexWalkTestHexIDs.revealedGlyph_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
    [moveRange1HexWalkTestHexIDs.beyondRevealedGlyph_id]: {
      q: -1,
      r: 3,
      s: -2,
      id: moveRange1HexWalkTestHexIDs.beyondRevealedGlyph_id,
      occupyingUnitID: '',
      isUnitTail: false,
      altitude: 15,
      terrain: 'grass',
      startzonePlayerIDs: [],
    },
  },
  hexMap: {
    mapId: 'moveRangeMap',
    mapShape: 'hexagon',
    mapName: 'Move Range Test Map',
    flat: false,
    mapSize: 3,
    hexSize: 10,
    mapWidth: 1,
    mapHeight: 1,
    glyphs: {
      '0,2,-2': {
        hexID: '0,2,-2',
        glyphID: 'move',
        isRevealed: true,
      },
    },
  },
}
