export const forsakenWaters = {
  boardHexes: {
    '0,0,0': {
      q: 0,
      r: 0,
      s: 0,
      id: '0,0,0',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,0,-1': {
      q: 1,
      r: 0,
      s: -1,
      id: '1,0,-1',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '2,0,-2': {
      q: 2,
      r: 0,
      s: -2,
      id: '2,0,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '3,0,-3': {
      q: 3,
      r: 0,
      s: -3,
      id: '3,0,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '4,0,-4': {
      q: 4,
      r: 0,
      s: -4,
      id: '4,0,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '5,0,-5': {
      q: 5,
      r: 0,
      s: -5,
      id: '5,0,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '6,0,-6': {
      q: 6,
      r: 0,
      s: -6,
      id: '6,0,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '7,0,-7': {
      q: 7,
      r: 0,
      s: -7,
      id: '7,0,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '8,0,-8': {
      q: 8,
      r: 0,
      s: -8,
      id: '8,0,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '9,0,-9': {
      q: 9,
      r: 0,
      s: -9,
      id: '9,0,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '10,0,-10': {
      q: 10,
      r: 0,
      s: -10,
      id: '10,0,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '11,0,-11': {
      q: 11,
      r: 0,
      s: -11,
      id: '11,0,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '12,0,-12': {
      q: 12,
      r: 0,
      s: -12,
      id: '12,0,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '13,0,-13': {
      q: 13,
      r: 0,
      s: -13,
      id: '13,0,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '14,0,-14': {
      q: 14,
      r: 0,
      s: -14,
      id: '14,0,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '15,0,-15': {
      q: 15,
      r: 0,
      s: -15,
      id: '15,0,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '16,0,-16': {
      q: 16,
      r: 0,
      s: -16,
      id: '16,0,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '17,0,-17': {
      q: 17,
      r: 0,
      s: -17,
      id: '17,0,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '18,0,-18': {
      q: 18,
      r: 0,
      s: -18,
      id: '18,0,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '19,0,-19': {
      q: 19,
      r: 0,
      s: -19,
      id: '19,0,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '20,0,-20': {
      q: 20,
      r: 0,
      s: -20,
      id: '20,0,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,1,-1': {
      q: 0,
      r: 1,
      s: -1,
      id: '0,1,-1',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,1,-2': {
      q: 1,
      r: 1,
      s: -2,
      id: '1,1,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '2,1,-3': {
      q: 2,
      r: 1,
      s: -3,
      id: '2,1,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '3,1,-4': {
      q: 3,
      r: 1,
      s: -4,
      id: '3,1,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '4,1,-5': {
      q: 4,
      r: 1,
      s: -5,
      id: '4,1,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '5,1,-6': {
      q: 5,
      r: 1,
      s: -6,
      id: '5,1,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 1,
    },
    '6,1,-7': {
      q: 6,
      r: 1,
      s: -7,
      id: '6,1,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '7,1,-8': {
      q: 7,
      r: 1,
      s: -8,
      id: '7,1,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '8,1,-9': {
      q: 8,
      r: 1,
      s: -9,
      id: '8,1,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '9,1,-10': {
      q: 9,
      r: 1,
      s: -10,
      id: '9,1,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '10,1,-11': {
      q: 10,
      r: 1,
      s: -11,
      id: '10,1,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '11,1,-12': {
      q: 11,
      r: 1,
      s: -12,
      id: '11,1,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '12,1,-13': {
      q: 12,
      r: 1,
      s: -13,
      id: '12,1,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '13,1,-14': {
      q: 13,
      r: 1,
      s: -14,
      id: '13,1,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '14,1,-15': {
      q: 14,
      r: 1,
      s: -15,
      id: '14,1,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '15,1,-16': {
      q: 15,
      r: 1,
      s: -16,
      id: '15,1,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '16,1,-17': {
      q: 16,
      r: 1,
      s: -17,
      id: '16,1,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '17,1,-18': {
      q: 17,
      r: 1,
      s: -18,
      id: '17,1,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '18,1,-19': {
      q: 18,
      r: 1,
      s: -19,
      id: '18,1,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '19,1,-20': {
      q: 19,
      r: 1,
      s: -20,
      id: '19,1,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '20,1,-21': {
      q: 20,
      r: 1,
      s: -21,
      id: '20,1,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,2,-1': {
      q: -1,
      r: 2,
      s: -1,
      id: '-1,2,-1',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,2,-2': {
      q: 0,
      r: 2,
      s: -2,
      id: '0,2,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,2,-3': {
      q: 1,
      r: 2,
      s: -3,
      id: '1,2,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '2,2,-4': {
      q: 2,
      r: 2,
      s: -4,
      id: '2,2,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '3,2,-5': {
      q: 3,
      r: 2,
      s: -5,
      id: '3,2,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '4,2,-6': {
      q: 4,
      r: 2,
      s: -6,
      id: '4,2,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '5,2,-7': {
      q: 5,
      r: 2,
      s: -7,
      id: '5,2,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '6,2,-8': {
      q: 6,
      r: 2,
      s: -8,
      id: '6,2,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '7,2,-9': {
      q: 7,
      r: 2,
      s: -9,
      id: '7,2,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '8,2,-10': {
      q: 8,
      r: 2,
      s: -10,
      id: '8,2,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '9,2,-11': {
      q: 9,
      r: 2,
      s: -11,
      id: '9,2,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,2,-12': {
      q: 10,
      r: 2,
      s: -12,
      id: '10,2,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,2,-13': {
      q: 11,
      r: 2,
      s: -13,
      id: '11,2,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '12,2,-14': {
      q: 12,
      r: 2,
      s: -14,
      id: '12,2,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 3,
    },
    '13,2,-15': {
      q: 13,
      r: 2,
      s: -15,
      id: '13,2,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '14,2,-16': {
      q: 14,
      r: 2,
      s: -16,
      id: '14,2,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '15,2,-17': {
      q: 15,
      r: 2,
      s: -17,
      id: '15,2,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '16,2,-18': {
      q: 16,
      r: 2,
      s: -18,
      id: '16,2,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '17,2,-19': {
      q: 17,
      r: 2,
      s: -19,
      id: '17,2,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '18,2,-20': {
      q: 18,
      r: 2,
      s: -20,
      id: '18,2,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '19,2,-21': {
      q: 19,
      r: 2,
      s: -21,
      id: '19,2,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,3,-2': {
      q: -1,
      r: 3,
      s: -2,
      id: '-1,3,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,3,-3': {
      q: 0,
      r: 3,
      s: -3,
      id: '0,3,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,3,-4': {
      q: 1,
      r: 3,
      s: -4,
      id: '1,3,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '2,3,-5': {
      q: 2,
      r: 3,
      s: -5,
      id: '2,3,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '3,3,-6': {
      q: 3,
      r: 3,
      s: -6,
      id: '3,3,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '4,3,-7': {
      q: 4,
      r: 3,
      s: -7,
      id: '4,3,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '5,3,-8': {
      q: 5,
      r: 3,
      s: -8,
      id: '5,3,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '6,3,-9': {
      q: 6,
      r: 3,
      s: -9,
      id: '6,3,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '7,3,-10': {
      q: 7,
      r: 3,
      s: -10,
      id: '7,3,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '8,3,-11': {
      q: 8,
      r: 3,
      s: -11,
      id: '8,3,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 8,
    },
    '9,3,-12': {
      q: 9,
      r: 3,
      s: -12,
      id: '9,3,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 7,
    },
    '10,3,-13': {
      q: 10,
      r: 3,
      s: -13,
      id: '10,3,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,3,-14': {
      q: 11,
      r: 3,
      s: -14,
      id: '11,3,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '12,3,-15': {
      q: 12,
      r: 3,
      s: -15,
      id: '12,3,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '13,3,-16': {
      q: 13,
      r: 3,
      s: -16,
      id: '13,3,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '14,3,-17': {
      q: 14,
      r: 3,
      s: -17,
      id: '14,3,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '15,3,-18': {
      q: 15,
      r: 3,
      s: -18,
      id: '15,3,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '16,3,-19': {
      q: 16,
      r: 3,
      s: -19,
      id: '16,3,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '17,3,-20': {
      q: 17,
      r: 3,
      s: -20,
      id: '17,3,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '18,3,-21': {
      q: 18,
      r: 3,
      s: -21,
      id: '18,3,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '19,3,-22': {
      q: 19,
      r: 3,
      s: -22,
      id: '19,3,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,4,-2': {
      q: -2,
      r: 4,
      s: -2,
      id: '-2,4,-2',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,4,-3': {
      q: -1,
      r: 4,
      s: -3,
      id: '-1,4,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,4,-4': {
      q: 0,
      r: 4,
      s: -4,
      id: '0,4,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '1,4,-5': {
      q: 1,
      r: 4,
      s: -5,
      id: '1,4,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '2,4,-6': {
      q: 2,
      r: 4,
      s: -6,
      id: '2,4,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '3,4,-7': {
      q: 3,
      r: 4,
      s: -7,
      id: '3,4,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '4,4,-8': {
      q: 4,
      r: 4,
      s: -8,
      id: '4,4,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '5,4,-9': {
      q: 5,
      r: 4,
      s: -9,
      id: '5,4,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '6,4,-10': {
      q: 6,
      r: 4,
      s: -10,
      id: '6,4,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,4,-11': {
      q: 7,
      r: 4,
      s: -11,
      id: '7,4,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 4,
    },
    '8,4,-12': {
      q: 8,
      r: 4,
      s: -12,
      id: '8,4,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 7,
    },
    '9,4,-13': {
      q: 9,
      r: 4,
      s: -13,
      id: '9,4,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 4,
    },
    '10,4,-14': {
      q: 10,
      r: 4,
      s: -14,
      id: '10,4,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,4,-15': {
      q: 11,
      r: 4,
      s: -15,
      id: '11,4,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,4,-16': {
      q: 12,
      r: 4,
      s: -16,
      id: '12,4,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '13,4,-17': {
      q: 13,
      r: 4,
      s: -17,
      id: '13,4,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '14,4,-18': {
      q: 14,
      r: 4,
      s: -18,
      id: '14,4,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '15,4,-19': {
      q: 15,
      r: 4,
      s: -19,
      id: '15,4,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '16,4,-20': {
      q: 16,
      r: 4,
      s: -20,
      id: '16,4,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '17,4,-21': {
      q: 17,
      r: 4,
      s: -21,
      id: '17,4,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '18,4,-22': {
      q: 18,
      r: 4,
      s: -22,
      id: '18,4,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,5,-3': {
      q: -2,
      r: 5,
      s: -3,
      id: '-2,5,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,5,-4': {
      q: -1,
      r: 5,
      s: -4,
      id: '-1,5,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'rock',
      altitude: 2,
    },
    '0,5,-5': {
      q: 0,
      r: 5,
      s: -5,
      id: '0,5,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'rock',
      altitude: 2,
    },
    '1,5,-6': {
      q: 1,
      r: 5,
      s: -6,
      id: '1,5,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'rock',
      altitude: 2,
    },
    '2,5,-7': {
      q: 2,
      r: 5,
      s: -7,
      id: '2,5,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,5,-8': {
      q: 3,
      r: 5,
      s: -8,
      id: '3,5,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '4,5,-9': {
      q: 4,
      r: 5,
      s: -9,
      id: '4,5,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '5,5,-10': {
      q: 5,
      r: 5,
      s: -10,
      id: '5,5,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,5,-11': {
      q: 6,
      r: 5,
      s: -11,
      id: '6,5,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '7,5,-12': {
      q: 7,
      r: 5,
      s: -12,
      id: '7,5,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 4,
    },
    '8,5,-13': {
      q: 8,
      r: 5,
      s: -13,
      id: '8,5,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 4,
    },
    '9,5,-14': {
      q: 9,
      r: 5,
      s: -14,
      id: '9,5,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,5,-15': {
      q: 10,
      r: 5,
      s: -15,
      id: '10,5,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,5,-16': {
      q: 11,
      r: 5,
      s: -16,
      id: '11,5,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,5,-17': {
      q: 12,
      r: 5,
      s: -17,
      id: '12,5,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '13,5,-18': {
      q: 13,
      r: 5,
      s: -18,
      id: '13,5,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '14,5,-19': {
      q: 14,
      r: 5,
      s: -19,
      id: '14,5,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 2,
    },
    '15,5,-20': {
      q: 15,
      r: 5,
      s: -20,
      id: '15,5,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '16,5,-21': {
      q: 16,
      r: 5,
      s: -21,
      id: '16,5,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '17,5,-22': {
      q: 17,
      r: 5,
      s: -22,
      id: '17,5,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'rock',
      altitude: 2,
    },
    '18,5,-23': {
      q: 18,
      r: 5,
      s: -23,
      id: '18,5,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,6,-3': {
      q: -3,
      r: 6,
      s: -3,
      id: '-3,6,-3',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,6,-4': {
      q: -2,
      r: 6,
      s: -4,
      id: '-2,6,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,6,-5': {
      q: -1,
      r: 6,
      s: -5,
      id: '-1,6,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '0,6,-6': {
      q: 0,
      r: 6,
      s: -6,
      id: '0,6,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '1,6,-7': {
      q: 1,
      r: 6,
      s: -7,
      id: '1,6,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,6,-8': {
      q: 2,
      r: 6,
      s: -8,
      id: '2,6,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,6,-9': {
      q: 3,
      r: 6,
      s: -9,
      id: '3,6,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '4,6,-10': {
      q: 4,
      r: 6,
      s: -10,
      id: '4,6,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '5,6,-11': {
      q: 5,
      r: 6,
      s: -11,
      id: '5,6,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,6,-12': {
      q: 6,
      r: 6,
      s: -12,
      id: '6,6,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,6,-13': {
      q: 7,
      r: 6,
      s: -13,
      id: '7,6,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '8,6,-14': {
      q: 8,
      r: 6,
      s: -14,
      id: '8,6,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '9,6,-15': {
      q: 9,
      r: 6,
      s: -15,
      id: '9,6,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '10,6,-16': {
      q: 10,
      r: 6,
      s: -16,
      id: '10,6,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '11,6,-17': {
      q: 11,
      r: 6,
      s: -17,
      id: '11,6,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,6,-18': {
      q: 12,
      r: 6,
      s: -18,
      id: '12,6,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '13,6,-19': {
      q: 13,
      r: 6,
      s: -19,
      id: '13,6,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '14,6,-20': {
      q: 14,
      r: 6,
      s: -20,
      id: '14,6,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '15,6,-21': {
      q: 15,
      r: 6,
      s: -21,
      id: '15,6,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '16,6,-22': {
      q: 16,
      r: 6,
      s: -22,
      id: '16,6,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '17,6,-23': {
      q: 17,
      r: 6,
      s: -23,
      id: '17,6,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,7,-4': {
      q: -3,
      r: 7,
      s: -4,
      id: '-3,7,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,7,-5': {
      q: -2,
      r: 7,
      s: -5,
      id: '-2,7,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,7,-6': {
      q: -1,
      r: 7,
      s: -6,
      id: '-1,7,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '0,7,-7': {
      q: 0,
      r: 7,
      s: -7,
      id: '0,7,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,7,-8': {
      q: 1,
      r: 7,
      s: -8,
      id: '1,7,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,7,-9': {
      q: 2,
      r: 7,
      s: -9,
      id: '2,7,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,7,-10': {
      q: 3,
      r: 7,
      s: -10,
      id: '3,7,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '4,7,-11': {
      q: 4,
      r: 7,
      s: -11,
      id: '4,7,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '5,7,-12': {
      q: 5,
      r: 7,
      s: -12,
      id: '5,7,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,7,-13': {
      q: 6,
      r: 7,
      s: -13,
      id: '6,7,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '7,7,-14': {
      q: 7,
      r: 7,
      s: -14,
      id: '7,7,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 5,
    },
    '8,7,-15': {
      q: 8,
      r: 7,
      s: -15,
      id: '8,7,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 5,
    },
    '9,7,-16': {
      q: 9,
      r: 7,
      s: -16,
      id: '9,7,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '10,7,-17': {
      q: 10,
      r: 7,
      s: -17,
      id: '10,7,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '11,7,-18': {
      q: 11,
      r: 7,
      s: -18,
      id: '11,7,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,7,-19': {
      q: 12,
      r: 7,
      s: -19,
      id: '12,7,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '13,7,-20': {
      q: 13,
      r: 7,
      s: -20,
      id: '13,7,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '14,7,-21': {
      q: 14,
      r: 7,
      s: -21,
      id: '14,7,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '15,7,-22': {
      q: 15,
      r: 7,
      s: -22,
      id: '15,7,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '16,7,-23': {
      q: 16,
      r: 7,
      s: -23,
      id: '16,7,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '17,7,-24': {
      q: 17,
      r: 7,
      s: -24,
      id: '17,7,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,8,-4': {
      q: -4,
      r: 8,
      s: -4,
      id: '-4,8,-4',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,8,-5': {
      q: -3,
      r: 8,
      s: -5,
      id: '-3,8,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,8,-6': {
      q: -2,
      r: 8,
      s: -6,
      id: '-2,8,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,8,-7': {
      q: -1,
      r: 8,
      s: -7,
      id: '-1,8,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '0,8,-8': {
      q: 0,
      r: 8,
      s: -8,
      id: '0,8,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,8,-9': {
      q: 1,
      r: 8,
      s: -9,
      id: '1,8,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,8,-10': {
      q: 2,
      r: 8,
      s: -10,
      id: '2,8,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,8,-11': {
      q: 3,
      r: 8,
      s: -11,
      id: '3,8,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '4,8,-12': {
      q: 4,
      r: 8,
      s: -12,
      id: '4,8,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '5,8,-13': {
      q: 5,
      r: 8,
      s: -13,
      id: '5,8,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '6,8,-14': {
      q: 6,
      r: 8,
      s: -14,
      id: '6,8,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 5,
    },
    '7,8,-15': {
      q: 7,
      r: 8,
      s: -15,
      id: '7,8,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 6,
    },
    '8,8,-16': {
      q: 8,
      r: 8,
      s: -16,
      id: '8,8,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 5,
    },
    '9,8,-17': {
      q: 9,
      r: 8,
      s: -17,
      id: '9,8,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '10,8,-18': {
      q: 10,
      r: 8,
      s: -18,
      id: '10,8,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '11,8,-19': {
      q: 11,
      r: 8,
      s: -19,
      id: '11,8,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,8,-20': {
      q: 12,
      r: 8,
      s: -20,
      id: '12,8,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '13,8,-21': {
      q: 13,
      r: 8,
      s: -21,
      id: '13,8,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '14,8,-22': {
      q: 14,
      r: 8,
      s: -22,
      id: '14,8,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '15,8,-23': {
      q: 15,
      r: 8,
      s: -23,
      id: '15,8,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '16,8,-24': {
      q: 16,
      r: 8,
      s: -24,
      id: '16,8,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,9,-5': {
      q: -4,
      r: 9,
      s: -5,
      id: '-4,9,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,9,-6': {
      q: -3,
      r: 9,
      s: -6,
      id: '-3,9,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,9,-7': {
      q: -2,
      r: 9,
      s: -7,
      id: '-2,9,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,9,-8': {
      q: -1,
      r: 9,
      s: -8,
      id: '-1,9,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '0,9,-9': {
      q: 0,
      r: 9,
      s: -9,
      id: '0,9,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,9,-10': {
      q: 1,
      r: 9,
      s: -10,
      id: '1,9,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,9,-11': {
      q: 2,
      r: 9,
      s: -11,
      id: '2,9,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '3,9,-12': {
      q: 3,
      r: 9,
      s: -12,
      id: '3,9,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 3,
    },
    '4,9,-13': {
      q: 4,
      r: 9,
      s: -13,
      id: '4,9,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '5,9,-14': {
      q: 5,
      r: 9,
      s: -14,
      id: '5,9,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '6,9,-15': {
      q: 6,
      r: 9,
      s: -15,
      id: '6,9,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 7,
    },
    '7,9,-16': {
      q: 7,
      r: 9,
      s: -16,
      id: '7,9,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 6,
    },
    '8,9,-17': {
      q: 8,
      r: 9,
      s: -17,
      id: '8,9,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '9,9,-18': {
      q: 9,
      r: 9,
      s: -18,
      id: '9,9,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '10,9,-19': {
      q: 10,
      r: 9,
      s: -19,
      id: '10,9,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,9,-20': {
      q: 11,
      r: 9,
      s: -20,
      id: '11,9,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,9,-21': {
      q: 12,
      r: 9,
      s: -21,
      id: '12,9,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '13,9,-22': {
      q: 13,
      r: 9,
      s: -22,
      id: '13,9,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '14,9,-23': {
      q: 14,
      r: 9,
      s: -23,
      id: '14,9,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '15,9,-24': {
      q: 15,
      r: 9,
      s: -24,
      id: '15,9,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '16,9,-25': {
      q: 16,
      r: 9,
      s: -25,
      id: '16,9,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,10,-5': {
      q: -5,
      r: 10,
      s: -5,
      id: '-5,10,-5',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,10,-6': {
      q: -4,
      r: 10,
      s: -6,
      id: '-4,10,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,10,-7': {
      q: -3,
      r: 10,
      s: -7,
      id: '-3,10,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,10,-8': {
      q: -2,
      r: 10,
      s: -8,
      id: '-2,10,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,10,-9': {
      q: -1,
      r: 10,
      s: -9,
      id: '-1,10,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '0,10,-10': {
      q: 0,
      r: 10,
      s: -10,
      id: '0,10,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,10,-11': {
      q: 1,
      r: 10,
      s: -11,
      id: '1,10,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 3,
    },
    '2,10,-12': {
      q: 2,
      r: 10,
      s: -12,
      id: '2,10,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '3,10,-13': {
      q: 3,
      r: 10,
      s: -13,
      id: '3,10,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 3,
    },
    '4,10,-14': {
      q: 4,
      r: 10,
      s: -14,
      id: '4,10,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '5,10,-15': {
      q: 5,
      r: 10,
      s: -15,
      id: '5,10,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '6,10,-16': {
      q: 6,
      r: 10,
      s: -16,
      id: '6,10,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'rock',
      altitude: 3,
    },
    '7,10,-17': {
      q: 7,
      r: 10,
      s: -17,
      id: '7,10,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '8,10,-18': {
      q: 8,
      r: 10,
      s: -18,
      id: '8,10,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '9,10,-19': {
      q: 9,
      r: 10,
      s: -19,
      id: '9,10,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,10,-20': {
      q: 10,
      r: 10,
      s: -20,
      id: '10,10,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,10,-21': {
      q: 11,
      r: 10,
      s: -21,
      id: '11,10,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,10,-22': {
      q: 12,
      r: 10,
      s: -22,
      id: '12,10,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '13,10,-23': {
      q: 13,
      r: 10,
      s: -23,
      id: '13,10,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '14,10,-24': {
      q: 14,
      r: 10,
      s: -24,
      id: '14,10,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '15,10,-25': {
      q: 15,
      r: 10,
      s: -25,
      id: '15,10,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,11,-6': {
      q: -5,
      r: 11,
      s: -6,
      id: '-5,11,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,11,-7': {
      q: -4,
      r: 11,
      s: -7,
      id: '-4,11,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,11,-8': {
      q: -3,
      r: 11,
      s: -8,
      id: '-3,11,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,11,-9': {
      q: -2,
      r: 11,
      s: -9,
      id: '-2,11,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,11,-10': {
      q: -1,
      r: 11,
      s: -10,
      id: '-1,11,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '0,11,-11': {
      q: 0,
      r: 11,
      s: -11,
      id: '0,11,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,11,-12': {
      q: 1,
      r: 11,
      s: -12,
      id: '1,11,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '2,11,-13': {
      q: 2,
      r: 11,
      s: -13,
      id: '2,11,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '3,11,-14': {
      q: 3,
      r: 11,
      s: -14,
      id: '3,11,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '4,11,-15': {
      q: 4,
      r: 11,
      s: -15,
      id: '4,11,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '5,11,-16': {
      q: 5,
      r: 11,
      s: -16,
      id: '5,11,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,11,-17': {
      q: 6,
      r: 11,
      s: -17,
      id: '6,11,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,11,-18': {
      q: 7,
      r: 11,
      s: -18,
      id: '7,11,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '8,11,-19': {
      q: 8,
      r: 11,
      s: -19,
      id: '8,11,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '9,11,-20': {
      q: 9,
      r: 11,
      s: -20,
      id: '9,11,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,11,-21': {
      q: 10,
      r: 11,
      s: -21,
      id: '10,11,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,11,-22': {
      q: 11,
      r: 11,
      s: -22,
      id: '11,11,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,11,-23': {
      q: 12,
      r: 11,
      s: -23,
      id: '12,11,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '13,11,-24': {
      q: 13,
      r: 11,
      s: -24,
      id: '13,11,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '14,11,-25': {
      q: 14,
      r: 11,
      s: -25,
      id: '14,11,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '15,11,-26': {
      q: 15,
      r: 11,
      s: -26,
      id: '15,11,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,12,-6': {
      q: -6,
      r: 12,
      s: -6,
      id: '-6,12,-6',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,12,-7': {
      q: -5,
      r: 12,
      s: -7,
      id: '-5,12,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-4,12,-8': {
      q: -4,
      r: 12,
      s: -8,
      id: '-4,12,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,12,-9': {
      q: -3,
      r: 12,
      s: -9,
      id: '-3,12,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,12,-10': {
      q: -2,
      r: 12,
      s: -10,
      id: '-2,12,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,12,-11': {
      q: -1,
      r: 12,
      s: -11,
      id: '-1,12,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '0,12,-12': {
      q: 0,
      r: 12,
      s: -12,
      id: '0,12,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'sand',
      altitude: 2,
    },
    '1,12,-13': {
      q: 1,
      r: 12,
      s: -13,
      id: '1,12,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,12,-14': {
      q: 2,
      r: 12,
      s: -14,
      id: '2,12,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,12,-15': {
      q: 3,
      r: 12,
      s: -15,
      id: '3,12,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '4,12,-16': {
      q: 4,
      r: 12,
      s: -16,
      id: '4,12,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '5,12,-17': {
      q: 5,
      r: 12,
      s: -17,
      id: '5,12,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,12,-18': {
      q: 6,
      r: 12,
      s: -18,
      id: '6,12,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,12,-19': {
      q: 7,
      r: 12,
      s: -19,
      id: '7,12,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'water',
      altitude: 1,
    },
    '8,12,-20': {
      q: 8,
      r: 12,
      s: -20,
      id: '8,12,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '9,12,-21': {
      q: 9,
      r: 12,
      s: -21,
      id: '9,12,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,12,-22': {
      q: 10,
      r: 12,
      s: -22,
      id: '10,12,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,12,-23': {
      q: 11,
      r: 12,
      s: -23,
      id: '11,12,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,12,-24': {
      q: 12,
      r: 12,
      s: -24,
      id: '12,12,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['1'],
      terrain: 'grass',
      altitude: 2,
    },
    '13,12,-25': {
      q: 13,
      r: 12,
      s: -25,
      id: '13,12,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '14,12,-26': {
      q: 14,
      r: 12,
      s: -26,
      id: '14,12,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,13,-7': {
      q: -6,
      r: 13,
      s: -7,
      id: '-6,13,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,13,-8': {
      q: -5,
      r: 13,
      s: -8,
      id: '-5,13,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-4,13,-9': {
      q: -4,
      r: 13,
      s: -9,
      id: '-4,13,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,13,-10': {
      q: -3,
      r: 13,
      s: -10,
      id: '-3,13,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,13,-11': {
      q: -2,
      r: 13,
      s: -11,
      id: '-2,13,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '-1,13,-12': {
      q: -1,
      r: 13,
      s: -12,
      id: '-1,13,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '0,13,-13': {
      q: 0,
      r: 13,
      s: -13,
      id: '0,13,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,13,-14': {
      q: 1,
      r: 13,
      s: -14,
      id: '1,13,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,13,-15': {
      q: 2,
      r: 13,
      s: -15,
      id: '2,13,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,13,-16': {
      q: 3,
      r: 13,
      s: -16,
      id: '3,13,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 5,
    },
    '4,13,-17': {
      q: 4,
      r: 13,
      s: -17,
      id: '4,13,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 5,
    },
    '5,13,-18': {
      q: 5,
      r: 13,
      s: -18,
      id: '5,13,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '6,13,-19': {
      q: 6,
      r: 13,
      s: -19,
      id: '6,13,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,13,-20': {
      q: 7,
      r: 13,
      s: -20,
      id: '7,13,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '8,13,-21': {
      q: 8,
      r: 13,
      s: -21,
      id: '8,13,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '9,13,-22': {
      q: 9,
      r: 13,
      s: -22,
      id: '9,13,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,13,-23': {
      q: 10,
      r: 13,
      s: -23,
      id: '10,13,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,13,-24': {
      q: 11,
      r: 13,
      s: -24,
      id: '11,13,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '12,13,-25': {
      q: 12,
      r: 13,
      s: -25,
      id: '12,13,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '13,13,-26': {
      q: 13,
      r: 13,
      s: -26,
      id: '13,13,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '14,13,-27': {
      q: 14,
      r: 13,
      s: -27,
      id: '14,13,-27',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-7,14,-7': {
      q: -7,
      r: 14,
      s: -7,
      id: '-7,14,-7',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,14,-8': {
      q: -6,
      r: 14,
      s: -8,
      id: '-6,14,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-5,14,-9': {
      q: -5,
      r: 14,
      s: -9,
      id: '-5,14,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: ['0'],
      terrain: 'grass',
      altitude: 2,
    },
    '-4,14,-10': {
      q: -4,
      r: 14,
      s: -10,
      id: '-4,14,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-3,14,-11': {
      q: -3,
      r: 14,
      s: -11,
      id: '-3,14,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-2,14,-12': {
      q: -2,
      r: 14,
      s: -12,
      id: '-2,14,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '-1,14,-13': {
      q: -1,
      r: 14,
      s: -13,
      id: '-1,14,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '0,14,-14': {
      q: 0,
      r: 14,
      s: -14,
      id: '0,14,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '1,14,-15': {
      q: 1,
      r: 14,
      s: -15,
      id: '1,14,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,14,-16': {
      q: 2,
      r: 14,
      s: -16,
      id: '2,14,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 6,
    },
    '3,14,-17': {
      q: 3,
      r: 14,
      s: -17,
      id: '3,14,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 8,
    },
    '4,14,-18': {
      q: 4,
      r: 14,
      s: -18,
      id: '4,14,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 5,
    },
    '5,14,-19': {
      q: 5,
      r: 14,
      s: -19,
      id: '5,14,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,14,-20': {
      q: 6,
      r: 14,
      s: -20,
      id: '6,14,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '7,14,-21': {
      q: 7,
      r: 14,
      s: -21,
      id: '7,14,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '8,14,-22': {
      q: 8,
      r: 14,
      s: -22,
      id: '8,14,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '9,14,-23': {
      q: 9,
      r: 14,
      s: -23,
      id: '9,14,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '10,14,-24': {
      q: 10,
      r: 14,
      s: -24,
      id: '10,14,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '11,14,-25': {
      q: 11,
      r: 14,
      s: -25,
      id: '11,14,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '12,14,-26': {
      q: 12,
      r: 14,
      s: -26,
      id: '12,14,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 3,
    },
    '13,14,-27': {
      q: 13,
      r: 14,
      s: -27,
      id: '13,14,-27',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-7,15,-8': {
      q: -7,
      r: 15,
      s: -8,
      id: '-7,15,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,15,-9': {
      q: -6,
      r: 15,
      s: -9,
      id: '-6,15,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,15,-10': {
      q: -5,
      r: 15,
      s: -10,
      id: '-5,15,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,15,-11': {
      q: -4,
      r: 15,
      s: -11,
      id: '-4,15,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,15,-12': {
      q: -3,
      r: 15,
      s: -12,
      id: '-3,15,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,15,-13': {
      q: -2,
      r: 15,
      s: -13,
      id: '-2,15,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,15,-14': {
      q: -1,
      r: 15,
      s: -14,
      id: '-1,15,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,15,-15': {
      q: 0,
      r: 15,
      s: -15,
      id: '0,15,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,15,-16': {
      q: 1,
      r: 15,
      s: -16,
      id: '1,15,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,15,-17': {
      q: 2,
      r: 15,
      s: -17,
      id: '2,15,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 9,
    },
    '3,15,-18': {
      q: 3,
      r: 15,
      s: -18,
      id: '3,15,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 8,
    },
    '4,15,-19': {
      q: 4,
      r: 15,
      s: -19,
      id: '4,15,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '5,15,-20': {
      q: 5,
      r: 15,
      s: -20,
      id: '5,15,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '6,15,-21': {
      q: 6,
      r: 15,
      s: -21,
      id: '6,15,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '7,15,-22': {
      q: 7,
      r: 15,
      s: -22,
      id: '7,15,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '8,15,-23': {
      q: 8,
      r: 15,
      s: -23,
      id: '8,15,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '9,15,-24': {
      q: 9,
      r: 15,
      s: -24,
      id: '9,15,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '10,15,-25': {
      q: 10,
      r: 15,
      s: -25,
      id: '10,15,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '11,15,-26': {
      q: 11,
      r: 15,
      s: -26,
      id: '11,15,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '12,15,-27': {
      q: 12,
      r: 15,
      s: -27,
      id: '12,15,-27',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '13,15,-28': {
      q: 13,
      r: 15,
      s: -28,
      id: '13,15,-28',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-8,16,-8': {
      q: -8,
      r: 16,
      s: -8,
      id: '-8,16,-8',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-7,16,-9': {
      q: -7,
      r: 16,
      s: -9,
      id: '-7,16,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,16,-10': {
      q: -6,
      r: 16,
      s: -10,
      id: '-6,16,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,16,-11': {
      q: -5,
      r: 16,
      s: -11,
      id: '-5,16,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,16,-12': {
      q: -4,
      r: 16,
      s: -12,
      id: '-4,16,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,16,-13': {
      q: -3,
      r: 16,
      s: -13,
      id: '-3,16,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,16,-14': {
      q: -2,
      r: 16,
      s: -14,
      id: '-2,16,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,16,-15': {
      q: -1,
      r: 16,
      s: -15,
      id: '-1,16,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,16,-16': {
      q: 0,
      r: 16,
      s: -16,
      id: '0,16,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,16,-17': {
      q: 1,
      r: 16,
      s: -17,
      id: '1,16,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '2,16,-18': {
      q: 2,
      r: 16,
      s: -18,
      id: '2,16,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,16,-19': {
      q: 3,
      r: 16,
      s: -19,
      id: '3,16,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '4,16,-20': {
      q: 4,
      r: 16,
      s: -20,
      id: '4,16,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '5,16,-21': {
      q: 5,
      r: 16,
      s: -21,
      id: '5,16,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '6,16,-22': {
      q: 6,
      r: 16,
      s: -22,
      id: '6,16,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '7,16,-23': {
      q: 7,
      r: 16,
      s: -23,
      id: '7,16,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '8,16,-24': {
      q: 8,
      r: 16,
      s: -24,
      id: '8,16,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '9,16,-25': {
      q: 9,
      r: 16,
      s: -25,
      id: '9,16,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '10,16,-26': {
      q: 10,
      r: 16,
      s: -26,
      id: '10,16,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '11,16,-27': {
      q: 11,
      r: 16,
      s: -27,
      id: '11,16,-27',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '12,16,-28': {
      q: 12,
      r: 16,
      s: -28,
      id: '12,16,-28',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-8,17,-9': {
      q: -8,
      r: 17,
      s: -9,
      id: '-8,17,-9',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-7,17,-10': {
      q: -7,
      r: 17,
      s: -10,
      id: '-7,17,-10',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-6,17,-11': {
      q: -6,
      r: 17,
      s: -11,
      id: '-6,17,-11',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-5,17,-12': {
      q: -5,
      r: 17,
      s: -12,
      id: '-5,17,-12',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-4,17,-13': {
      q: -4,
      r: 17,
      s: -13,
      id: '-4,17,-13',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-3,17,-14': {
      q: -3,
      r: 17,
      s: -14,
      id: '-3,17,-14',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-2,17,-15': {
      q: -2,
      r: 17,
      s: -15,
      id: '-2,17,-15',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '-1,17,-16': {
      q: -1,
      r: 17,
      s: -16,
      id: '-1,17,-16',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '0,17,-17': {
      q: 0,
      r: 17,
      s: -17,
      id: '0,17,-17',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '1,17,-18': {
      q: 1,
      r: 17,
      s: -18,
      id: '1,17,-18',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '2,17,-19': {
      q: 2,
      r: 17,
      s: -19,
      id: '2,17,-19',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '3,17,-20': {
      q: 3,
      r: 17,
      s: -20,
      id: '3,17,-20',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'grass',
      altitude: 2,
    },
    '4,17,-21': {
      q: 4,
      r: 17,
      s: -21,
      id: '4,17,-21',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '5,17,-22': {
      q: 5,
      r: 17,
      s: -22,
      id: '5,17,-22',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '6,17,-23': {
      q: 6,
      r: 17,
      s: -23,
      id: '6,17,-23',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '7,17,-24': {
      q: 7,
      r: 17,
      s: -24,
      id: '7,17,-24',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '8,17,-25': {
      q: 8,
      r: 17,
      s: -25,
      id: '8,17,-25',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '9,17,-26': {
      q: 9,
      r: 17,
      s: -26,
      id: '9,17,-26',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '10,17,-27': {
      q: 10,
      r: 17,
      s: -27,
      id: '10,17,-27',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '11,17,-28': {
      q: 11,
      r: 17,
      s: -28,
      id: '11,17,-28',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
    '12,17,-29': {
      q: 12,
      r: 17,
      s: -29,
      id: '12,17,-29',
      occupyingUnitID: '',
      isUnitTail: false,
      startzonePlayerIDs: [],
      terrain: 'void',
      altitude: 1,
    },
  },
  hexMap: {
    mapId: 'forsakenWaters',
    mapName: 'Forsaken Waters',
    mapShape: 'rectangle',
    flat: false,
    mapSize: 25,
    hexSize: 10,
    mapWidth: 21,
    mapHeight: 18,
    // glyphs: {},
    glyphs: {
      '5,11,-16': {
        hexID: '5,11,-16',
        glyphID: 'attack',
        isRevealed: true,
      },
      '2,17,-19': {
        hexID: '2,17,-19',
        glyphID: 'range',
        isRevealed: true,
      },
      '6,7,-13': {
        hexID: '6,7,-13',
        glyphID: 'defense',
        isRevealed: true,
      },
      '2,5,-7': {
        hexID: '2,5,-7',
        glyphID: 'move',
        isRevealed: true,
      },
      // '9,2,-11': {
      //   hexID: '9,2,-11',
      //   glyphID: 'move',
      //   isRevealed: true,
      // },
    },
  },
}
