export const cirdanGardenMap = {
  boardHexes: {
    '-10,0,10': {
      q: -10,
      r: 0,
      s: 10,
      id: '-10,0,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,1,9': {
      q: -10,
      r: 1,
      s: 9,
      id: '-10,1,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-10,2,8': {
      q: -10,
      r: 2,
      s: 8,
      id: '-10,2,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-10,3,7': {
      q: -10,
      r: 3,
      s: 7,
      id: '-10,3,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,4,6': {
      q: -10,
      r: 4,
      s: 6,
      id: '-10,4,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,5,5': {
      q: -10,
      r: 5,
      s: 5,
      id: '-10,5,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,6,4': {
      q: -10,
      r: 6,
      s: 4,
      id: '-10,6,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,7,3': {
      q: -10,
      r: 7,
      s: 3,
      id: '-10,7,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,8,2': {
      q: -10,
      r: 8,
      s: 2,
      id: '-10,8,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,9,1': {
      q: -10,
      r: 9,
      s: 1,
      id: '-10,9,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-10,10,0': {
      q: -10,
      r: 10,
      s: 0,
      id: '-10,10,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,-1,10': {
      q: -9,
      r: -1,
      s: 10,
      id: '-9,-1,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,0,9': {
      q: -9,
      r: 0,
      s: 9,
      id: '-9,0,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-9,1,8': {
      q: -9,
      r: 1,
      s: 8,
      id: '-9,1,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-9,2,7': {
      q: -9,
      r: 2,
      s: 7,
      id: '-9,2,7',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-9,3,6': {
      q: -9,
      r: 3,
      s: 6,
      id: '-9,3,6',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-9,4,5': {
      q: -9,
      r: 4,
      s: 5,
      id: '-9,4,5',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-9,5,4': {
      q: -9,
      r: 5,
      s: 4,
      id: '-9,5,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-9,6,3': {
      q: -9,
      r: 6,
      s: 3,
      id: '-9,6,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,7,2': {
      q: -9,
      r: 7,
      s: 2,
      id: '-9,7,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,8,1': {
      q: -9,
      r: 8,
      s: 1,
      id: '-9,8,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,9,0': {
      q: -9,
      r: 9,
      s: 0,
      id: '-9,9,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-9,10,-1': {
      q: -9,
      r: 10,
      s: -1,
      id: '-9,10,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,-2,10': {
      q: -8,
      r: -2,
      s: 10,
      id: '-8,-2,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,-1,9': {
      q: -8,
      r: -1,
      s: 9,
      id: '-8,-1,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,0,8': {
      q: -8,
      r: 0,
      s: 8,
      id: '-8,0,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,1,7': {
      q: -8,
      r: 1,
      s: 7,
      id: '-8,1,7',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,2,6': {
      q: -8,
      r: 2,
      s: 6,
      id: '-8,2,6',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,3,5': {
      q: -8,
      r: 3,
      s: 5,
      id: '-8,3,5',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,4,4': {
      q: -8,
      r: 4,
      s: 4,
      id: '-8,4,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-8,5,3': {
      q: -8,
      r: 5,
      s: 3,
      id: '-8,5,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-8,6,2': {
      q: -8,
      r: 6,
      s: 2,
      id: '-8,6,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,7,1': {
      q: -8,
      r: 7,
      s: 1,
      id: '-8,7,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,8,0': {
      q: -8,
      r: 8,
      s: 0,
      id: '-8,8,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,9,-1': {
      q: -8,
      r: 9,
      s: -1,
      id: '-8,9,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-8,10,-2': {
      q: -8,
      r: 10,
      s: -2,
      id: '-8,10,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-7,-3,10': {
      q: -7,
      r: -3,
      s: 10,
      id: '-7,-3,10',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,-2,9': {
      q: -7,
      r: -2,
      s: 9,
      id: '-7,-2,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,-1,8': {
      q: -7,
      r: -1,
      s: 8,
      id: '-7,-1,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,0,7': {
      q: -7,
      r: 0,
      s: 7,
      id: '-7,0,7',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,1,6': {
      q: -7,
      r: 1,
      s: 6,
      id: '-7,1,6',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,2,5': {
      q: -7,
      r: 2,
      s: 5,
      id: '-7,2,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,3,4': {
      q: -7,
      r: 3,
      s: 4,
      id: '-7,3,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,4,3': {
      q: -7,
      r: 4,
      s: 3,
      id: '-7,4,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-7,5,2': {
      q: -7,
      r: 5,
      s: 2,
      id: '-7,5,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-7,6,1': {
      q: -7,
      r: 6,
      s: 1,
      id: '-7,6,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-7,7,0': {
      q: -7,
      r: 7,
      s: 0,
      id: '-7,7,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-7,8,-1': {
      q: -7,
      r: 8,
      s: -1,
      id: '-7,8,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-7,9,-2': {
      q: -7,
      r: 9,
      s: -2,
      id: '-7,9,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-7,10,-3': {
      q: -7,
      r: 10,
      s: -3,
      id: '-7,10,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-6,-4,10': {
      q: -6,
      r: -4,
      s: 10,
      id: '-6,-4,10',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,-3,9': {
      q: -6,
      r: -3,
      s: 9,
      id: '-6,-3,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,-2,8': {
      q: -6,
      r: -2,
      s: 8,
      id: '-6,-2,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,-1,7': {
      q: -6,
      r: -1,
      s: 7,
      id: '-6,-1,7',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,0,6': {
      q: -6,
      r: 0,
      s: 6,
      id: '-6,0,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,1,5': {
      q: -6,
      r: 1,
      s: 5,
      id: '-6,1,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,2,4': {
      q: -6,
      r: 2,
      s: 4,
      id: '-6,2,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '-6,3,3': {
      q: -6,
      r: 3,
      s: 3,
      id: '-6,3,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-6,4,2': {
      q: -6,
      r: 4,
      s: 2,
      id: '-6,4,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-6,5,1': {
      q: -6,
      r: 5,
      s: 1,
      id: '-6,5,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-6,6,0': {
      q: -6,
      r: 6,
      s: 0,
      id: '-6,6,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-6,7,-1': {
      q: -6,
      r: 7,
      s: -1,
      id: '-6,7,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '-6,8,-2': {
      q: -6,
      r: 8,
      s: -2,
      id: '-6,8,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-6,9,-3': {
      q: -6,
      r: 9,
      s: -3,
      id: '-6,9,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-6,10,-4': {
      q: -6,
      r: 10,
      s: -4,
      id: '-6,10,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-5,-5,10': {
      q: -5,
      r: -5,
      s: 10,
      id: '-5,-5,10',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-5,-4,9': {
      q: -5,
      r: -4,
      s: 9,
      id: '-5,-4,9',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-5,-3,8': {
      q: -5,
      r: -3,
      s: 8,
      id: '-5,-3,8',
      startzonePlayerIDs: ['0'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-5,-2,7': {
      q: -5,
      r: -2,
      s: 7,
      id: '-5,-2,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-5,-1,6': {
      q: -5,
      r: -1,
      s: 6,
      id: '-5,-1,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-5,0,5': {
      q: -5,
      r: 0,
      s: 5,
      id: '-5,0,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-5,1,4': {
      q: -5,
      r: 1,
      s: 4,
      id: '-5,1,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-5,2,3': {
      q: -5,
      r: 2,
      s: 3,
      id: '-5,2,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-5,3,2': {
      q: -5,
      r: 3,
      s: 2,
      id: '-5,3,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-5,4,1': {
      q: -5,
      r: 4,
      s: 1,
      id: '-5,4,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-5,5,0': {
      q: -5,
      r: 5,
      s: 0,
      id: '-5,5,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 4,
    },
    '-5,6,-1': {
      q: -5,
      r: 6,
      s: -1,
      id: '-5,6,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-5,7,-2': {
      q: -5,
      r: 7,
      s: -2,
      id: '-5,7,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-5,8,-3': {
      q: -5,
      r: 8,
      s: -3,
      id: '-5,8,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-5,9,-4': {
      q: -5,
      r: 9,
      s: -4,
      id: '-5,9,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-5,10,-5': {
      q: -5,
      r: 10,
      s: -5,
      id: '-5,10,-5',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-4,-6,10': {
      q: -4,
      r: -6,
      s: 10,
      id: '-4,-6,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-4,-5,9': {
      q: -4,
      r: -5,
      s: 9,
      id: '-4,-5,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-4,-4,8': {
      q: -4,
      r: -4,
      s: 8,
      id: '-4,-4,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-4,-3,7': {
      q: -4,
      r: -3,
      s: 7,
      id: '-4,-3,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '-4,-2,6': {
      q: -4,
      r: -2,
      s: 6,
      id: '-4,-2,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-4,-1,5': {
      q: -4,
      r: -1,
      s: 5,
      id: '-4,-1,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-4,0,4': {
      q: -4,
      r: 0,
      s: 4,
      id: '-4,0,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-4,1,3': {
      q: -4,
      r: 1,
      s: 3,
      id: '-4,1,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-4,2,2': {
      q: -4,
      r: 2,
      s: 2,
      id: '-4,2,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-4,3,1': {
      q: -4,
      r: 3,
      s: 1,
      id: '-4,3,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-4,4,0': {
      q: -4,
      r: 4,
      s: 0,
      id: '-4,4,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-4,5,-1': {
      q: -4,
      r: 5,
      s: -1,
      id: '-4,5,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-4,6,-2': {
      q: -4,
      r: 6,
      s: -2,
      id: '-4,6,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-4,7,-3': {
      q: -4,
      r: 7,
      s: -3,
      id: '-4,7,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-4,8,-4': {
      q: -4,
      r: 8,
      s: -4,
      id: '-4,8,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-4,9,-5': {
      q: -4,
      r: 9,
      s: -5,
      id: '-4,9,-5',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-4,10,-6': {
      q: -4,
      r: 10,
      s: -6,
      id: '-4,10,-6',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-3,-7,10': {
      q: -3,
      r: -7,
      s: 10,
      id: '-3,-7,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-3,-6,9': {
      q: -3,
      r: -6,
      s: 9,
      id: '-3,-6,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-3,-5,8': {
      q: -3,
      r: -5,
      s: 8,
      id: '-3,-5,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-3,-4,7': {
      q: -3,
      r: -4,
      s: 7,
      id: '-3,-4,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-3,-3,6': {
      q: -3,
      r: -3,
      s: 6,
      id: '-3,-3,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-3,-2,5': {
      q: -3,
      r: -2,
      s: 5,
      id: '-3,-2,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-3,-1,4': {
      q: -3,
      r: -1,
      s: 4,
      id: '-3,-1,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-3,0,3': {
      q: -3,
      r: 0,
      s: 3,
      id: '-3,0,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '-3,1,2': {
      q: -3,
      r: 1,
      s: 2,
      id: '-3,1,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-3,2,1': {
      q: -3,
      r: 2,
      s: 1,
      id: '-3,2,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-3,3,0': {
      q: -3,
      r: 3,
      s: 0,
      id: '-3,3,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-3,4,-1': {
      q: -3,
      r: 4,
      s: -1,
      id: '-3,4,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-3,5,-2': {
      q: -3,
      r: 5,
      s: -2,
      id: '-3,5,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-3,6,-3': {
      q: -3,
      r: 6,
      s: -3,
      id: '-3,6,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-3,7,-4': {
      q: -3,
      r: 7,
      s: -4,
      id: '-3,7,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '-3,8,-5': {
      q: -3,
      r: 8,
      s: -5,
      id: '-3,8,-5',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-3,9,-6': {
      q: -3,
      r: 9,
      s: -6,
      id: '-3,9,-6',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-3,10,-7': {
      q: -3,
      r: 10,
      s: -7,
      id: '-3,10,-7',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-2,-8,10': {
      q: -2,
      r: -8,
      s: 10,
      id: '-2,-8,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-2,-7,9': {
      q: -2,
      r: -7,
      s: 9,
      id: '-2,-7,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-2,-6,8': {
      q: -2,
      r: -6,
      s: 8,
      id: '-2,-6,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-2,-5,7': {
      q: -2,
      r: -5,
      s: 7,
      id: '-2,-5,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '-2,-4,6': {
      q: -2,
      r: -4,
      s: 6,
      id: '-2,-4,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-2,-3,5': {
      q: -2,
      r: -3,
      s: 5,
      id: '-2,-3,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-2,-2,4': {
      q: -2,
      r: -2,
      s: 4,
      id: '-2,-2,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 6,
    },
    '-2,-1,3': {
      q: -2,
      r: -1,
      s: 3,
      id: '-2,-1,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '-2,0,2': {
      q: -2,
      r: 0,
      s: 2,
      id: '-2,0,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '-2,1,1': {
      q: -2,
      r: 1,
      s: 1,
      id: '-2,1,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-2,2,0': {
      q: -2,
      r: 2,
      s: 0,
      id: '-2,2,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '-2,3,-1': {
      q: -2,
      r: 3,
      s: -1,
      id: '-2,3,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '-2,4,-2': {
      q: -2,
      r: 4,
      s: -2,
      id: '-2,4,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 6,
    },
    '-2,5,-3': {
      q: -2,
      r: 5,
      s: -3,
      id: '-2,5,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-2,6,-4': {
      q: -2,
      r: 6,
      s: -4,
      id: '-2,6,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-2,7,-5': {
      q: -2,
      r: 7,
      s: -5,
      id: '-2,7,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-2,8,-6': {
      q: -2,
      r: 8,
      s: -6,
      id: '-2,8,-6',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-2,9,-7': {
      q: -2,
      r: 9,
      s: -7,
      id: '-2,9,-7',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-2,10,-8': {
      q: -2,
      r: 10,
      s: -8,
      id: '-2,10,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-1,-9,10': {
      q: -1,
      r: -9,
      s: 10,
      id: '-1,-9,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-1,-8,9': {
      q: -1,
      r: -8,
      s: 9,
      id: '-1,-8,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '-1,-7,8': {
      q: -1,
      r: -7,
      s: 8,
      id: '-1,-7,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '-1,-6,7': {
      q: -1,
      r: -6,
      s: 7,
      id: '-1,-6,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '-1,-5,6': {
      q: -1,
      r: -5,
      s: 6,
      id: '-1,-5,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-1,-4,5': {
      q: -1,
      r: -4,
      s: 5,
      id: '-1,-4,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-1,-3,4': {
      q: -1,
      r: -3,
      s: 4,
      id: '-1,-3,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-1,-2,3': {
      q: -1,
      r: -2,
      s: 3,
      id: '-1,-2,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '-1,-1,2': {
      q: -1,
      r: -1,
      s: 2,
      id: '-1,-1,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '-1,0,1': {
      q: -1,
      r: 0,
      s: 1,
      id: '-1,0,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-1,1,0': {
      q: -1,
      r: 1,
      s: 0,
      id: '-1,1,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '-1,2,-1': {
      q: -1,
      r: 2,
      s: -1,
      id: '-1,2,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '-1,3,-2': {
      q: -1,
      r: 3,
      s: -2,
      id: '-1,3,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '-1,4,-3': {
      q: -1,
      r: 4,
      s: -3,
      id: '-1,4,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-1,5,-4': {
      q: -1,
      r: 5,
      s: -4,
      id: '-1,5,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '-1,6,-5': {
      q: -1,
      r: 6,
      s: -5,
      id: '-1,6,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '-1,7,-6': {
      q: -1,
      r: 7,
      s: -6,
      id: '-1,7,-6',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-1,8,-7': {
      q: -1,
      r: 8,
      s: -7,
      id: '-1,8,-7',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-1,9,-8': {
      q: -1,
      r: 9,
      s: -8,
      id: '-1,9,-8',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '-1,10,-9': {
      q: -1,
      r: 10,
      s: -9,
      id: '-1,10,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '0,-10,10': {
      q: 0,
      r: -10,
      s: 10,
      id: '0,-10,10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '0,-9,9': {
      q: 0,
      r: -9,
      s: 9,
      id: '0,-9,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '0,-8,8': {
      q: 0,
      r: -8,
      s: 8,
      id: '0,-8,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '0,-7,7': {
      q: 0,
      r: -7,
      s: 7,
      id: '0,-7,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '0,-6,6': {
      q: 0,
      r: -6,
      s: 6,
      id: '0,-6,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '0,-5,5': {
      q: 0,
      r: -5,
      s: 5,
      id: '0,-5,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 4,
    },
    '0,-4,4': {
      q: 0,
      r: -4,
      s: 4,
      id: '0,-4,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '0,-3,3': {
      q: 0,
      r: -3,
      s: 3,
      id: '0,-3,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '0,-2,2': {
      q: 0,
      r: -2,
      s: 2,
      id: '0,-2,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '0,-1,1': {
      q: 0,
      r: -1,
      s: 1,
      id: '0,-1,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '0,0,0': {
      q: 0,
      r: 0,
      s: 0,
      id: '0,0,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '0,1,-1': {
      q: 0,
      r: 1,
      s: -1,
      id: '0,1,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '0,2,-2': {
      q: 0,
      r: 2,
      s: -2,
      id: '0,2,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '0,3,-3': {
      q: 0,
      r: 3,
      s: -3,
      id: '0,3,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '0,4,-4': {
      q: 0,
      r: 4,
      s: -4,
      id: '0,4,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '0,5,-5': {
      q: 0,
      r: 5,
      s: -5,
      id: '0,5,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '0,6,-6': {
      q: 0,
      r: 6,
      s: -6,
      id: '0,6,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '0,7,-7': {
      q: 0,
      r: 7,
      s: -7,
      id: '0,7,-7',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '0,8,-8': {
      q: 0,
      r: 8,
      s: -8,
      id: '0,8,-8',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '0,9,-9': {
      q: 0,
      r: 9,
      s: -9,
      id: '0,9,-9',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '0,10,-10': {
      q: 0,
      r: 10,
      s: -10,
      id: '0,10,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '1,-10,9': {
      q: 1,
      r: -10,
      s: 9,
      id: '1,-10,9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '1,-9,8': {
      q: 1,
      r: -9,
      s: 8,
      id: '1,-9,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '1,-8,7': {
      q: 1,
      r: -8,
      s: 7,
      id: '1,-8,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '1,-7,6': {
      q: 1,
      r: -7,
      s: 6,
      id: '1,-7,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '1,-6,5': {
      q: 1,
      r: -6,
      s: 5,
      id: '1,-6,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,-5,4': {
      q: 1,
      r: -5,
      s: 4,
      id: '1,-5,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,-4,3': {
      q: 1,
      r: -4,
      s: 3,
      id: '1,-4,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,-3,2': {
      q: 1,
      r: -3,
      s: 2,
      id: '1,-3,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,-2,1': {
      q: 1,
      r: -2,
      s: 1,
      id: '1,-2,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,-1,0': {
      q: 1,
      r: -1,
      s: 0,
      id: '1,-1,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,0,-1': {
      q: 1,
      r: 0,
      s: -1,
      id: '1,0,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,1,-2': {
      q: 1,
      r: 1,
      s: -2,
      id: '1,1,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,2,-3': {
      q: 1,
      r: 2,
      s: -3,
      id: '1,2,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,3,-4': {
      q: 1,
      r: 3,
      s: -4,
      id: '1,3,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '1,4,-5': {
      q: 1,
      r: 4,
      s: -5,
      id: '1,4,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '1,5,-6': {
      q: 1,
      r: 5,
      s: -6,
      id: '1,5,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '1,6,-7': {
      q: 1,
      r: 6,
      s: -7,
      id: '1,6,-7',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '1,7,-8': {
      q: 1,
      r: 7,
      s: -8,
      id: '1,7,-8',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '1,8,-9': {
      q: 1,
      r: 8,
      s: -9,
      id: '1,8,-9',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '1,9,-10': {
      q: 1,
      r: 9,
      s: -10,
      id: '1,9,-10',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '2,-10,8': {
      q: 2,
      r: -10,
      s: 8,
      id: '2,-10,8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '2,-9,7': {
      q: 2,
      r: -9,
      s: 7,
      id: '2,-9,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '2,-8,6': {
      q: 2,
      r: -8,
      s: 6,
      id: '2,-8,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '2,-7,5': {
      q: 2,
      r: -7,
      s: 5,
      id: '2,-7,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '2,-6,4': {
      q: 2,
      r: -6,
      s: 4,
      id: '2,-6,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '2,-5,3': {
      q: 2,
      r: -5,
      s: 3,
      id: '2,-5,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '2,-4,2': {
      q: 2,
      r: -4,
      s: 2,
      id: '2,-4,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '2,-3,1': {
      q: 2,
      r: -3,
      s: 1,
      id: '2,-3,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '2,-2,0': {
      q: 2,
      r: -2,
      s: 0,
      id: '2,-2,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '2,-1,-1': {
      q: 2,
      r: -1,
      s: -1,
      id: '2,-1,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 5,
    },
    '2,0,-2': {
      q: 2,
      r: 0,
      s: -2,
      id: '2,0,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '2,1,-3': {
      q: 2,
      r: 1,
      s: -3,
      id: '2,1,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '2,2,-4': {
      q: 2,
      r: 2,
      s: -4,
      id: '2,2,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '2,3,-5': {
      q: 2,
      r: 3,
      s: -5,
      id: '2,3,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '2,4,-6': {
      q: 2,
      r: 4,
      s: -6,
      id: '2,4,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '2,5,-7': {
      q: 2,
      r: 5,
      s: -7,
      id: '2,5,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '2,6,-8': {
      q: 2,
      r: 6,
      s: -8,
      id: '2,6,-8',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '2,7,-9': {
      q: 2,
      r: 7,
      s: -9,
      id: '2,7,-9',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '2,8,-10': {
      q: 2,
      r: 8,
      s: -10,
      id: '2,8,-10',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,-10,7': {
      q: 3,
      r: -10,
      s: 7,
      id: '3,-10,7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '3,-9,6': {
      q: 3,
      r: -9,
      s: 6,
      id: '3,-9,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '3,-8,5': {
      q: 3,
      r: -8,
      s: 5,
      id: '3,-8,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '3,-7,4': {
      q: 3,
      r: -7,
      s: 4,
      id: '3,-7,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,-6,3': {
      q: 3,
      r: -6,
      s: 3,
      id: '3,-6,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,-5,2': {
      q: 3,
      r: -5,
      s: 2,
      id: '3,-5,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '3,-4,1': {
      q: 3,
      r: -4,
      s: 1,
      id: '3,-4,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '3,-3,0': {
      q: 3,
      r: -3,
      s: 0,
      id: '3,-3,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '3,-2,-1': {
      q: 3,
      r: -2,
      s: -1,
      id: '3,-2,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 4,
    },
    '3,-1,-2': {
      q: 3,
      r: -1,
      s: -2,
      id: '3,-1,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 4,
    },
    '3,0,-3': {
      q: 3,
      r: 0,
      s: -3,
      id: '3,0,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '3,1,-4': {
      q: 3,
      r: 1,
      s: -4,
      id: '3,1,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '3,2,-5': {
      q: 3,
      r: 2,
      s: -5,
      id: '3,2,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '3,3,-6': {
      q: 3,
      r: 3,
      s: -6,
      id: '3,3,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,4,-7': {
      q: 3,
      r: 4,
      s: -7,
      id: '3,4,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,5,-8': {
      q: 3,
      r: 5,
      s: -8,
      id: '3,5,-8',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,6,-9': {
      q: 3,
      r: 6,
      s: -9,
      id: '3,6,-9',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '3,7,-10': {
      q: 3,
      r: 7,
      s: -10,
      id: '3,7,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '4,-10,6': {
      q: 4,
      r: -10,
      s: 6,
      id: '4,-10,6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '4,-9,5': {
      q: 4,
      r: -9,
      s: 5,
      id: '4,-9,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '4,-8,4': {
      q: 4,
      r: -8,
      s: 4,
      id: '4,-8,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '4,-7,3': {
      q: 4,
      r: -7,
      s: 3,
      id: '4,-7,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '4,-6,2': {
      q: 4,
      r: -6,
      s: 2,
      id: '4,-6,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '4,-5,1': {
      q: 4,
      r: -5,
      s: 1,
      id: '4,-5,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '4,-4,0': {
      q: 4,
      r: -4,
      s: 0,
      id: '4,-4,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '4,-3,-1': {
      q: 4,
      r: -3,
      s: -1,
      id: '4,-3,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '4,-2,-2': {
      q: 4,
      r: -2,
      s: -2,
      id: '4,-2,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 6,
    },
    '4,-1,-3': {
      q: 4,
      r: -1,
      s: -3,
      id: '4,-1,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '4,0,-4': {
      q: 4,
      r: 0,
      s: -4,
      id: '4,0,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '4,1,-5': {
      q: 4,
      r: 1,
      s: -5,
      id: '4,1,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '4,2,-6': {
      q: 4,
      r: 2,
      s: -6,
      id: '4,2,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '4,3,-7': {
      q: 4,
      r: 3,
      s: -7,
      id: '4,3,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '4,4,-8': {
      q: 4,
      r: 4,
      s: -8,
      id: '4,4,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '4,5,-9': {
      q: 4,
      r: 5,
      s: -9,
      id: '4,5,-9',
      startzonePlayerIDs: ['1'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '4,6,-10': {
      q: 4,
      r: 6,
      s: -10,
      id: '4,6,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '5,-10,5': {
      q: 5,
      r: -10,
      s: 5,
      id: '5,-10,5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '5,-9,4': {
      q: 5,
      r: -9,
      s: 4,
      id: '5,-9,4',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '5,-8,3': {
      q: 5,
      r: -8,
      s: 3,
      id: '5,-8,3',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '5,-7,2': {
      q: 5,
      r: -7,
      s: 2,
      id: '5,-7,2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '5,-6,1': {
      q: 5,
      r: -6,
      s: 1,
      id: '5,-6,1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '5,-5,0': {
      q: 5,
      r: -5,
      s: 0,
      id: '5,-5,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '5,-4,-1': {
      q: 5,
      r: -4,
      s: -1,
      id: '5,-4,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '5,-3,-2': {
      q: 5,
      r: -3,
      s: -2,
      id: '5,-3,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '5,-2,-3': {
      q: 5,
      r: -2,
      s: -3,
      id: '5,-2,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '5,-1,-4': {
      q: 5,
      r: -1,
      s: -4,
      id: '5,-1,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 3,
    },
    '5,0,-5': {
      q: 5,
      r: 0,
      s: -5,
      id: '5,0,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 4,
    },
    '5,1,-6': {
      q: 5,
      r: 1,
      s: -6,
      id: '5,1,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 4,
    },
    '5,2,-7': {
      q: 5,
      r: 2,
      s: -7,
      id: '5,2,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '5,3,-8': {
      q: 5,
      r: 3,
      s: -8,
      id: '5,3,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '5,4,-9': {
      q: 5,
      r: 4,
      s: -9,
      id: '5,4,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '5,5,-10': {
      q: 5,
      r: 5,
      s: -10,
      id: '5,5,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '6,-10,4': {
      q: 6,
      r: -10,
      s: 4,
      id: '6,-10,4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '6,-9,3': {
      q: 6,
      r: -9,
      s: 3,
      id: '6,-9,3',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '6,-8,2': {
      q: 6,
      r: -8,
      s: 2,
      id: '6,-8,2',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '6,-7,1': {
      q: 6,
      r: -7,
      s: 1,
      id: '6,-7,1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '6,-6,0': {
      q: 6,
      r: -6,
      s: 0,
      id: '6,-6,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '6,-5,-1': {
      q: 6,
      r: -5,
      s: -1,
      id: '6,-5,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,-4,-2': {
      q: 6,
      r: -4,
      s: -2,
      id: '6,-4,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,-3,-3': {
      q: 6,
      r: -3,
      s: -3,
      id: '6,-3,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,-2,-4': {
      q: 6,
      r: -2,
      s: -4,
      id: '6,-2,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,-1,-5': {
      q: 6,
      r: -1,
      s: -5,
      id: '6,-1,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,0,-6': {
      q: 6,
      r: 0,
      s: -6,
      id: '6,0,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'road',
      altitude: 3,
    },
    '6,1,-7': {
      q: 6,
      r: 1,
      s: -7,
      id: '6,1,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '6,2,-8': {
      q: 6,
      r: 2,
      s: -8,
      id: '6,2,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '6,3,-9': {
      q: 6,
      r: 3,
      s: -9,
      id: '6,3,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '6,4,-10': {
      q: 6,
      r: 4,
      s: -10,
      id: '6,4,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '7,-10,3': {
      q: 7,
      r: -10,
      s: 3,
      id: '7,-10,3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '7,-9,2': {
      q: 7,
      r: -9,
      s: 2,
      id: '7,-9,2',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '7,-8,1': {
      q: 7,
      r: -8,
      s: 1,
      id: '7,-8,1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '7,-7,0': {
      q: 7,
      r: -7,
      s: 0,
      id: '7,-7,0',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '7,-6,-1': {
      q: 7,
      r: -6,
      s: -1,
      id: '7,-6,-1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '7,-5,-2': {
      q: 7,
      r: -5,
      s: -2,
      id: '7,-5,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '7,-4,-3': {
      q: 7,
      r: -4,
      s: -3,
      id: '7,-4,-3',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '7,-3,-4': {
      q: 7,
      r: -3,
      s: -4,
      id: '7,-3,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '7,-2,-5': {
      q: 7,
      r: -2,
      s: -5,
      id: '7,-2,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '7,-1,-6': {
      q: 7,
      r: -1,
      s: -6,
      id: '7,-1,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'rock',
      altitude: 5,
    },
    '7,0,-7': {
      q: 7,
      r: 0,
      s: -7,
      id: '7,0,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '7,1,-8': {
      q: 7,
      r: 1,
      s: -8,
      id: '7,1,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '7,2,-9': {
      q: 7,
      r: 2,
      s: -9,
      id: '7,2,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '7,3,-10': {
      q: 7,
      r: 3,
      s: -10,
      id: '7,3,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '8,-10,2': {
      q: 8,
      r: -10,
      s: 2,
      id: '8,-10,2',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-9,1': {
      q: 8,
      r: -9,
      s: 1,
      id: '8,-9,1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-8,0': {
      q: 8,
      r: -8,
      s: 0,
      id: '8,-8,0',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-7,-1': {
      q: 8,
      r: -7,
      s: -1,
      id: '8,-7,-1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-6,-2': {
      q: 8,
      r: -6,
      s: -2,
      id: '8,-6,-2',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-5,-3': {
      q: 8,
      r: -5,
      s: -3,
      id: '8,-5,-3',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '8,-4,-4': {
      q: 8,
      r: -4,
      s: -4,
      id: '8,-4,-4',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '8,-3,-5': {
      q: 8,
      r: -3,
      s: -5,
      id: '8,-3,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '8,-2,-6': {
      q: 8,
      r: -2,
      s: -6,
      id: '8,-2,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '8,-1,-7': {
      q: 8,
      r: -1,
      s: -7,
      id: '8,-1,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '8,0,-8': {
      q: 8,
      r: 0,
      s: -8,
      id: '8,0,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '8,1,-9': {
      q: 8,
      r: 1,
      s: -9,
      id: '8,1,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '8,2,-10': {
      q: 8,
      r: 2,
      s: -10,
      id: '8,2,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '9,-10,1': {
      q: 9,
      r: -10,
      s: 1,
      id: '9,-10,1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-9,0': {
      q: 9,
      r: -9,
      s: 0,
      id: '9,-9,0',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-8,-1': {
      q: 9,
      r: -8,
      s: -1,
      id: '9,-8,-1',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-7,-2': {
      q: 9,
      r: -7,
      s: -2,
      id: '9,-7,-2',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-6,-3': {
      q: 9,
      r: -6,
      s: -3,
      id: '9,-6,-3',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-5,-4': {
      q: 9,
      r: -5,
      s: -4,
      id: '9,-5,-4',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '9,-4,-5': {
      q: 9,
      r: -4,
      s: -5,
      id: '9,-4,-5',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '9,-3,-6': {
      q: 9,
      r: -3,
      s: -6,
      id: '9,-3,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'sand',
      altitude: 2,
    },
    '9,-2,-7': {
      q: 9,
      r: -2,
      s: -7,
      id: '9,-2,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'water',
      altitude: 1,
    },
    '9,-1,-8': {
      q: 9,
      r: -1,
      s: -8,
      id: '9,-1,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '9,0,-9': {
      q: 9,
      r: 0,
      s: -9,
      id: '9,0,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '9,1,-10': {
      q: 9,
      r: 1,
      s: -10,
      id: '9,1,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-10,0': {
      q: 10,
      r: -10,
      s: 0,
      id: '10,-10,0',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-9,-1': {
      q: 10,
      r: -9,
      s: -1,
      id: '10,-9,-1',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-8,-2': {
      q: 10,
      r: -8,
      s: -2,
      id: '10,-8,-2',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-7,-3': {
      q: 10,
      r: -7,
      s: -3,
      id: '10,-7,-3',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '10,-6,-4': {
      q: 10,
      r: -6,
      s: -4,
      id: '10,-6,-4',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '10,-5,-5': {
      q: 10,
      r: -5,
      s: -5,
      id: '10,-5,-5',
      startzonePlayerIDs: ['2'],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'grass',
      altitude: 2,
    },
    '10,-4,-6': {
      q: 10,
      r: -4,
      s: -6,
      id: '10,-4,-6',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-3,-7': {
      q: 10,
      r: -3,
      s: -7,
      id: '10,-3,-7',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-2,-8': {
      q: 10,
      r: -2,
      s: -8,
      id: '10,-2,-8',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,-1,-9': {
      q: 10,
      r: -1,
      s: -9,
      id: '10,-1,-9',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
    '10,0,-10': {
      q: 10,
      r: 0,
      s: -10,
      id: '10,0,-10',
      startzonePlayerIDs: [],
      occupyingUnitID: '',
      isUnitTail: false,
      terrain: 'void',
      altitude: 0,
    },
  },
  hexMap: {
    flat: false,
    hexHeight: 2,
    hexWidth: 1.73,
    mapId: 'cirdanGardenWithoutTrees',
    mapName: 'The Cirdan Garden',
    hexSize: 25,
    mapShape: 'hexagon',
    mapSize: 10,
    mapHeight: 21,
    mapWidth: 21,
    glyphs: {},
  },
}
