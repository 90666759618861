import { ICoreHeroscapeCard } from './types'
export const coreHeroscapeCards: ICoreHeroscapeCard[] = [
  {
    name: 'Marro Warriors',
    singleName: 'Marro Warrior',
    armyCardID: 'hs1000',
    image: 'marrowarriors.jpg',
    portraitPattern: 'marrowarriors-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique squad',
    cardClass: 'warriors',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '6',
    attack: '2',
    defense: '3',
    points: '50',
    figures: '4',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Water Clone',
        desc: 'Instead of attacking with all of the Marro Warriors, one at a time, roll the 20-sided die for each Marro Warrior in play. If you roll a 15 or higher, place a previously destroyed Marro Warrior on a same-level space adjacent to that Marro Warrior. Any Marro Warrior on a water space needs a 10 or higher to Water Clone. You may only Water Clone after you move.',
      },
    ],
  },
  {
    name: 'Deathwalker 9000',
    singleName: 'Deathwalker 9000',
    armyCardID: 'hs1001',
    image: 'deathwalker9000.jpg',
    portraitPattern: 'deathwalker9000-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'deathwalker',
    personality: 'precise',
    height: '7',
    heightClass: 'large',
    life: '1',
    move: '5',
    range: '7',
    attack: '4',
    defense: '9',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Explosion Special Attack',
        desc: 'Range 7. Attack 3. Choose a figure to attack. Any figures adjacent to the chosen figure are also affected by Explosion Special Attack. Deathwalker only needs a clear sight shot at the chosen figure. Roll 3 attack dice once for all affected figures. Each figure rolls defense dice separately. Deathwalker can be affected by his own Explosion Special Attack.',
      },
      {
        name: 'Range Enhancement',
        desc: 'Any Soulborg Guards adjacent to Deathwalker add 2 spaces to their range.',
      },
    ],
  },
  {
    name: 'Izumi Samurai',
    singleName: 'Izumi Samurai',
    armyCardID: 'hs1002',
    image: 'izumisamurai.jpg',
    portraitPattern: 'izumisamurai-portrait',
    general: 'einar',
    race: 'orc',
    type: 'unique squad',
    cardClass: 'warriors',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '5',
    points: '60',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure. This power does not work against other Samurai.',
      },
    ],
  },
  {
    name: 'Sgt. Drake Alexander',
    singleName: 'Sgt. Drake Alexander',
    armyCardID: 'hs1003',
    image: 'sgtdrakealexander1.jpg',
    portraitPattern: 'sgtdrakealexander1-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'soldier',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '6',
    defense: '3',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Thorian Speed',
        desc: "Opponents' figures must be adjacent to Sgt. Drake Alexander to attack him with a normal attack.",
      },
      {
        name: 'Grapple Gun 25',
        desc: "Instead of Sgt. Drake Alexander's normal move, he may move only one space. This space may be up to 25 levels higher. When using the Grapple Gun, all engagement rules still apply.",
      },
    ],
  },
  {
    name: 'Syvarris',
    singleName: 'Syvarris',
    armyCardID: 'hs1004',
    image: 'syvarris.jpg',
    portraitPattern: 'syvarris-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'archer',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '9',
    attack: '3',
    defense: '2',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Double Attack',
        desc: 'When Syvarris attacks, he may attack one additional time.',
      },
    ],
  },
  {
    name: 'Krav Maga Agents',
    singleName: 'Krav Maga Agent',
    armyCardID: 'hs1005',
    image: 'kravmagaagents.jpg',
    portraitPattern: 'kravmagaagents-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'agents',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '7',
    attack: '3',
    defense: '3',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Stealth Dodge',
        desc: 'When a Krav Maga Agent rolls defense dice against an attacking figure who is not adjacent, one shield will block all damage.',
      },
    ],
  },
  {
    name: 'Tarn Viking Warriors',
    singleName: 'Tarn Viking Warrior',
    armyCardID: 'hs1006',
    image: 'tarnvikingwarriors.jpg',
    portraitPattern: 'tarnvikingwarriors-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'warriors',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '3',
    defense: '4',
    points: '50',
    figures: '4',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Berserker Charge',
        desc: 'After moving and before attacking, roll the 20-sided die. If you roll a 15 or higher, you may move all Tarn Viking Warriors again.',
        // Unlimited Berserker Charge
        // If I move once and am successful rolling for Berserker Charge, can I roll for it again after I'm done moving a second time?
        // Yes. You can keep rolling for Berserker Charge until you roll less than a 15 or choose not to roll any more. Once you attack though, you cannot roll for Berserker Charge that turn. (Hasbro FAQ)
        // https://heroscape.fandom.com/wiki/Tarn_Viking_Warriors
      },
    ],
  },
  {
    name: 'Agent Carr',
    singleName: 'Agent Carr',
    armyCardID: 'hs1007',
    image: 'agentcarr.jpg',
    portraitPattern: 'agentcarr-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'agent',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '6',
    attack: '2',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Phantom Walk',
        desc: 'Agent Carr can move through all figures and is never attacked when leaving an engagement.',
      },
      {
        name: 'Sword of Reckoning',
        desc: "If Agent Carr is attacking an adjacent figure, add 4 dice to Agent Carr's attack.",
      },
    ],
  },
  {
    name: 'Zettian Guards',
    singleName: 'Zettian Guard',
    armyCardID: 'hs1008',
    image: 'zettianguards.jpg',
    portraitPattern: 'zettianguards-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'unique squad',
    cardClass: 'guards',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '7',
    attack: '2',
    defense: '7',
    points: '70',
    figures: '2',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Zettian Targeting',
        desc: "When attacking, if your second Zettian Guard attacks the same figure as the first Zettian Guard, add one attack die to the second Zettian Guard's attack.",
      },
    ],
  },
  {
    name: 'Airborne Elite',
    singleName: 'Airborne Elite',
    armyCardID: 'hs1009',
    image: 'airborneelite.jpg',
    portraitPattern: 'airborneelite-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'soldiers',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '8',
    attack: '3',
    defense: '2',
    points: '110',
    figures: '4',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Grenade Special Attack',
        desc: 'Range 5. Lob 12. Attack 2. Use this power once per game. Start the game with a grenade marker on this card. Remove the marker to throw grenades. One at a time do the following with each Airborne Elite: Choose a figure to attack. No clear line of sight is needed. Any figures adjacent to the chosen figure are also affected by the Grenade Special Attack. Roll 2 attack dice once for all affected figures. Each figure rolls defense dice separately.',
      },
      {
        name: 'The Drop',
        desc: 'Airborne Elite do not start the game on the battlefield. At the start of each round, before you place Order Markers, roll the 20-sided die. If you roll a 13 or higher you may place all 4 Airborne Elite figures on any empty spaces. You cannot place them adjacent to each other or other figures or on glyphs.',
      },
    ],
  },
  {
    name: 'Finn the Viking Champion',
    singleName: 'Finn the Viking Champion',
    armyCardID: 'hs1010',
    image: 'finnthevikingchampion.jpg',
    portraitPattern: 'finnthevikingchampion-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '3',
    defense: '4',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Attack Aura 1',
        desc: 'All friendly figures adjacent to Finn with a range of 1 add 1 die to their normal attack.',
      },
      {
        name: "Warrior's Attack Spirit 1",
        desc: "When Finn is destroyed, place this figure on any unique Army Card. Finn's Spirit adds 1 to the normal attack number on that card.",
      },
    ],
  },
  {
    name: 'Thorgrim the Viking Champion',
    singleName: 'Thorgrim the Viking Champion',
    armyCardID: 'hs1011',
    image: 'thorgrimthevikingchampion.jpg',
    portraitPattern: 'thorgrimthevikingchampion-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '3',
    defense: '4',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: "Warrior's Armor Spirit 1",
        desc: "When Thorgrim is destroyed, place this figure on any unique Army Card. Thorgrim's Spirit adds 1 to the defense number on that card.",
      },
      {
        name: 'Defensive Aura 1',
        desc: 'All friendly figures adjacent to Thorgrim add 1 die their defense.',
      },
    ],
  },
  {
    name: 'Raelin the Kyrie Warrior',
    singleName: 'Raelin the Kyrie Warrior',
    armyCardID: 'hs1012',
    image: 'raelinthekyriewarrior1.jpg',
    portraitPattern: 'raelinthekyriewarrior1-portrait',
    general: 'jandar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'merciful',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Defensive Aura',
        desc: "All figures you control within 4 clear sight spaces of Raelin add 2 to their defensive dice. Raelin's Defensive Aura does not affect Raelin.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Raelin's movement, ignore elevations. Raelin may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Raelin starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
      // DEV NOTE: These 3 abilities are not actually Raelin's
      // {
      //   name: 'Stealth Flying',
      //   desc: 'When counting spaces for Raelin’s movement, ignore elevations. Raelin may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Raelin starts to fly, if he is engaged he will not take any leaving engagement attacks.',
      // },
      // {
      //   name: 'Ghost Walk',
      //   desc: 'Raelin can move through all figures.',
      // },
      // {
      //   name: 'Disengage',
      //   desc: 'Raelin is never attacked when leaving an engagement.',
      // },
    ],
  },
  {
    name: 'Mimring',
    singleName: 'Mimring',
    armyCardID: 'hs1013',
    image: 'mimring.jpg',
    portraitPattern: 'mimring-portrait',
    general: 'utgar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'beast',
    personality: 'ferocious',
    height: '9',
    heightClass: 'huge',
    life: '5',
    move: '6',
    range: '1',
    attack: '4',
    defense: '3',
    points: '150',
    figures: '1',
    hexes: '2',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Fire Line Special Attack',
        desc: "Range: Special. Attack: 4. Choose 8 spaces in a straight line from Mimring. All figures on those spaces who are in line of sight are affected by Mimring's Fire Line Special Attack. Roll 4 attack dice once for all affected figures. Affected figures roll defense dice seperately.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Mimring's movement, ignore elevations. Mimring may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Mimring starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Ne-gok-sa',
    singleName: 'Ne-gok-sa',
    armyCardID: 'hs1014',
    image: 'negoksa.jpg',
    portraitPattern: 'negoksa-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '6',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Mind Shackle 20',
        desc: "After moving and before attacking, you may choose any unique figure adjacent to Ne-gok-sa. Roll the 20-sided die. If you roll a 20, take control of the chosen figure and that figure's Army Card. You now control that Army Card and all figures on it. Remove any Order Markers on this card. If Ne-gok-sa is destroyed, you retain control of any previously Mind Shackled Army Cards.",
      },
    ],
  },
  {
    name: 'Grimnak',
    singleName: 'Grimnak',
    armyCardID: 'hs1015',
    image: 'grimnak.jpg',
    portraitPattern: 'grimnak-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'ferocious',
    height: '11',
    heightClass: 'huge',
    life: '5',
    move: '5',
    range: '1',
    attack: '2',
    defense: '4',
    points: '120',
    figures: '1',
    hexes: '2',
    setWave: 'Master Set: Rise of the Valkyrie',
    abilities: [
      {
        name: 'Chomp',
        desc: 'Before attacking, choose one medium or small figure adjacent to Grimnak. If the chosen figure is a Squad figure, destroy it. If the chosen figure is a Hero figure, roll the 20-sided die. If you roll a 16 or higher, destroy the chosen Hero.',
      },
      {
        name: 'Orc Warrior Enhancement',
        desc: 'All friendly Orc Warriors adjacent to Grimnak roll an additional attack die and an additional defense die.',
      },
    ],
  },
  {
    name: 'Omnicron Snipers',
    singleName: 'Omnicron Sniper',
    armyCardID: 'hs1016',
    image: 'omnicronsnipers.jpg',
    portraitPattern: 'omnicronsnipers-portrait',
    general: 'jandar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'snipers',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '7',
    attack: '1',
    defense: '3',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Deadly Shot',
        desc: 'When attacking with Omnicron Snipers, any skull rolled counts as one additional hit.',
      },
    ],
  },
  {
    name: 'Taelord the Kyrie Warrior',
    singleName: 'Taelord the Kyrie Warrior',
    armyCardID: 'hs1017',
    image: 'taelordthekyriewarrior.jpg',
    portraitPattern: 'taelordthekyriewarrior-portrait',
    general: 'utgar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'snipers',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '180',
    figures: '1',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Attack Aura',
        desc: "All figures you control within 4 clear sight spaces of Taelord get +1 to attack. Taelord's Attack Aura does not affect Taelord.",
      },
      {
        name: 'Stealth Flying',
        desc: 'When counting spaces for Taelord’s movement, ignore elevations. Taelord may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Taelord starts to fly, if he is engaged he will not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Kelda the Kyrie Warrior',
    singleName: 'Kelda the Kyrie Warrior',
    armyCardID: 'hs1018',
    image: 'keldathekyriewarrior.jpg',
    portraitPattern: 'keldathekyriewarrior-portrait',
    general: 'jandar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'merciful',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '2',
    defense: '3',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Healing Touch',
        desc: "After moving and before attacking, choose a wounded hero figure adjacent to Kelda. Then roll the 20-sided die to add or remove wound markers from the chosen figure's card: If you roll 1, add 2 markers. If you roll 2-5, remove one marker. If you roll 6-17, remove up to 2 markers. If you roll 18-20, remove all markers.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Kelda's movement, ignore elevations. Kelda may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Kelda starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Marcus Decimus Gallus',
    singleName: 'Marcus Decimus Gallus',
    armyCardID: 'hs1019',
    image: 'marcusdecimusgallus.jpg',
    portraitPattern: 'marcusdecimusgallus-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Soldier Leadership',
        desc: 'All Soldiers you control move one additional space.',
      },
      {
        name: 'Soldier Attack Enhancement',
        desc: 'All friendly Soldiers adjacent to Marcus Decimus Gallus receive an additional attack die.',
      },
    ],
  },
  {
    name: 'Venoc Warlord',
    singleName: 'Venoc Warlord',
    armyCardID: 'hs1020',
    image: 'venocwarlord.jpg',
    portraitPattern: 'venocwarlord-portrait',
    general: 'ullar',
    race: 'viper',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'relentless',
    height: '6',
    heightClass: 'medium',
    life: '6',
    move: '7',
    range: '1',
    attack: '4',
    defense: '3',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Scout Leadership',
        desc: 'All Scouts you control can move an additional 2 spaces.',
      },
      {
        name: 'Frenzy Enhancement',
        desc: 'Add 1 to your die roll when you roll for the Frenzy power on any Army Card.',
      },
      {
        name: 'Slither',
        desc: 'Venoc Warlord does not have to stop his movement when entering water spaces.',
      },
    ],
  },
  {
    name: 'Tornak',
    singleName: 'Tornak',
    armyCardID: 'hs1021',
    image: 'tornak.jpg',
    portraitPattern: 'tornak-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'tricky',
    height: '6',
    heightClass: 'large',
    life: '3',
    move: '7',
    range: '1',
    attack: '4',
    defense: '5',
    points: '100',
    figures: '1',
    hexes: '2',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Disengage',
        desc: 'Tornak is never attacked when leaving an engagement.',
      },
      {
        name: 'Orc Warrior Enhancement',
        desc: 'All friendly Orc Warriors adjacent to Tornak receive an additional attack die and an additional defense die.',
      },
    ],
  },
  {
    name: 'Venoc Vipers',
    singleName: 'Venoc Viper',
    armyCardID: 'hs1022',
    image: 'venocvipers.jpg',
    portraitPattern: 'venocvipers-portrait',
    general: 'ullar',
    race: 'viper',
    type: 'common squad',
    cardClass: 'scouts',
    personality: 'relentless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '7',
    range: '1',
    attack: '3',
    defense: '0',
    points: '40',
    figures: '3',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Slither',
        desc: 'Venoc Vipers do not have to stop their movement when entering water spaces.',
      },
      {
        name: 'Frenzy',
        desc: 'After you take a turn with Venoc Vipers; roll the 20-sided die. If you roll a 16 or higher you may take another turn with Venoc Vipers.',
      },
    ],
  },
  {
    name: 'Roman Legionnaires',
    singleName: 'Roman Legionnaire',
    armyCardID: 'hs1023',
    image: 'romanlegionnaires.jpg',
    portraitPattern: 'romanlegionnaires-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'soldiers',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '3',
    defense: '2',
    points: '50',
    figures: '4',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Warlord Bonding',
        desc: 'Before taking a turn with Roman Legionnaires, you may first take a turn with any Warlord you control.',
      },
      {
        name: 'Shield Wall',
        desc: 'When defending with a Roman Legionnaire, add 1 defense die for each other adjacent Roman Legionnaire up to a maximum of +2 dice, for the Shield Wall power.',
      },
    ],
  },
  {
    name: 'Roman Archers',
    singleName: 'Roman Archer',
    armyCardID: 'hs1024',
    image: 'romanarchers.jpg',
    portraitPattern: 'romanarchers-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'archers',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '6',
    attack: '2',
    defense: '1',
    points: '55',
    figures: '3',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Arrow Volley Special Attack',
        desc: 'Range 6. Attack 6. Three unengaged adjacent Roman Archers on the same level may combine their attacks and roll their attack dice as one attack. All Roman Archers in the Arrow Volley must have a clear line of sight on the one target.',
      },
    ],
  },
  {
    name: 'Arrow Gruts',
    singleName: 'Arrow Grut',
    armyCardID: 'hs1025',
    image: 'arrowgruts.jpg',
    portraitPattern: 'arrowgruts-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'common squad',
    cardClass: 'archers',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '6',
    attack: '1',
    defense: '1',
    points: '40',
    figures: '3',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Beast Bonding',
        desc: 'Before taking a turn with Arrow Gruts, you may first take a turn with any Beast you control.',
      },
      {
        name: 'Disengage',
        desc: 'Arrow Gruts are never attacked when leaving an engagement.',
      },
    ],
  },
  {
    name: 'Blade Gruts',
    singleName: 'Blade Grut',
    armyCardID: 'hs1026',
    image: 'bladegruts.jpg',
    portraitPattern: 'bladegruts-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '2',
    points: '40',
    figures: '4',
    hexes: '1',
    setWave: "Wave 1: Malliddon's Prophecy",
    abilities: [
      {
        name: 'Orc Champion Bonding',
        desc: 'Before taking a turn with Blade Gruts, you may first take a turn with any Orc Champion you control.',
      },
      {
        name: 'Disengage',
        desc: 'Blade Gruts are never attacked when leaving an engagement.',
      },
    ],
  },
  {
    name: 'Khosumet the Darklord',
    singleName: 'Khosumet the Darklord',
    armyCardID: 'hs1027',
    image: 'khosumetthedarklord.jpg',
    portraitPattern: 'khosumetthedarklord-portrait',
    general: 'utgar',
    race: 'wolf',
    type: 'unique hero',
    cardClass: 'darklord',
    personality: 'relentless',
    height: '5',
    heightClass: 'medium',
    life: '3',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '75',
    figures: '1',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Relentless Assault',
        desc: 'Each friendly figure adjacent to Khosumet with a relentless personality receives an additional attack die.',
      },
      {
        name: 'Unleashed Fury Enhancement',
        desc: 'You may add 1 to your die roll when you roll for the Unleashed Fury power on any Army Card.',
      },
    ],
  },
  {
    name: 'Sir Denrick',
    singleName: 'Sir Denrick',
    armyCardID: 'hs1028',
    image: 'sirdenrick.jpg',
    portraitPattern: 'sirdenrick-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: "A Coward's Reward",
        desc: 'Sir Denrick rolls one additional die against each figure leaving an engagement with him.',
      },
      {
        name: 'Giant Killer',
        desc: 'When Sir Denrick attacks huge figures, add 2 attack dice.',
      },
    ],
  },
  {
    name: 'Me-Burq-Sa',
    singleName: 'Me-Burq-Sa',
    armyCardID: 'hs1029',
    image: 'meburqsa.jpg',
    portraitPattern: 'meburqsa-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'wild',
    height: '7',
    heightClass: 'large',
    life: '3',
    move: '8',
    range: '6',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '1',
    hexes: '2',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Paralyzing Stare 16',
        desc: 'After moving and before attacking, choose any small or medium figure within 6 clear sight spaces of Me-Burq-Sa. Roll the 20-sided die. If you roll a 16 or higher, the chosen figure cannot roll any defense dice if attacked by Me-Burq-Sa this turn.',
      },
    ],
  },
  {
    name: 'Krug',
    singleName: 'Krug',
    armyCardID: 'hs1030',
    image: 'krug.jpg',
    portraitPattern: 'krug-portrait',
    general: 'utgar',
    race: 'troll',
    type: 'unique hero',
    cardClass: 'beast',
    personality: 'relentless',
    height: '8',
    heightClass: 'huge',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Wounded Smash',
        desc: 'When Krug attacks, he receives one extra attack die for each wound marker he has.',
      },
      {
        name: 'Double Attack',
        desc: 'When Krug attacks, he may attack one additional time.',
      },
    ],
  },
  {
    name: 'Minions of Utgar',
    singleName: 'Minion of Utgar',
    armyCardID: 'hs1031',
    image: 'minionsofutgar.jpg',
    portraitPattern: 'minionsofutgar-portrait',
    general: 'utgar',
    race: 'kyrie',
    type: 'common squad',
    cardClass: 'minions',
    personality: 'relentless',
    height: '6',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '2',
    defense: '6',
    points: '110',
    figures: '3',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: "Utgar's Orders",
        desc: 'Instead of taking a turn with the Minions of Utgar, you may take a turn with any Kyrie Warrior you control who follows Utgar.',
      },
      {
        name: 'Deadly Strike',
        desc: 'When attacking with Minions of Utgar all skulls rolled count for one additional hit. ',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Minion of Utgar's movement, ignore elevations. Minions of Utgar may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Minions of Utgar starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Marro Drones',
    singleName: 'Marro Drone',
    armyCardID: 'hs1032',
    image: 'marrodrones.jpg',
    portraitPattern: 'marrodrones-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'common squad',
    cardClass: 'drones',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '3',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Hive Swarm',
        desc: 'Before moving Marro Drones, roll the 20-sided die. If you roll 1 - 12, you may move and attack with up to 3 Marro Drones you control. If you roll 13 - 16, you may move and attack with up to 6 Marro Drones you control. If you roll 17 - 20, you may move and attack with up to 9 Marro Drones you control.',
      },
    ],
  },
  {
    name: 'Knights of Weston',
    singleName: 'Knight of Weston',
    armyCardID: 'hs1033',
    image: 'knightsofweston.jpg',
    portraitPattern: 'knightsofweston-portrait',
    general: 'jandar',
    race: 'human',
    type: 'common squad',
    cardClass: 'knights',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '3',
    defense: '4',
    points: '70',
    figures: '4',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Human Champion Bonding',
        desc: 'Before taking a turn with the Knights of Weston, you may first take a turn with any Human Champion you control.',
      },
      {
        name: "A Coward's Reward",
        desc: 'Knights of Weston roll one additional die against each figure leaving an engagement with him.',
      },
    ],
  },
  {
    name: 'Swog Rider',
    singleName: 'Swog Rider',
    armyCardID: 'hs1034',
    image: 'swogrider.jpg',
    portraitPattern: 'swogrider-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'common hero',
    cardClass: 'beast',
    personality: 'wild',
    height: '6',
    heightClass: 'large',
    life: '1',
    move: '8',
    range: '1',
    attack: '3',
    defense: '3',
    points: '25',
    figures: '1',
    hexes: '2',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Disengage',
        desc: 'Swog Rider is never attacked when leaving an engagement.',
      },
      {
        name: 'Orc Archer Enhancement',
        desc: 'All friendly Orc Archers adjacent to a Swog Rider receive an additional attack die and an additional defense die.',
      },
    ],
  },
  {
    name: '4th Massachusetts Line',
    singleName: '4th Massachusetts Line',
    armyCardID: 'hs1035',
    image: '4thmassachusettsline.jpg',
    portraitPattern: '4thmassachusettsline-portrait',
    general: 'jandar',
    race: 'human',
    type: 'common squad',
    cardClass: 'soldiers',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '2',
    defense: '2',
    points: '70',
    figures: '4',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Wait Then Fire',
        desc: 'If none of the 4th Massachusetts Line move this turn, add 1 die to their attack.',
      },
      {
        name: 'Valiant Army Defense Bonus',
        desc: 'If every Army Card you control has a valiant personality, each soldier in the 4th Massachusetts Line recieves 1 additional defense die.',
      },
    ],
  },
  {
    name: 'Anubian Wolves',
    singleName: 'Anubian Wolf',
    armyCardID: 'hs1036',
    image: 'anubianwolves.jpg',
    portraitPattern: 'anubianwolves-portrait',
    general: 'utgar',
    race: 'wolves',
    type: 'common squad',
    cardClass: 'devourers',
    personality: 'relentless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '75',
    figures: '3',
    hexes: '1',
    setWave: "Wave 2: Utgar's Rage",
    abilities: [
      {
        name: 'Unleashed Fury',
        desc: 'Before moving, roll the 20-sided die. If you roll a 1, choose an Anubian Wolf you control and destroy it. If you roll a 2 - 6, add 1 to the attack value on this card. If you roll a 7 - 11, add 2 to the attack value on this card. If you roll a 12 - 15, add 3 to the attack value on this card. If you roll a 16 - 19, add 4 to the attack value on this card. If you roll a 20, add 8 to the attack value on this card.',
      },
    ],
  },
  {
    name: 'Saylind the Kyrie Warrior',
    singleName: 'Saylind the Kyrie Warrior',
    armyCardID: 'hs1037',
    image: 'saylindthekyriewarrior.jpg',
    portraitPattern: 'saylindthekyriewarrior-portrait',
    general: 'ullar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Spear of Summoning',
        desc: 'After moving and before attacking, choose any figure you control on the battlefield, then roll the 20-sided die. If you roll a 1-8, nothing happens. If you roll a 9-20, move the chosen figure to any space adjacent to Saylind. If the summoned figure is engaged, the figure does not receive any leaving engagement attacks.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Saylind's movement, ignore elevations. Saylind may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Saylind starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: "Johnny 'Shotgun' Sullivan",
    singleName: "Johnny 'Shotgun' Sullivan",
    armyCardID: 'hs1038',
    image: 'johnnyshotgunsullivan.jpg',
    portraitPattern: 'johnnyshotgunsullivan-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'lawman',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '7',
    attack: '2',
    defense: '2',
    points: '65',
    figures: '1',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Shotgun Blast Special Attack',
        desc: 'Range 5. Attack 3. Choose a figure to attack. Any figures adjacent to the chosen figure are also affected by the Shotgun Blast Special Attack. Johnny only needs a clear sight shot at the chosen figure. Roll attack dice once for all affected figures. Each figure rolls defense dice separately. Johnny cannot be affected by his own Shotgun Blast Special Attack.',
      },
    ],
  },
  {
    name: 'Deathwalker 7000',
    singleName: 'Deathwalker 7000',
    armyCardID: 'hs1039',
    image: 'deathwalker7000.jpg',
    portraitPattern: 'deathwalker7000-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'deathwalker',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '7',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Self-Destruct',
        desc: 'After moving and instead of attacking, Deathwalker may choose to self-destruct. Roll the 20-sided die to determine if any other figures are wounded. If you roll a 1-3, all adjacent figures are safe. If you roll a 4-15, each adjacent figure receives 2 wounds. If you roll a 16-19 each adjacent figure receives 4 wounds. If you roll a 20, each adjacent figure receives 8 wounds. After using this power, Deathwalker is always destroyed.',
      },
      {
        name: 'Stealth Dodge',
        desc: 'When Deathwalker 7000 rolls defense dice against an attacking figure who is not adjacent, one shield will block all damage.',
      },
    ],
  },
  {
    name: 'Concan the Kyrie Warrior',
    singleName: 'Concan the Kyrie Warrior',
    armyCardID: 'hs1040',
    image: 'concanthekyriewarrior.jpg',
    portraitPattern: 'concanthekyriewarrior-portrait',
    general: 'jandar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Knight and Sentinel Enhancement',
        desc: 'All friendly Knights and Sentinels adjacent to Concan roll an additional attack die and an additional defense die.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Concan's movement, ignore elevations. Concan may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Concon starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Alastair MacDirk',
    singleName: 'Alastair MacDirk',
    armyCardID: 'hs1041',
    image: 'alastairmacdirk.jpg',
    portraitPattern: 'alastairmacdirk-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Overextend Attack',
        desc: 'After taking a turn with Alastair MacDirk, you may place a wound marker on Alastair MacDirk and take another turn with him.  You may only use this power once during a round.',
      },
    ],
  },
  {
    name: 'Sentinels of Jandar',
    singleName: 'Sentinel of Jandar',
    armyCardID: 'hs1042',
    image: 'sentinelsofjandar.jpg',
    portraitPattern: 'sentinelsofjandar-portrait',
    general: 'jandar',
    race: 'kyrie',
    type: 'common squad',
    cardClass: 'sentinels',
    personality: 'valiant',
    height: '6',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '3',
    defense: '4',
    points: '110',
    figures: '3',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Shields of Valor',
        desc: 'When defending with Sentinels of Jandar, each shield rolled counts for one additional block.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Sentinels of Jandar's movement, ignore elevations. Sentinels of Jandar may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Sentinels of Jandar starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Marrden Hounds',
    singleName: 'Marrden Hound',
    armyCardID: 'hs1043',
    image: 'marrdenhounds.jpg',
    portraitPattern: 'marrdenhounds-portrait',
    general: 'utgar',
    race: 'wulsinu',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'wild',
    height: '4',
    heightClass: 'large',
    life: '1',
    move: '1',
    range: '1',
    attack: '3',
    defense: '5',
    points: '90',
    figures: '3',
    hexes: '2',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Wild Pack Movement',
        desc: 'Before moving, roll the 20-sided die. If you roll a 1-3, add 1 to the move value of this card. If you roll a 4-6, add 3 to the move value of this card. If you roll a 7-20, add 7 to the move value of this card.',
      },
      {
        name: 'Marro Plague',
        desc: 'After moving and before attacking, you must roll the 20-sided die once for each figure adjacent to any Marrden Hounds you control. If you roll a 16 or higher, that figure receives a wound. Soulborgs and Wulsinu are not affected by the Marro Plague.',
      },
    ],
  },
  {
    name: 'Gorillinators',
    singleName: 'Gorillinator',
    armyCardID: 'hs1044',
    image: 'gorillinators.jpg',
    portraitPattern: 'gorillinators-portrait',
    general: 'vydar',
    race: 'primadon',
    type: 'common squad',
    cardClass: 'agents',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '7',
    range: '6',
    attack: '2',
    defense: '1',
    points: '90',
    figures: '3',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Tough',
        desc: 'When rolling defense dice against a normal attack, Gorillinators always add one automatic shield to whatever is rolled.',
      },
    ],
  },
  {
    name: 'Microcorp Agents',
    singleName: 'Microcorp Agent',
    armyCardID: 'hs1045',
    image: 'microcorpagents.jpg',
    portraitPattern: 'microcorpagents-portrait',
    general: 'vydar',
    race: 'human',
    type: 'common squad',
    cardClass: 'agents',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '7',
    attack: '2',
    defense: '3',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Stealth Armor 15',
        desc: 'When a Microcorp Agent receives one or more wounds, before removing that agent, roll the 20-sided die. If you roll a 15 or higher, ignore any wounds.',
      },
      {
        name: 'Water Suits',
        desc: "Microcorp Agents do not have to stop their movement when entering a water space. Add 2 to a Microcorp Agent's defense while he is on a water space.",
      },
      {
        name: 'Sighting',
        desc: 'When a Microcorp Agent is attacking with a height advantage, he rolls an additional attack die',
      },
    ],
  },
  {
    name: 'MacDirk Warriors',
    singleName: 'MacDirk Warrior',
    armyCardID: 'hs1046',
    image: 'macdirkwarriors.jpg',
    portraitPattern: 'macdirkwarriors-portrait',
    general: 'jandar',
    race: 'human',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '2',
    defense: '2',
    points: '80',
    figures: '4',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Highland Fury',
        desc: 'At the start of the game, choose a Human Champion you control. While that champion is in play, MacDirk Warriors roll one additional attack die for each wound marker on the chosen hero. There can be only one Chosen Human Champion for all the MacDirk Warriors you control. MacDirk Warriors cannot attack their Chosen Human Champion.',
      },
      {
        name: 'Human Champion Bonding',
        desc: 'Before taking a turn with the MacDirk Warriors, you may first take a turn with any Human Champion you control.',
      },
    ],
  },
  {
    name: 'Shaolin Monks',
    singleName: 'Shaolin Monk',
    armyCardID: 'hs1047',
    image: 'shaolinmonks.jpg',
    portraitPattern: 'shaolinmonks-portrait',
    general: 'ullar',
    race: 'human',
    type: 'common squad',
    cardClass: 'monks',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '3',
    hexes: '1',
    setWave: "Wave 3: Jandar's Oath",
    abilities: [
      {
        name: 'Shaolin Assault',
        desc: 'A Shaolin Monk may attack any or all figures adjacent to him. Roll each attack separately.',
      },
      {
        name: 'Stealth Leap',
        desc: "Instead of their normal move, any or all Shaolin Monks may Stealth Leap. Stealth Leap has a move 3. When counting spaces for a Monk's leaping movement, ignore elevations. A Monk may leap over water without stopping, pass over figures without becoming engaged, and leap over obstacles such as ruins. A Monk may not leap more than 12 levels up or down in a single leap. If a Monk is engaged when he starts to leap, he does not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Major X17',
    singleName: 'Major X17',
    armyCardID: 'hs1048',
    image: 'majorx17.jpg',
    portraitPattern: 'majorx17-portrait',
    general: 'vydar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'major',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Improved Cyberclaw',
        desc: "All small, medium or large opponent's figures that enter or occupy a space adjacent to Major X17 may not move. Figures affected by the Cyberclaw cannot be moved by any special power on an Army Card or Glyph.",
      },
      {
        name: 'Melee Defense 4',
        desc: 'When rollling defense dice against a normal attack from an adjacent figure, Major X17 adds 4 dice.',
      },
    ],
  },
  {
    name: 'Valguard',
    singleName: 'Valguard',
    armyCardID: 'hs1049',
    image: 'valguard.jpg',
    portraitPattern: 'valguard-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '7',
    move: '5',
    range: '1',
    attack: '2',
    defense: '4',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'First Assault 3',
        desc: 'When attacking with Valguard, if the defending figure was not adjacent to Valguard at the start of this turn, Valguard receives 3 additional attack dice.',
      },
      {
        name: 'Berserker Charge Enhancement',
        desc: 'Add 1 to your die roll when you roll for the Berserker Charge power on any Army Card.',
      },
    ],
  },
  {
    name: 'Parmenio',
    singleName: 'Parmenio',
    armyCardID: 'hs1050',
    image: 'parmenio.jpg',
    portraitPattern: 'parmenio-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Disciplined Influence',
        desc: "At the start of the game, you may choose any Unique Army Card you control. For this game the chosen card's personality is disciplined, regardless of what is listed on the card.",
      },
      {
        name: 'Sacred Band Defy Death 15',
        desc: 'When an adjacent Sacred Band figure receives one or more wounds, roll the 20-sided die before removing that figure. If you roll a 15 or higher, ignore any wounds.',
      },
    ],
  },
  {
    name: 'Morsbane',
    singleName: 'Morsbane',
    armyCardID: 'hs1051',
    image: 'morsbane.jpg',
    portraitPattern: 'morsbane-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '3',
    defense: '2',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Rod of Negation',
        desc: "Start the game with 3 brown(gold) Negation Markers on this card. At the end of the turn, if you have at least 1 Negation Marker on this card, you may choose any opponent's unique figure within 6 clear sight spaces of Morsbane. Roll the 20-sided die. If you roll a 1-15, nothing happens. If you roll a 16-19, place a Negation Marker on the chosen figure's Army Card. All of that figure's special powers are negated for the entire game. If you roll a 20, destroy the chosen figure.",
      },
    ],
  },
  {
    name: 'Sudema',
    singleName: 'Sudema',
    armyCardID: 'hs1052',
    image: 'sudema.jpg',
    portraitPattern: 'sudema-portrait',
    general: 'vydar',
    race: 'undead',
    type: 'unique hero',
    cardClass: 'queen',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '2',
    defense: '3',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Stare of Store',
        desc: 'Instead of attacking, choose any figure within 4 clear sight spaces of Sudema. Roll the 20-sided die. If the chosen figure is a Squad figure and you roll a 7 or higher, destroy it. If the chosen figure is a Hero figure and you roll a 17 or higher, destroy the chosen Hero.',
      },
    ],
  },
  {
    name: 'James Murphy',
    singleName: 'James Murphy',
    armyCardID: 'hs1053',
    image: 'jamesmurphy.jpg',
    portraitPattern: 'jamesmurphy-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'lawman',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '7',
    attack: '2',
    defense: '2',
    points: '75',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Shotgun Blast Special Attack',
        desc: 'Range 5. Attack 3. Choose a figure to attack. Any figures adjacent to the chosen figures are also affected by the Shotgun Blast Special Attack. James only needs a clear sight shot at the chosen figure. Roll attack dice once for all affected figures. Each figure rolls defense dice separately. James cannot be affected by his own Shotgun Blast Special Attack.',
      },
    ],
  },
  {
    name: 'Guilty McCreech',
    singleName: 'Guilty McCreech',
    armyCardID: 'hs1054',
    image: 'guiltymccreech.jpg',
    portraitPattern: 'guiltymccreech-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'lawman',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '2',
    move: '5',
    range: '7',
    attack: '2',
    defense: '2',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Double Attack',
        desc: 'When Guilty McCreech attacks, he may attack one additional time.',
      },
    ],
  },
  {
    name: 'Deadeye Dan',
    singleName: 'Deadeye Dan',
    armyCardID: 'hs1055',
    image: 'deadeyedan.jpg',
    portraitPattern: 'deadeyedan-portrait',
    general: 'ullar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'sniper',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '3',
    move: '5',
    range: '10',
    attack: '1',
    defense: '2',
    points: '60',
    figures: '1',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Ullar Enhanced Rifle Special Attack',
        desc: "Range 10. Attack 1. Choose a small or medium figure to attack. The chosen figure cannot roll defense dice when attacked by Deadeye Dan's Ullar Enhanced Rifle Special Attack. Deadeye Dan may not use this special attack if he moved this turn.",
      },
      {
        name: 'Sharpshooter',
        desc: 'Instead of attacking, you may choose any non-adjacent figure within 10 clear sight spaces of Deadeye Dan. Roll the 20-sided die. If you roll a 19 or 20, the chosen figure is destroyed. If you roll a 1-18, you missed. Deadeye Dan may not use this special power if he moved this turn.',
      },
    ],
  },
  {
    name: 'Tagawa Samurai',
    singleName: 'Tagawa Samurai',
    armyCardID: 'hs1056',
    image: 'tagawasamurai.jpg',
    portraitPattern: 'tagawasamurai-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'samurai',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '3',
    defense: '5',
    points: '120',
    figures: '3',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure. This power does not work against other Samurai.',
      },
      {
        name: 'Bloodlust',
        desc: "For every opponent's figure a Tagawa Samurai destroys, place a purple Experience Marker on this card. When attacking with Tagawa Samurai, roll one additional attack die for each Experience Marker on this card. A maximum of 3 Experience Markers can be placed on this card.",
      },
    ],
  },
  {
    name: 'Aubrien Archers',
    singleName: 'Aubrien Archer',
    armyCardID: 'hs1057',
    image: 'aubrienarchers.jpg',
    portraitPattern: 'aubrienarchers-portrait',
    general: 'ullar',
    race: 'elves',
    type: 'common squad',
    cardClass: 'archers',
    personality: 'precise',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '7',
    attack: '2',
    defense: '2',
    points: '70',
    figures: '3',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Frenzy',
        desc: 'After you take a turn with the Aubrien Archers, roll the 20-sided die. If you roll 16 or higher, you may take another turn with the Aubrien Archers.',
      },
    ],
  },
  {
    name: 'Gladiatrons',
    singleName: 'Gladiatron',
    armyCardID: 'hs1058',
    image: 'gladiatrons.jpg',
    portraitPattern: 'gladiatrons-portrait',
    general: 'vydar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '2',
    defense: '3',
    points: '80',
    figures: '4',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Cyberclaw',
        desc: "All small or medium opponent's figures that enter or occupy a space adjacent to any Gladiatron may not move. Figures affected by the Cyberclaw cannot be moved by any special power on an Army Card or Glyph.",
      },
      {
        name: 'Melee Defense 1',
        desc: 'When rolling defense dice against a normal attack from an adjacent figure, a Gladiatron adds 1 die.',
      },
    ],
  },
  {
    name: 'Sacred Band',
    singleName: 'Sacred Band',
    armyCardID: 'hs1059',
    image: 'sacredband.jpg',
    portraitPattern: 'sacredband-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'soldiers',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '4',
    range: '1',
    attack: '3',
    defense: '2',
    points: '50',
    figures: '4',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Einar Warlord Bonding',
        desc: 'Before taking a turn with Sacred Band, you may first take a turn with any Warlord you control who follows Einar.',
      },
      {
        name: 'Disciplined Army Defense Bonus',
        desc: 'If every Army Card you control has a Disciplined personality, each soldier in the Sacred Band receives 1 additional defense die.',
      },
    ],
  },
  {
    name: 'Armoc Vipers',
    singleName: 'Armoc Viper',
    armyCardID: 'hs1060',
    image: 'armocvipers.jpg',
    portraitPattern: 'armocvipers-portrait',
    general: 'ullar',
    race: 'viper',
    type: 'common squad',
    cardClass: 'protectors',
    personality: 'relentless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '7',
    range: '1',
    attack: '3',
    defense: '3',
    points: '65',
    figures: '3',
    hexes: '1',
    setWave: "Wave 4: Zanafor's Discovery",
    abilities: [
      {
        name: 'Ullar Warlord Bonding',
        desc: 'Before taking a turn with Armoc Vipers, you may first take a turn with any Warlord you control who follows Ullar.',
      },
      {
        name: 'Slither',
        desc: 'Armoc Vipers do not have to stop their movement when entering water spaces.',
      },
    ],
  },
  {
    name: 'Brunak',
    singleName: 'Brunak',
    armyCardID: 'hs1061',
    image: 'brunak.jpg',
    portraitPattern: 'brunak-portrait',
    general: 'utgar',
    race: 'trolticor',
    type: 'unique hero',
    cardClass: 'mount',
    personality: 'ferocious',
    height: '8',
    heightClass: 'huge',
    life: '3',
    move: '6',
    range: '1',
    attack: '4',
    defense: '7',
    points: '110',
    figures: '1',
    hexes: '2',
    setWave: 'Giant Wave 1: Orms Return',
    abilities: [
      {
        name: 'Carry',
        desc: 'Before moving Brunak, choose an unengaged friendly small or medium figure adjacent to Brunak. After you move Brunak, place the chosen figure adjacent to Brunak.',
      },
      {
        name: 'Blood Hungry Special Attack',
        desc: " Range 1. Attack 4. If Brunak's Blood Hungry Special Attack destroys a figure, Brunak may attack again with his Blood Hungry Special Attack. Brunak may continue attacking with his Blood Hungry Special Attack until he does not destroy a figure.",
      },
      {
        name: 'Lava Resistant',
        desc: 'Brunak never rolls for molten lava damage or lava field damage and he does not have to stop in molten lava spaces',
      },
    ],
  },
  {
    name: 'Charos',
    singleName: 'Charos',
    armyCardID: 'hs1062',
    image: 'charos.jpg',
    portraitPattern: 'charos-portrait',
    general: 'ullar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'king',
    personality: 'valiant',
    height: '9',
    heightClass: 'huge',
    life: '9',
    move: '5',
    range: '1',
    attack: '5',
    defense: '5',
    points: '210',
    figures: '1',
    hexes: '1',
    setWave: 'Giant Wave 1: Orms Return',
    abilities: [
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Charos' movement, ignore elevation. Charos may fly over water without stopping. Charos may pass over figures without becoming engaged. Charos may fly over obstacles such as ruins. When Charos starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Deathwalker 8000',
    singleName: 'Deathwalker 8000',
    armyCardID: 'hs1063',
    image: 'deathwalker8000.jpg',
    portraitPattern: 'deathwalker8000-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'deathwalker',
    personality: 'precise',
    height: '7',
    heightClass: 'large',
    life: '1',
    move: '5',
    range: '7',
    attack: '3',
    defense: '8',
    points: '130',
    figures: '1',
    hexes: '1',
    setWave: 'Giant Wave 1: Orms Return',
    abilities: [
      {
        name: 'Rapid Fire Special Attack',
        desc: "Range 7. Attack 3. If Deathwalker's Rapid Fire Special Attack inflicts a wound, he may attack again with his Rapid Fire Special Attack. Deathwalker may continue attacking with his Rapid Fire Special Attack until he does not inflict a wound.",
      },
    ],
  },
  {
    name: 'Dund',
    singleName: 'Dund',
    armyCardID: 'hs1064',
    image: 'dund.jpg',
    portraitPattern: 'dund-portrait',
    general: 'vydar',
    race: 'doggin',
    type: 'unique hero',
    cardClass: 'hunter',
    personality: 'tricky',
    height: '4',
    heightClass: 'large',
    life: '4',
    move: '6',
    range: '1',
    attack: '3',
    defense: '5',
    points: '110',
    figures: '1',
    hexes: '2',
    setWave: 'Giant Wave 1: Orms Return',
    abilities: [
      {
        name: 'Crippling Gaze 15',
        desc: "Before moving, you may choose a figure within 5 clear sight spaces of Dünd. Roll the 20-sided die. If you roll a 15 or higher remove all order markers on the chosen figure's army card (or cards if your opponent has more than one common card for that figure).",
      },
    ],
  },
  {
    name: 'Su-Bak-Na',
    singleName: 'Su-Bak-Na',
    armyCardID: 'hs1065',
    image: 'subakna.jpg',
    portraitPattern: 'subakna-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'hivelord',
    personality: 'tricky',
    height: '12',
    heightClass: 'huge',
    life: '5',
    move: '6',
    range: '1',
    attack: '7',
    defense: '3',
    points: '160',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Hive Supremacy',
        desc: 'Anytime you roll the 20-sided die for a Marro or Wulsinu Army Card, you may add 1 to your die roll.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Su-Bak-Na's movement ignore elevation. Su-Bak-Na may fly over water without stopping. Su-Bak-Na may pass over figures without becoming engaged. Su-Bak-Na may fly over obstacles such as ruins. When Su-Bak-Na starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Braxas',
    singleName: 'Braxas',
    armyCardID: 'hs1066',
    image: 'braxas.jpg',
    portraitPattern: 'braxas-portrait',
    general: 'vydar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'queen',
    personality: 'wild',
    height: '13',
    heightClass: 'huge',
    life: '8',
    move: '6',
    range: '1',
    attack: '5',
    defense: '3',
    points: '210',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Poisonous Acid Breath',
        desc: 'Instead of attacking, you may choose up to 3 different small or medium figures within 4 clear sight spaces of Braxas. One at a time, roll the 20-sided die for each chosen figure. If the chosen figure is a Squad figure and you roll an 8 or higher, destroy it. If the chosen figure is a Hero figure and you roll a 17 or higher, destroy the chosen Hero. (since this is not an attack, engagement rules do not apply)',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Braxas' movement, ignore elevations. Braxas may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Braxas starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Jotun',
    singleName: 'Jotun',
    armyCardID: 'hs1067',
    image: 'jotun.jpg',
    portraitPattern: 'jotun-portrait',
    general: 'ullar',
    race: 'giant',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'wild',
    height: '10',
    heightClass: 'huge',
    life: '7',
    move: '6',
    range: '1',
    attack: '8',
    defense: '4',
    points: '225',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Wild Swing Special Attack',
        desc: 'Range 1. Attack 4. Choose a figure to attack. Any figures adjacent to the chosen figure are also affected by the Wild Swing Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately. Jotun cannot be affected by his own Wild Swing Special Attack.',
      },
      {
        name: 'Throw 14',
        desc: 'After moving and before attacking, choose one small or medium non-flying figure adjacent to Jotun. Roll the 20-sided die. if you roll a 14 or higher, you may throw the figure by placing it on any empty space within 4 spaces of Jotun. The figure must land within clear sight of Jotun. After the figure is placed, roll the 20-sided die for throwing damage. If you roll an 11 or higher, the thrown figure receives 2 wounds. If the figure is thrown onto a level higher than the height of Jotun or onto water, do not roll for throwing damage. The thrown figure does not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Major Q9',
    singleName: 'Major Q9',
    armyCardID: 'hs1068',
    image: 'majorq9.jpg',
    portraitPattern: 'majorq9-portrait',
    general: 'vydar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'major',
    personality: 'precise',
    height: '7',
    heightClass: 'large',
    life: '4',
    move: '5',
    range: '8',
    attack: '4',
    defense: '7',
    points: '180',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Queglix Gun Special Attack',
        desc: 'Range 6. Attack 1, 2 or 3. Major Q9 starts each turn with 9 attack dice. Choose any figure within range and attack by rolling 1, 2 or 3 attack dice. Major Q9 may keep making special attacks with 1, 2 or 3 attack dice until he has rolled all 9 attack dice. Major Q9 may target the same or different figures with each attack.',
      },
    ],
  },
  {
    name: 'Nilfheim',
    singleName: 'Nilfheim',
    armyCardID: 'hs1069',
    image: 'nilfheim.jpg',
    portraitPattern: 'nilfheim-portrait',
    general: 'jandar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'king',
    personality: 'ferocious',
    height: '12',
    heightClass: 'huge',
    life: '6',
    move: '6',
    range: '1',
    attack: '5',
    defense: '4',
    points: '185',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Ice Shard Breath Special Attack',
        desc: 'Range 5. Attack 4. When Nilfheim attacks with his Ice Shard Breath Special Attack, he may attack 2 additional times. He cannot attack the same figure more than once.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Nilfheim's movement, ignore elevations. Nilfheim may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Nilfheim starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Theracus',
    singleName: 'Theracus',
    armyCardID: 'hs1070',
    image: 'theracus.jpg',
    portraitPattern: 'theracus-portrait',
    general: 'ullar',
    race: 'gryphillin',
    type: 'unique hero',
    cardClass: 'scout',
    personality: 'disciplined',
    height: '5',
    heightClass: 'large',
    life: '3',
    move: '7',
    range: '1',
    attack: '3',
    defense: '3',
    points: '40',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 2: Raknar's Vision",
    abilities: [
      {
        name: 'Carry',
        desc: 'Before moving Theracus, choose an unengaged friendly small or medium figure adjacent to Thercus. After you move Theracus, place the chosen figure adjacent to Theracus.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Theracus's movement, ignore elevations. Theracus may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Theracus starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Gurei-Oni',
    singleName: 'Gurei-Oni',
    armyCardID: 'hs1071',
    image: 'gureioni.jpg',
    portraitPattern: 'gureioni-portrait',
    general: 'einar',
    race: 'ogre',
    type: 'unique hero',
    cardClass: 'guard',
    personality: 'tormenting',
    height: '7',
    heightClass: 'large',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 3: Aquilla's Alliance",
    abilities: [
      {
        name: 'Evil Eye Defense',
        desc: 'When rolling defense dice against a normal attack from a non-adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure.',
      },
      {
        name: 'Tetsubo Special Attack',
        desc: 'Range 1. Attack 3. Choose a figure to attack. You may also choose one figure adjacent to the targeted figured to be attacked by the Tetsubo Special Attack as well. Roll attack dice once for both figures. Each figure rolls defense dice separately.',
      },
    ],
  },
  {
    name: 'Sujoah',
    singleName: 'Sujoah',
    armyCardID: 'hs1072',
    image: 'sujoah.jpg',
    portraitPattern: 'sujoah-portrait',
    general: 'aquilla',
    race: 'insect',
    type: 'unique hero',
    cardClass: 'predator',
    personality: 'relentless',
    height: '6',
    heightClass: 'huge',
    life: '6',
    move: '8',
    range: '1',
    attack: '4',
    defense: '4',
    points: '185',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 3: Aquilla's Alliance",
    abilities: [
      {
        name: 'Poison Sting Special Attack',
        desc: "Range 1. Attack 4. If Sujoah inflicts at least 1 wound with Poison Sting Special Attack, roll the 20-sided die for Poison Damage. If you roll 1-9, the defending figure receives no additional wounds for Poison Damage. If you roll 10-19, add 1 additional wound marker to the defending figure's Army Card, and roll again for Poison Damage. If you roll a 20, destroy the defending figure.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Sujoah's movement, ignore elevations. Sujoah may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Sujoah starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Wo-Sa-Ga',
    singleName: 'Wo-Sa-Ga',
    armyCardID: 'hs1073',
    image: 'wosaga.jpg',
    portraitPattern: 'wosaga-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'hivelord',
    personality: 'terrifying',
    height: '10',
    heightClass: 'huge',
    life: '4',
    move: '6',
    range: '1',
    attack: '6',
    defense: '4',
    points: '135',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 3: Aquilla's Alliance",
    abilities: [
      {
        name: 'Coil Crush',
        desc: 'When attacking a small, medium or large figure, if Wo-Sa-Ga inflicts at least 2 wounds, roll the 20-sided die. If you roll a 15 or higher, destroy the defending figure.',
      },
      {
        name: 'Slither',
        desc: 'Wo-Sa-Ga does not have to stop her movement when entering water spaces.',
      },
    ],
  },
  {
    name: 'Zelrig',
    singleName: 'Zelrig',
    armyCardID: 'hs1074',
    image: 'zelrig.jpg',
    portraitPattern: 'zelrig-portrait',
    general: 'einar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'emperor',
    personality: 'disciplined',
    height: '11',
    heightClass: 'huge',
    life: '6',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '185',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 3: Aquilla's Alliance",
    abilities: [
      {
        name: 'Majestic Fires Special Attack',
        desc: 'Range 7. Attack 3. Choose a figure to attack. Any figures adjacent to the targeted figure are also affected by Majestic Fires Special Attack. Common Squad figures roll 2 less defense dice against Majestic Fires Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately. Zelrig cannot be affected by his own Majestic Fires Special Attack.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Zelrig's movement, ignore elevations. Zelrig may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Zelrig starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Zetacron',
    singleName: 'Zetacron',
    armyCardID: 'hs1075',
    image: 'zetacron.jpg',
    portraitPattern: 'zetacron-portrait',
    general: 'jandar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'scout',
    personality: 'precise',
    height: '7',
    heightClass: 'large',
    life: '2',
    move: '5',
    range: '8',
    attack: '2',
    defense: '4',
    points: '60',
    figures: '1',
    hexes: '2',
    setWave: "Giant Wave 3: Aquilla's Alliance",
    abilities: [
      {
        name: 'Deadly Shot',
        desc: 'When attacking with Zetacron, each skull rolled counts for one additional hit. (ie: double the number of skulls rolled)',
      },
    ],
  },
  {
    name: 'Raelin the Kyrie Warrior',
    singleName: 'Raelin the Kyrie Warrior',
    armyCardID: 'hs1076',
    image: 'raelinthekyriewarrior2.jpg',
    portraitPattern: 'raelinthekyriewarrior2-portrait',
    general: 'jandar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'resolute',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Whirlwind Assault',
        desc: 'Raelin may attack any or all figures adjacent to her. Roll each attack separately.',
      },
      {
        name: 'Extended Defensive Aura',
        desc: "All figures you control within 6 clear sight spaces of Raelin add 1 to their defense dice. Raelin's Extended Defensive Aura does not affect Raelin.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Raelin's movement, ignore elevations. Raelin may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Raelin starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Sgt. Drake Alexander',
    singleName: 'Sgt. Drake Alexander',
    armyCardID: 'hs1077',
    image: 'sgtdrakealexander2.jpg',
    portraitPattern: 'sgtdrakealexander2-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'soldier',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '6',
    range: '1',
    attack: '6',
    defense: '4',
    points: '170',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Thorian Speed',
        desc: "Opponents' figures must be adjacent to Sgt. Drake Alexander to attack him with a normal attack.",
      },
      {
        name: 'Grapple Arm',
        desc: 'Instead of his normal move, Sgt. Drake Alexander may use his Grapple Arm. Grapple Arm has a move of 4. When counting spaces for Grapple Arm, ignore elevations. Drake may grapple over water without stopping, over figures wtihout becoming engaged, and over obstacles such as ruins. Drake may not grapple more than 45 levels up or down in a single Grapple Arm move. If Drake is engaged when he starts his Grapple Arm move, he will take any leaving engagement attacks.',
      },
      {
        name: 'Pistol Fire Special Attack',
        desc: 'Range 5. Attack 3. Drake may not use Pistol Fire Special Attack to attack a figure who follows Jandar.',
      },
    ],
  },
  {
    name: 'Sonlen',
    singleName: 'Sonlen',
    armyCardID: 'hs1078',
    image: 'sonlen.jpg',
    portraitPattern: 'sonlen-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'archmage',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '6',
    attack: '4',
    defense: '3',
    points: '160',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Dragon Healing',
        desc: "Before moving, you may choose a wounded Hero figure within 4 clear sight spaces of Sonlen. Roll the 20-sided die. If you roll a 15 or higher, remove one Wound Marker from the chosen Hero's Army Card. Sonlen's Dragon can use its Dragon Healing on Sonlen.",
      },
      {
        name: 'Dragon Swoop',
        desc: 'After moving and before attacking, you may choose any figure within 4 clear sight spaces of Sonlen. Roll the 20-sided die. If you roll a 15 or higher, the chosen figure receives one wound.',
      },
    ],
  },
  {
    name: 'Major Q10',
    singleName: 'Major Q10',
    armyCardID: 'hs1079',
    image: 'majorq10.jpg',
    portraitPattern: 'majorq10-portrait',
    general: 'vydar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'major',
    personality: 'merciful',
    height: '6',
    heightClass: 'large',
    life: '4',
    move: '5',
    range: '8',
    attack: '4',
    defense: '5',
    points: '150',
    figures: '1',
    hexes: '2',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Machine Pistol Special Attack',
        desc: 'Range 7. Attack 2. Major Q10 may use this special attack 4 times in the same turn. Q10 may target the same figure or a different figure with each attack.',
      },
      {
        name: 'Wrist Rocket Special Attack',
        desc: 'Range 4. Attack 4. Major Q10 may use this special attack 2 times in the same turn. Q10 may target the same figure or a different figure with each attack.',
      },
    ],
  },
  {
    name: 'Shiori',
    singleName: 'Shiori',
    armyCardID: 'hs1080',
    image: 'shiori.jpg',
    portraitPattern: 'shiori-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'ninja',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '3',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '60',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Concentrated Will',
        desc: 'If Shiori has only one unrevealed order marker on her Army Card, add 1 to her attack and defense.',
      },
      {
        name: 'Phantom Walk',
        desc: 'Shiori can move through all figures and is never attacked when leaving an engagement.',
      },
      {
        name: 'Shuriken Special Attack',
        desc: 'Range 5. Attack: Special. If Shiori is attacking a small or medium figure, roll 3 attack dice for Shuriken Special Attack. If Shiori is attacking a figure of a different size or a destructible object, roll 2 attack dice for Shuriken Special Attack.',
      },
    ],
  },
  {
    name: 'Marro Drudge',
    singleName: 'Marro Drudge',
    armyCardID: 'hs1081',
    image: 'marrodrudge.jpg',
    portraitPattern: 'marrodrudge-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '5',
    attack: '2',
    defense: '2',
    points: '50',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Swamp Water Strength',
        desc: 'When a Marro Drudge is on a swamp water space, add 1 to its attack and defense.',
      },
      {
        name: 'Swamp Water Tunnel',
        desc: 'If a Marro Drudge ends its normal movement on a swamp water space, you may immediately place it on any empty same-level swamp water space within 5 spaces. If a Marro Drudge is engaged when it starts to tunnel, it will take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Marro Hive',
    singleName: 'Marro Hive',
    armyCardID: 'hs1082',
    image: 'marrohive.jpg',
    portraitPattern: 'marrohive-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'hive',
    personality: 'terrifying',
    height: '17',
    heightClass: 'huge',
    life: '6',
    move: '0',
    range: '1',
    attack: '1',
    defense: '2',
    points: '160',
    figures: '1',
    hexes: '6',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Hive Mind',
        desc: 'After revealing an Order Marker on this Army Card, you may take a turn with any small or medium common Marro Squad you control before taking a turn with Marro Hive. Any figure that is taking a turn must be within 12 clear sight spaces of Marro Hive, prior to its movement.',
      },
      {
        name: 'Marro Rebirth',
        desc: 'After taking a turn with Marro Hive, you may roll the 20-sided die. if you roll a 13 or higher, you may place any previously destroyed common Marro Squad figure from your army on an empty space adjacent to Marro Hive.',
      },
    ],
  },
  {
    name: 'Marrden Nagrubs',
    singleName: 'Marrden Nagrub',
    armyCardID: 'hs1083',
    image: 'marrdennagrubs.jpg',
    portraitPattern: 'marrdennagrubs-portrait',
    general: 'utgar',
    race: 'nagrubs',
    type: 'common squad',
    cardClass: 'guards',
    personality: 'loyal',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '2',
    points: '30',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Hivelord Life Bonding',
        desc: "Before taking a turn with Marrden Nagrubs, you may first take a turn with any Hivelord you control. Before moving the chosen Hivelord, you may destroy one adjacent Marrden Nagrub you control. If you destroy a Marrden Nagrub with Hivelord Life Bonding, remove 1 wound marker from the chosen Hivelord's Army Card.",
      },
      {
        name: 'Climb X2',
        desc: 'When moving up or down levels of terrain, Marrden Nagrubs may double their Height.',
      },
    ],
  },
  {
    name: 'Marro Stingers',
    singleName: 'Marro Stinger',
    armyCardID: 'hs1084',
    image: 'marrostingers.jpg',
    portraitPattern: 'marrostingers-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'common squad',
    cardClass: 'stingers',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '5',
    attack: '3',
    defense: '3',
    points: '60',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Stinger Drain',
        desc: 'After moving and before attacking, you may roll the 20-sided die. If you roll a 1-4, you must destroy a Marro Stinger you control and you cannot attack this turn. If you roll a 5-9, add 0 to the attack value of this card. If you roll a 10 or higher, add 1 to the attack value of this card.',
      },
    ],
  },
  {
    name: 'Tor-Kul-Na',
    singleName: 'Tor-Kul-Na',
    armyCardID: 'hs1085',
    image: 'torkulna.jpg',
    portraitPattern: 'torkulna-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'hivelord',
    personality: 'terrifying',
    height: '11',
    heightClass: 'huge',
    life: '6',
    move: '6',
    range: '1',
    attack: '6',
    defense: '5',
    points: '220',
    figures: '1',
    hexes: '2',
    setWave: 'Master Set: Swarm of the Marro',
    abilities: [
      {
        name: 'Trample Stomp',
        desc: "At any point while moving, Tor-Kul-Na may choose a small or medium figure that is adjacent, on the same level, and on a space where Tor-Kul-Na may end his movement. Roll the 20-sided die. If you roll 1-7, the figure is safe and Tor-Kul-Na's movement ends. If you roll 8-20, the chosen figure receives one wound. If the wound destroys the figure, move Tor-Kul-Na onto the space occupied, and you may continue Tor-Kul-Na's movement. If the chosen figure is not destroyed, Tor-Kul-Na's movement ends. Tor-Kul-Na must be on a space where he can end movement each time he uses this power.",
      },
    ],
  },
  {
    name: 'Ana Karithon',
    singleName: 'Ana Karithon',
    armyCardID: 'hs1086',
    image: 'anakarithon.jpg',
    portraitPattern: 'anakarithon-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'cleric',
    personality: 'merciful',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '4',
    attack: '2',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: 'Turn Undead Special Attack',
        desc: 'Range 4. Attack 4. Turn Undead Special Attack can be used only to attack Undead figures.',
      },
      {
        name: 'Protection From Evil Aura',
        desc: "All small or medium figures you control within 3 clear sight spaces of Ana Karithion add 1 to their defense dice when rolling defense against an attack from a figure who follows Utgar. Ana Karithon's Protection from Evil Aura does not affect Ana Karithon.",
      },
      {
        name: 'Healing Word',
        desc: "After revealing an Order Marker on a wounded small or medium Hero figure you control that is adjacent to Ana Karithon, and before using any other special powers on any Army Card or Treasure Glyph, you may first roll the 20-sided die. If you roll a 1 - 12 nothing happens. If you roll a 13 or higher, remove up to 2 wound markers from that Hero's Army Card.",
      },
    ],
  },
  {
    name: 'Tandros Kreel',
    singleName: 'Tandros Kreel',
    armyCardID: 'hs1087',
    image: 'tandroskreel.jpg',
    portraitPattern: 'tandroskreel-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'fighter',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '7',
    move: '5',
    range: '5',
    attack: '2',
    defense: '4',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: '+2 Broadsword',
        desc: "If Tandros Kreel is attacking an adjacent figure, add 2 dice to Tandros Kreel's attack.",
      },
      {
        name: 'Cleave',
        desc: "When Tandros Kreel attacks an adjacent figure, you may choose one Squad figure that is also adjacent to Tandros Kreel. If the defending figure recieves any wounds from Tandros Kreel's attack, the chosen figure recieves one wound.",
      },
      {
        name: 'Combat Challenge',
        desc: "If an opponent's figure that is adjacent to Tandros Kreel attacks with a normal or special attack, it must attack Tandros Kreel.",
      },
    ],
  },
  {
    name: 'Erevan Sunshadow',
    singleName: 'Erevan Sunshadow',
    armyCardID: 'hs1088',
    image: 'erevansunshadow.jpg',
    portraitPattern: 'erevansunshadow-portrait',
    general: 'jandar',
    race: 'eladrin',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '2',
    defense: '2',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: 'Fire Blast Special Attack',
        desc: "Range 5. Attack: Special. Each time you attack with Erevan Sunshadow's Fire Blast Special Attack, you may choose to roll 2, 3, or 4 attack dice. If a skull is rolled on every die, you may attack again with Erevan Sunshadow' Fire Blast Special Attack. You may continue attacking with Erevan Sunshadow's Fire Blast Special Attack until you do not roll a skull on every die.",
      },
      {
        name: 'Fey Step',
        desc: 'Once per game Erevan Sunshadow may use Fey Step. Before moving normally with Erevan Sunshadow, you may place him on any empty space within 5 spaces of his current location. If Erevan Sunshadow is engaged when he uses Fey Step, he will not take any engagement attacks.',
      },
    ],
  },
  {
    name: 'Darrak Ambershard',
    singleName: 'Darrak Ambershard',
    armyCardID: 'hs1089',
    image: 'darrakambershard.jpg',
    portraitPattern: 'darrakambershard-portrait',
    general: 'aquilla',
    race: 'dwarf',
    type: 'unique hero',
    cardClass: 'rogue',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '2',
    points: '60',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: 'Sneak Attack 2',
        desc: "If Darrak Ambershard is attacking an opponent's figure adjacent to at least one other figure you control, add 2 dice to Darrak Ambershard's attack.",
      },
      {
        name: 'Disarm Traps',
        desc: 'When you roll the 20-sided die for a treasure glyph trap with Darrak Ambershard, you may add 4 to your die roll.',
      },
      {
        name: 'Hide in Darkness',
        desc: 'If Darrak Ambershard is attacked with a normal attack and at least one skull is rolled, roll the 20-sided die. If Darrak Ambershard is on a Dungeon space, add three to your die roll. If he is on a Shadow space, add 6 to your die roll. If you roll a 1-15, roll defence dice normally. If you roll a 16 or higher, ignore all damage that would be inflicted by the attack.',
      },
    ],
  },
  {
    name: 'Pelloth',
    singleName: 'Pelloth',
    armyCardID: 'hs1090',
    image: 'pelloth.jpg',
    portraitPattern: 'pelloth-portrait',
    general: 'utgar',
    race: 'drow',
    type: 'unique hero',
    cardClass: 'arachnomancer',
    personality: 'devout',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '6',
    range: '5',
    attack: '3',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: "Lolth's Wrath Special Attack",
        desc: "Range: Special. Attack 3. Choose a Drow figure you control that is within 5 clear sight spaces of Pelloth and roll 3 attack dice. One at a time for each skull rolled, you may inflict 1 wound on any small or medium figure that is within 2 clear sight spaces of the chosen Drow figure. You may inflict more than 1 wound on a single figure using Lolth's Wrath Special Attack. Figures affected by Lolth's Wrath Special Attack cannot roll any defense dice. After attacking with Lolth's Wrath Special Attack, destroy the chosen Drow figure.",
      },
      {
        name: 'Lurk In Shadows',
        desc: "If Pelloth is on a shadow space, opponents' figures must be adjacent to attack him with a normal attack.",
      },
    ],
  },
  {
    name: 'Feral Troll',
    singleName: 'Feral Troll',
    armyCardID: 'hs1091',
    image: 'feraltroll.jpg',
    portraitPattern: 'feraltroll-portrait',
    general: 'utgar',
    race: 'troll',
    type: 'uncommon hero',
    cardClass: 'hunter',
    personality: 'ferocious',
    height: '7',
    heightClass: 'large',
    life: '8',
    move: '5',
    range: '1',
    attack: '3',
    defense: '1',
    points: '90',
    figures: '1',
    hexes: '2',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: 'Blood Frenzy',
        desc: "When attacking with this Feral Troll, it receives 1 additional attack die for each Wound Marker on the defending figure's Army Card, for a maximum of 3 additional attack dice for Blood Frenzy.",
      },
      {
        name: 'Regenerate',
        desc: "After taking a turn with this Feral Troll, remove 1 Wound Marker from this Feral Troll's army card.",
      },
    ],
  },
  {
    name: 'Deepwyrm Drow',
    singleName: 'Deepwyrm Drow',
    armyCardID: 'hs1092',
    image: 'deepwyrmdrow.jpg',
    portraitPattern: 'deepwyrmdrow-portrait',
    general: 'utgar',
    race: 'drow',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '70',
    figures: '3',
    hexes: '1',
    setWave: 'Master Set: Battle for the Underdark',
    abilities: [
      {
        name: 'Poison Weapons',
        desc: 'Each time a Deepwyrm Drow attacks a small, medium, or large Hero with a normal attack or a leaving engagement attack and inflicts at least 1 wound, roll the 20-sided die. If you roll a 1-11, nothing happens. If you roll a 12 or higher, add 1 additional wound to the defending figure.',
      },
      {
        name: 'Hide in Darkness',
        desc: 'If a Deepwyrm Drow is attacked with a normal attack and at least 1 skull is rolled, roll the 20-sided die. If that Deepwyrm Drow is on a dungeon space, add 3 to your die roll. If it is on a shadow space, add 6 to your die roll. If you roll a 1-15, roll defence dice normally. If you roll a 16 or higher, ignore all damage that would be inflicted by the attack.',
      },
    ],
  },
  {
    name: 'Nakita Agents',
    singleName: 'Nakita Agent',
    armyCardID: 'hs1093',
    image: 'nakitaagents.jpg',
    portraitPattern: 'nakitaagents-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'agents',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '3',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Smoke Powder 13',
        desc: 'When any Nakita Agent you control, or any figure you control that is adjacent to any Nakita agent you control, is targeted for a normal attack from a non-adjacent opponent, you may roll the 20-sided die. If you roll a 13 or higher, all Nakita Agents you control, and all figures you control that are adjacent to those Nakita Agents, no longer have any visible hit zones for the duration of the targeting figure’s turn.',
      },
      {
        name: 'Engagement Strike 15',
        desc: 'If an opponent’s small or medium figure moves adjacent to a Nakita Agent, roll the 20-sided die. If you roll a 15 or higher, the opponent’s figure receives a wound. Figures may only be targeted as they move into engagement with a Nakita Agent.',
      },
      {
        name: 'Gorillinator Movement Bonding',
        desc: 'Before taking a turn with Nakita Agents, you may move 3 Gorillinators you control up to 7 spaces each.',
      },
    ],
  },
  {
    name: 'Spartacus',
    singleName: 'Spartacus',
    armyCardID: 'hs1094',
    image: 'spartacus.jpg',
    portraitPattern: 'spartacus-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'gladiator',
    personality: 'rebellious',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '6',
    defense: '4',
    points: '200',
    figures: '1',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Gladiator Inspiration:',
        desc: 'If all Order Markers for a round are placed on Gladiator Army Cards, and at least one Order Marker is placed on Spartacus, then all Gladiators you control (except Spartacus) become inspired. Inspired Gladiators add one to their Move number and add 1 extra attack die and defense die for the rest of the round.',
      },
    ],
  },
  {
    name: 'Crixus',
    singleName: 'Crixus',
    armyCardID: 'hs1095',
    image: 'crixus.jpg',
    portraitPattern: 'crixus-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'gladiator',
    personality: 'rebellious',
    height: '4',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'One Shield Defense',
        desc: 'When rolling defense dice, if Crixus rolls at least one shield, the most wounds Crixus may take for this attack is one.',
      },
    ],
  },
  {
    name: 'Retiarius',
    singleName: 'Retiarius',
    armyCardID: 'hs1096',
    image: 'retiarius.jpg',
    portraitPattern: 'retiarius-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'gladiator',
    personality: 'rebellious',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Net Trip 14',
        desc: 'After moving and before attacking, roll the 20-sided die. If you roll a 14 or higher, any small or medium figure attacked by Retiarius this turn may roll no more than 1 die for defense.',
      },
    ],
  },
  {
    name: 'Ninjas of the Northern Wind',
    singleName: 'Ninja of the Northern Wind',
    armyCardID: 'hs1097',
    image: 'ninjasofthenorthernwind.jpg',
    portraitPattern: 'ninjasofthenorthernwind-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'ninja',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '4',
    defense: '3',
    points: '110',
    figures: '3',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Disappearing Ninja',
        desc: 'If a Ninja of the Northern Wind is attacked with a normal attack and at least 1 skull is rolled, roll the 20-sided die to disappear. If you roll 1-11, roll defense dice normally. If you roll a 12 or higher, that Ninja of the Northern Wind takes no damage and instead may move up to 4 spaces. Ninjas of the Northern Wind can disappear only if they end their disappearing move not adjacent to any enemy figures.',
      },
      {
        name: 'Phantom Walk',
        desc: 'Ninjas of the Northern Wind can move through all figures and are never attacked when leaving an engagement.',
      },
    ],
  },
  {
    name: 'Kozuke Samurai',
    singleName: 'Kozuke Samurai',
    armyCardID: 'hs1098',
    image: 'kozukesamurai.jpg',
    portraitPattern: 'kozukesamurai-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique squad',
    cardClass: 'samurai',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Charging Assault',
        desc: 'Any or all Kozuke Samurai may add 3 to their Move number as long as they are unengaged prior to moving. Kozuke Samurai must be able to move adjacent to an opponent’s figure in order to use Charging Assault.',
      },
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure. This power does not work against other Samurai.',
      },
    ],
  },
  {
    name: 'Blastatrons',
    singleName: 'Blastatron',
    armyCardID: 'hs1099',
    image: 'blastatrons.jpg',
    portraitPattern: 'blastatrons-portrait',
    general: 'vydar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'guards',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '7',
    attack: '1',
    defense: '2',
    points: '60',
    figures: '4',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Gladiatron Movement Bonding',
        desc: 'Before taking a turn with Blastatrons, you may move 4 Gladiatrons you control up to 5 spaces each.',
      },
      {
        name: 'Homing Device',
        desc: 'When attacking a non-adjacent figure, add 1 attack die for every Soulborg who follows Vydar who you control that is adjacent to the defending figure.',
      },
    ],
  },
  {
    name: 'Death Stalkers',
    singleName: 'Death Stalker',
    armyCardID: 'hs1100',
    image: 'deathstalkers.jpg',
    portraitPattern: 'deathstalkers-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'deathstalkers',
    personality: 'wild',
    height: '5',
    heightClass: 'large',
    life: '1',
    move: '7',
    range: '1',
    attack: '3',
    defense: '5',
    points: '100',
    figures: '3',
    hexes: '2',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Maul',
        desc: 'When rolling attack dice against a small or medium figure, if a Deathstalker rolls a skull on every die, the defending figure receives a wound for every skull, and cannot roll any defense dice.',
      },
    ],
  },
  {
    name: 'Warriors of Ashra',
    singleName: 'Warrior of Ashra',
    armyCardID: 'hs1101',
    image: 'warriorsofashra.jpg',
    portraitPattern: 'warriorsofashra-portrait',
    general: 'ullar',
    race: 'elves',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '3',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Defensive Agility',
        desc: 'When a Warrior of Ashra rolls defense dice against a normal attack from an adjacent figure, one shield will block all damage.',
      },
    ],
  },
  {
    name: 'Deathreavers',
    singleName: 'Deathreaver',
    armyCardID: 'hs1102',
    image: 'deathreavers.jpg',
    portraitPattern: 'deathreavers-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'deathreaver',
    personality: 'tricky',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '1',
    defense: '4',
    points: '30',
    figures: '4',
    hexes: '1',
    setWave: "Wave 5: Thora's Vengeance",
    abilities: [
      {
        name: 'Scatter',
        desc: 'After a Deathreaver you control rolls defense dice against a normal attack from an opponent’s figure, you may move any 2 Deathreavers you control up to 4 spaces each.',
      },
      {
        name: 'Disengage',
        desc: 'Deathreavers are never attacked when leaving an engagement.',
      },
      {
        name: 'Climb X2',
        desc: 'When moving up or down levels of terrain, Deathreavers may double their Height.',
      },
    ],
  },
  {
    name: 'Kaemon Awa',
    singleName: 'Kaemon Awa',
    armyCardID: 'hs1103',
    image: 'kaemonawa.jpg',
    portraitPattern: 'kaemonawa-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'samurai',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '7',
    attack: '4',
    defense: '4',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Quick Release Special Attack',
        desc: 'Range 4. Attack 4. When Kaemon Awa attacks with his Quick Release Special Attack, he may attack one additional time.',
      },
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure. This power does not work against other Samurai.',
      },
    ],
  },
  {
    name: 'Eldgrim the Viking Champion',
    singleName: 'Eldgrim the Viking Champion',
    armyCardID: 'hs1104',
    image: 'eldgrimthevikingchampion.jpg',
    portraitPattern: 'eldgrimthevikingchampion-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '4',
    heightClass: 'medium',
    life: '3',
    move: '5',
    range: '1',
    attack: '2',
    defense: '2',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Overextend Attack',
        desc: 'After taking a turn with Eldgrim, you may place a wound marker on Eldgrim and take another turn with him. You may only use this power once per round.',
      },
      {
        name: "Warrior's Swiftness Spirit",
        desc: "When Eldgrim is destroyed, you may place this figure on any unique Army Card. Eldgrim's Spirit adds 1 to the move number on that card.",
      },
    ],
  },
  {
    name: 'Empress Kiova',
    singleName: 'Empress Kiova',
    armyCardID: 'hs1105',
    image: 'empresskiova.jpg',
    portraitPattern: 'empresskiova-portrait',
    general: 'einar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'disciplined',
    height: '6',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '4',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Gift of the Empress Aura',
        desc: "When you roll defense dice for any Kyrie that you control who follows Einar and is within 5 clear sight spaces of Empress Kiova, you may re-roll all defense dice that did not show shields. Gift of the Empress Aura can be used only once for each defense roll. Empress Kiova's Gift of the Empress Aura does not affect Empress Kiova.",
      },
      {
        name: 'Stealth Flying',
        desc: "When counting spaces for Empress Kiova's movement, ignore elevations. Empress Kiova may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Empress Kiova starts to fly, if she is engaged she will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Kee-Mo-Shi',
    singleName: 'Kee-Mo-Shi',
    armyCardID: 'hs1106',
    image: 'keemoshi.jpg',
    portraitPattern: 'keemoshi-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'warwitch',
    personality: 'tricky',
    height: '6',
    heightClass: 'medium',
    life: '4',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '130',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Mind Shackle 19',
        desc: "After moving and before attacking, you may choose any unique figure adjacent to Kee-Mo-Shi. Roll the 20-sided die. If you roll a 19 or 20, take control of the chosen figure and that figure's Army Card. You now control that Army Card and all figures on it. Remove any Order Markers on the chosen figure's Army Card. If Kee-Mo-Shi is destroyed, you retain control of any previously Mind Shackled Army Cards.",
      },
      {
        name: 'Toxic Skin',
        desc: 'After attacking, you must roll the 20-sided die once for each figure adjacent to Kee-Mo-Shi. If you roll a 17 or higher, that figure receives one wound. Soulborgs are not affected by Toxic Skin.',
      },
    ],
  },
  {
    name: 'Runa',
    singleName: 'Runa',
    armyCardID: 'hs1107',
    image: 'runa.jpg',
    portraitPattern: 'runa-portrait',
    general: 'utgar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Helm of Mitonsoul Aura',
        desc: "After moving and before attacking, Runa may use her Helm of Mitonsoul Aura. When using the Helm of Mitonsoul Aura, you must roll the 20-sided die for all figures within 3 clear sight spaces of Runa, one at a time. If you roll a 20, destroy the figure. Runa's Helm of Mitonsoul Aura does not affect Runa.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Runa's movement, ignore elevations. Runa may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Runa starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Einar Imperium',
    singleName: 'Einar Imperium',
    armyCardID: 'hs1108',
    image: 'einarimperium.jpg',
    portraitPattern: 'einarimperium-portrait',
    general: 'einar',
    race: 'kyrie',
    type: 'common squad',
    cardClass: 'disciplined',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '140',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Double Attack',
        desc: 'When each member of the Einar Imperium attacks, he may attack one additional time.',
      },
      {
        name: 'Stealth Flying',
        desc: "When counting spaces for the Einar Imperium's movement, ignore elevations. The Einar Imperium may fly over water without stopping, pass over obstacles such as ruins. When a member of the Einar Imperium starts to fly, if he is engaged he will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Tagawa Samurai Archers',
    singleName: 'Tagawa Samurai Archer',
    armyCardID: 'hs1109',
    image: 'tagawasamuraiarchers.jpg',
    portraitPattern: 'tagawasamuraiarchers-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'samurai',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '2',
    defense: '3',
    points: '65',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Counter Strike',
        desc: 'When rolling defense dice against a normal attack from an adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure. This power does not work against other Samurai.',
      },
    ],
  },
  {
    name: 'Heavy Gruts',
    singleName: 'Heavy Grut',
    armyCardID: 'hs1110',
    image: 'heavygruts.jpg',
    portraitPattern: 'heavygruts-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '70',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Orc Champion Bonding',
        desc: 'Before taking a turn with Heavy Gruts, you may first take a turn with any Orc Champion you control.',
      },
      {
        name: 'Disengage',
        desc: 'Heavy Gruts are never attacked when leaving engagements.',
      },
    ],
  },
  {
    name: 'Shades of Bleakwoode',
    singleName: 'Shade of Bleakwoode',
    armyCardID: 'hs1111',
    image: 'shadesofbleakwoode.jpg',
    portraitPattern: 'shadesofbleakwoode-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'common squad',
    cardClass: 'devourers',
    personality: 'terrifying',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '7',
    range: '1',
    attack: '2',
    defense: '4',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Soul Devour',
        desc: 'Before moving, each Shade of Bleakwoode you control may choose an adjacent unique hero. Roll the 20-sided die once for each Shade. If you roll 19 or 20, destroy the Shade of Bleakwoode figure, then take control of the chosen unique hero and remove any Order Marker on its card. You now control that Army Card.',
      },
      {
        name: 'Stealth Flying',
        desc: "When counting spaces for Shades of Bleakwoode's movement, ignore elevations. Shades of Bleakwoode may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When a Shade of Bleakwoode starts to fly, if it is engaged it will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Zombies of Morindan',
    singleName: 'Zombie of Morindan',
    armyCardID: 'hs1112',
    image: 'zombiesofmorindan.jpg',
    portraitPattern: 'zombiesofmorindan-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'common squad',
    cardClass: 'savages',
    personality: 'terrifying',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '2',
    defense: '3',
    points: '60',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 6: Dawn of Darkness',
    abilities: [
      {
        name: 'Horde Movement',
        desc: 'You may move up to 6 Zombies of Morindan that you control each turn. However, you may attack with only 3 Zombies of Morindan. You may attack with any 3 Zombies of Morindan, even Zombies of Morindan that you did not move this turn.',
      },
      {
        name: 'Zombie Onslaught Special Attack',
        desc: 'Range 1. Attack 6. Three Zombies of Morindan on the same level may combine their attacks and roll their attack dice as one attack. All Zombies of Morindan in the attack must be engaged to the targeted figure.',
      },
      {
        name: 'Zombies Rise Again',
        desc: "If a Zombie of Morindan that you control destroys an opponent's small or medium figure, replace that figure immediately, if possible, with one of your own previously destroyed Zombies of Morindan. Newly placed Zombies of Morindan cannot attack this turn. Undead are not affected by Zombies Rise Again.",
      },
    ],
  },
  {
    name: 'Cyprien Esenwein',
    singleName: 'Cyprien Esenwein',
    armyCardID: 'hs1113',
    image: 'cyprienesenwein.jpg',
    portraitPattern: 'cyprienesenwein-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'unique hero',
    cardClass: 'lord',
    personality: 'terrifying',
    height: '4',
    heightClass: 'medium',
    life: '6',
    move: '6',
    range: '1',
    attack: '3',
    defense: '4',
    points: '150',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Life Drain',
        desc: ' Each time Cyprien Esenwein destroys a figure, you may remove a wound marker from this Army Card. Cyprien Esenwein cannot Life Drain Destructible Objects.',
      },
      {
        name: 'Chilling Touch',
        desc: 'After moving and before attacking, Cyprien Esenwein may attempt a Chilling Touch. To do this, choose a figure adjacent to Cyprien Esenwein and roll the 20-sided die. If you roll 1-12, nothing happens. If you roll 13-15, the chosen figure receives 1 wound. If you roll 16-17, the chosen figure receives 2 wounds. If you roll 18-19, the chosen figure receives 3 wounds. If you roll a 20 or higher, the chosen figure receives 6 wounds. Cyprien Esenwein’s Chilling Touch does not affect Soulborgs or Destructible Objects.',
      },
      {
        name: 'Stealth Flying',
        desc: 'When counting spaces for Cyprien Esenwein’s movement, ignore elevations. Cyprien Esenwein may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Cyprien Esenwein starts to fly, if he is engaged he will not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Sonya Esenwein',
    singleName: 'Sonya Esenwein',
    armyCardID: 'hs1114',
    image: 'sonyaesenwein.jpg',
    portraitPattern: 'sonyaesenwein-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'unique hero',
    cardClass: 'lady',
    personality: 'terrifying',
    height: '4',
    heightClass: 'medium',
    life: '3',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '45',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Life Drain',
        desc: 'Each time Sonya Esenwein destroys a figure, you may remove a wound marker from this Army Card. Sonya Esenwein cannot Life Drain Destructible Objects.',
      },
      {
        name: 'Eternal Strength',
        desc: 'Anytime you roll the 20-sided die for Cyprien Esenwein’s Chilling Touch, you may add 2 to your die roll.',
      },
      {
        name: 'Eternal Heartbreak',
        desc: 'If you control Cyprien Esenwein and he is destroyed, Sonya Esenwein immediately receives 2 wounds.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Sonya Esenwein's movement, ignore elevations. Sonya Esenwein may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Sonya Esenwein starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Warden 816',
    singleName: 'Warden 816',
    armyCardID: 'hs1115',
    image: 'warden816.jpg',
    portraitPattern: 'warden816-portrait',
    general: 'utgar',
    race: 'soulborg',
    type: 'unique hero',
    cardClass: 'warden',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '2',
    move: '5',
    range: '7',
    attack: '3',
    defense: '6',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Evisceraxe Special Attack',
        desc: 'Range 1. Attack 5. The Evisceraxe Special Attack cannot be used on small figures.',
      },
      {
        name: 'Guard Leadership',
        desc: 'All Guards you control move one additional space.',
      },
    ],
  },
  {
    name: 'Isamu',
    singleName: 'Isamu',
    armyCardID: 'hs1116',
    image: 'isamu.jpg',
    portraitPattern: 'isamu-portrait',
    general: 'utgar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'ninja',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '1',
    points: '10',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Vanish 9',
        desc: 'If Isamu is attacked and at least 1 skull is rolled, roll the 20-sided die to vanish. If you roll 1-8, roll defense dice normally. If you roll a 9 or higher, Isamu takes no damage and may immediately move up to 4 spaces. Isamu can vanish only if he ends his vanishing move not adjacent to any enemy figures.',
      },
      {
        name: 'Phantom Walk',
        desc: 'Isamu can move through all figures and is never attacked when leaving an engagement.',
      },
      {
        name: 'Dishonorable Attack',
        desc: 'When attacking a figure who follows Jandar, Isamu rolls 2 additional attack dice.',
      },
    ],
  },
  {
    name: 'Kyntela Gwyn',
    singleName: 'Kyntela Gwyn',
    armyCardID: 'hs1117',
    image: 'kyntelagwyn.jpg',
    portraitPattern: 'kyntelagwyn-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'valiant',
    height: '4',
    heightClass: 'medium',
    life: '2',
    move: '5',
    range: '1',
    attack: '2',
    defense: '2',
    points: '20',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Strength of Oak Aura 1',
        desc: 'All friendly Elves adjacent to Kyntela Gwyn add 1 to their defense dice.',
      },
    ],
  },
  {
    name: 'Iskra Esenwein',
    singleName: 'Iskra Esenwein',
    armyCardID: 'hs1118',
    image: 'iskraesenwein.jpg',
    portraitPattern: 'iskraesenwein-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'unique hero',
    cardClass: 'duchess',
    personality: 'terrifying',
    height: '4',
    heightClass: 'medium',
    life: '4',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Life Drain',
        desc: 'Each time Iskra Esenwein destroys a figure, you may remove a wound marker from this Army Card. Iskra Esenwein cannot Life Drain Destructible Objects.',
      },
      {
        name: 'Summon the Rechets of Bogdan',
        desc: 'After taking a turn with Iskra Esenwein, you may attempt to summon the Rechets of Bogdan if they are in your army and they have not yet been successfully summoned. Roll the 20-sided die. If you roll a 14 or higher, you must place all 3 Rechets of Bogdan on empty spaces within 6 clear sight spaces of Iskra Esenwein. Any Rechets of Bogdan that you cannot place on the battlefield are immediately destroyed and cannot be summoned again. When the Rechets of Bogdan are summoned, you may immediately take a turn with them.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Iskra Esenwein's movement, ignore elevations. Iskra Esenwein may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Iskra Esenwein starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Rechets of Bogdan',
    singleName: 'Rechet of Bogdan',
    armyCardID: 'hs1119',
    image: 'rechetsofbogdan.jpg',
    portraitPattern: 'rechetsofbogdan-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'unique squad',
    cardClass: 'devourers',
    personality: 'terrifying',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: "Iskra's Summoning",
        desc: 'Rechets of Bogdan do not start the game on the battlefield. They must be summoned onto the battlefield by Iskra Esenwein.',
      },
      {
        name: 'Lethal Sting',
        desc: 'When rolling attack dice against a small or medium figure, if a Rechet of Bogdan rolls a skull on every die, the defending figure cannot roll any defense dice and is immediately destroyed.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for a Rechet's movement, ignore elevations. Rechets may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When a Rechet starts to fly, if it is engaged it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Marcu Esenwein',
    singleName: 'Marcu Esenwein',
    armyCardID: 'hs1120',
    image: 'marcuesenwein.jpg',
    portraitPattern: 'marcuesenwein-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'unique hero',
    cardClass: 'devourer',
    personality: 'terrifying',
    height: '4',
    heightClass: 'medium',
    life: '6',
    move: '7',
    range: '1',
    attack: '4',
    defense: '1',
    points: '20',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Life Drain',
        desc: 'Each time Marcu Esenwein destroys a figure, you may remove a wound marker from this Army Card. Marcu Esenwein cannot Life Drain Destructible Objects.',
      },
      {
        name: 'Eternal Hatred',
        desc: 'After revealing an order marker on this card, you must roll the 20-sided die. If you roll a 17 or higher, choose an opponent. That opponent will now control Marcu Esenwein for the remainder of your turn, but will not be able to view any unrevealed order markers on his card. At the end of that turn, control of Marcu returns to you. All order markers and figures that were on Marcu’s Army Card will stay on his Army Card.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Marcu Esenwein's movement, ignore elevations. Marcu Esenwein may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Marcu Esenwein starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Ashigaru Harquebus',
    singleName: 'Ashigaru Harquebus',
    armyCardID: 'hs1121',
    image: 'ashigaruharquebus.jpg',
    portraitPattern: 'ashigaruharquebus-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'ashigaru',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '2',
    defense: '1',
    points: '60',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Wait Then Fire',
        desc: 'If none of the Ashigaru Harquebus move this turn, add 1 die to their attack.',
      },
    ],
  },
  {
    name: 'Ashigaru Yari',
    singleName: 'Ashigaru Yari',
    armyCardID: 'hs1122',
    image: 'ashigaruyari.jpg',
    portraitPattern: 'ashigaruyari-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'ashigaru',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '2',
    defense: '1',
    points: '40',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Encircle Special Attack',
        desc: 'Range 1. Attack 6. If 3 Ashigaru Yari you control are adjacent to the same enemy figure, they may roll their attack dice as one combined attack. The defending figure compares height to the lowest Ashgaru Yari to determine any height advantage. If Encircle Special Attack is used, the 4th figure that moved this turn cannot attack.',
      },
    ],
  },
  {
    name: 'Templar Cavalry',
    singleName: 'Templar Cavalry',
    armyCardID: 'hs1123',
    image: 'templarcavalry.jpg',
    portraitPattern: 'templarcavalry-portrait',
    general: 'jandar',
    race: 'human',
    type: 'common squad',
    cardClass: 'knights',
    personality: 'valiant',
    height: '6',
    heightClass: 'large',
    life: '1',
    move: '8',
    range: '1',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '3',
    hexes: '2',
    setWave: 'Wave 7: Fields of Valor',
    abilities: [
      {
        name: 'Galloping Charge',
        desc: 'A Templar Cavalry Knight receives 2 additional dice when attacking any figure that was at least 4 hexes away at the start of his turn.',
      },
      {
        name: 'Dismiss the Rabble',
        desc: 'When rolling defense dice against adjacent small or medium Squad figures, Templar Cavalry Knights receive 1 additional defense dice.',
      },
      {
        name: 'Righteous Smite',
        desc: "When attacking an opponent's figure who follows Utgar; Templar Cavalry receive 1 additional attack die.",
      },
    ],
  },
  {
    name: 'Sir Dupuis',
    singleName: 'Sir Dupuis',
    armyCardID: 'hs1124',
    image: 'sirdupuis.jpg',
    portraitPattern: 'sirdupuis-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'knight',
    personality: 'valiant',
    height: '6',
    heightClass: 'large',
    life: '6',
    move: '8',
    range: '1',
    attack: '4',
    defense: '3',
    points: '150',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: "Knight's Courage",
        desc: "Add 1 to Sir Dupuis' attack dice for every Knight you control within 4 clear sight spaces of Sir Dupuis up to a maximum of +3 dice.",
      },
      {
        name: 'Tactical Disengagement 7',
        desc: 'When Sir Dupuis receives one or more wounds from a leaving engagement attack, immediately roll the 20-sided die. If you roll a 7 or higher, ignore any wounds from that leaving engagement attack.',
      },
      {
        name: 'Chalice of Fortitude',
        desc: 'If Sir Dupuis has 4 or more wounds on his Army Card, add 1 to his defense dice.',
      },
    ],
  },
  {
    name: 'Moriko',
    singleName: 'Moriko',
    armyCardID: 'hs1125',
    image: 'moriko.jpg',
    portraitPattern: 'moriko-portrait',
    general: 'ullar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'ninja',
    personality: 'disciplined',
    height: '4',
    heightClass: 'medium',
    life: '4',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Phantom Walk',
        desc: 'Moriko can move through all figures and is never attacked when leaving an engagement.',
      },
      {
        name: 'Saber Storm Special Attack',
        desc: 'Range 1. Attack 1, 2 or 3. Moriko starts each turn with 6 attack dice. Choose any adjacent figure and attack by rolling 1, 2 or 3 attack dice. Moriko may keep making special attacks with 1, 2 or 3 attack dice until she has rolled all 6 attack dice. Moriko may target the same or different figures with each attack.',
      },
    ],
  },
  {
    name: 'Kato Katsuro',
    singleName: 'Kato Katsuro',
    armyCardID: 'hs1126',
    image: 'katokatsuro.jpg',
    portraitPattern: 'katokatsuro-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'daimyo',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '200',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: "Kato Katsuro's Command",
        desc: 'Instead of taking a turn with Kato Katsuro, you may take a turn with one of the following that you control:1 Samurai Hero, or 1 Samurai Squad, or 1 Ashigaru Riflemen Squad and/or 1 Ashigaru Spearmen Squad. You may choose which Squad to activate first.Any figure in the above list that is taking a turn instead of Kato Katsuro must be within clear sight of Kato Katsuro before moving.',
      },
    ],
  },
  {
    name: 'Otonashi',
    singleName: 'Otonashi',
    armyCardID: 'hs1127',
    image: 'otonashi.jpg',
    portraitPattern: 'otonashi-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'ninja',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '3',
    points: '10',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Tricky Speed 4',
        desc: 'If Otonashi starts her turn adjacent to any figure you control with a "Tricky" personality, she may move 4 additional spaces.',
      },
      {
        name: 'Phantom Walk',
        desc: 'Otonashi can move through all figures and is never attacked when leaving an engagement.',
      },
      {
        name: 'Attack the Wild 2',
        desc: 'When attacking a figure who has a "Wild" personality, Otonashi rolls 2 additional attack dice.',
      },
    ],
  },
  {
    name: 'Chardris',
    singleName: 'Chardris',
    armyCardID: 'hs1128',
    image: 'chardris.jpg',
    portraitPattern: 'chardris-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '3',
    defense: '2',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Fire Strike Special Attack',
        desc: "Range 6. Attack 2 + Special. When Chadris attacks with Fire Strike Special Attack add 1 to Chadris' attack dice for every additional Elf Wizard you control within 3 clear sight spaces of Chadris, up to a maximum of +3 dice.",
      },
    ],
  },
  {
    name: 'Ulginesh',
    singleName: 'Ulginesh',
    armyCardID: 'hs1129',
    image: 'ulginesh.jpg',
    portraitPattern: 'ulginesh-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'tricky',
    height: '6',
    heightClass: 'large',
    life: '5',
    move: '7',
    range: '6',
    attack: '3',
    defense: '3',
    points: '150',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Mind Link',
        desc: 'Instead of taking a turn with Ulginesh, you may take a turn with up to 2 different Elf Wizards you control. Ulginesh cannot be one of the 2 Elf Wizards. Any Elf Wizard that is taking a turn instead of Ulginesh must be within 6 clear sight spaces of Ulginesh before moving.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Ulginesh's movement, ignore elevations. Ulginesh may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Ulginesh starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Jorhdawn',
    singleName: 'Jorhdawn',
    armyCardID: 'hs1130',
    image: 'jorhdawn.jpg',
    portraitPattern: 'jorhdawn-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'valiant',
    height: '4',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '3',
    defense: '2',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Rain of Flame Special Attack',
        desc: "Range 7. Attack 1 + Special. Choose a figure to attack. Any figures adjacent to the chosen figure are also affected by the Rain of Flame Special Attack. Add 1 to Jorhdawn's attack dice for every additional Elf Wizard you control within 3 clear sight spaces of Jorhdawn, up to a maximum of +3 dice. Roll attack dice once for all affected figures. Each affected figure rolls defense dice separately. Jorhdawn cannot be affected by her own Rain of Flame Special Attack.",
      },
    ],
  },
  {
    name: 'Emirroon',
    singleName: 'Emirroon',
    armyCardID: 'hs1131',
    image: 'emirroon.jpg',
    portraitPattern: 'emirroon-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Elven Summoning Spell',
        desc: 'After moving and before attacking, you may roll the 20-sided die. Add the number of Elves you control adjacent to Emirroon to your die roll. If you roll 1-4, nothing happens. If you roll 5-11, you may place 1 Elf you control adjacent to Emirroon. If you roll 12-17, you may place up to 2 Elves you control adjacent to Emirroon. If you roll 18-20, you may place up to 3 Elves you control adjacent to Emirroon. If the summoned Elves are engaged, they will not take any leaving engagement attacks. Emirroon cannot summon an Elf that he is already adjacent to.',
      },
    ],
  },
  {
    name: 'Arkmer',
    singleName: 'Arkmer',
    armyCardID: 'hs1132',
    image: 'arkmer.jpg',
    portraitPattern: 'arkmer-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '3',
    move: '5',
    range: '5',
    attack: '4',
    defense: '2',
    points: '50',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Staff of Lerkintin',
        desc: 'When defending with Arkmer, add as many defense dice as the number of Elves you control adjacent to Arkmer.',
      },
      {
        name: 'Engagement Strike 13',
        desc: "If an opponent's small or medium figure moves, adjacent to Arkmer, roll the 20-sided die. If you roll a 13 or higher, the opponent's figure receives one wound. Figures may be targeted only as they move into engagement with Arkmer.",
      },
    ],
  },
  {
    name: '10th Regiment of Foot',
    singleName: '10th Regiment of Foot',
    armyCardID: 'hs1133',
    image: '10thregimentoffoot.jpg',
    portraitPattern: '10thregimentoffoot-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'soldiers',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '2',
    defense: '2',
    points: '75',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Wait Then Fire',
        desc: 'If none of the 10th Regiment of Foot move this turn, add 1 die to their attack.',
      },
      {
        name: 'Melee Defense 1',
        desc: 'When rolling defense dice against a normal attack from an adjacent figure, a Soldier in the 10th Regiment of Foot adds 1 to his defense dice.',
      },
      {
        name: 'Bayonet Attack 1',
        desc: 'When rolling attack dice against an adjacent figure, a Soldier in the 10th Regiment of Foot adds 1 to his attack dice. A Soldier in the 10th Regiment of Foot can only use Bayonet Attack if he moved at least one space this turn.',
      },
    ],
  },
  {
    name: 'Wolves of Badru',
    singleName: 'Wolf of Badru',
    armyCardID: 'hs1134',
    image: 'wolvesofbadru.jpg',
    portraitPattern: 'wolvesofbadru-portrait',
    general: 'utgar',
    race: 'wolves',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'relentless',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Darklord Bonding',
        desc: 'Before taking a turn with Wolves of Badru, you may first take a turn with any Darklord you control.',
      },
      {
        name: 'Climb 2X',
        desc: 'When moving up or down levels of terrain, Wolves of Badru may double their height.',
      },
      {
        name: 'Pounce Special Attack',
        desc: 'Range3. Attack5. A Wolf of Badru that moved but did not attack normally may use Pounce Special Attack. To pounce, choose a non-adjacent small or medium figure whose base is not higher or lower than 5 levels from the base of the attacking Wolf. If the figure is destroyed, immediately place the attacking Wolf on the space the figure occupied. If the figure is not destroyed, destroy the attacking Wolf.',
      },
    ],
  },
  {
    name: 'Grok Riders',
    singleName: 'Grok Rider',
    armyCardID: 'hs1135',
    image: 'grokriders.jpg',
    portraitPattern: 'grokriders-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'menacing',
    height: '5',
    heightClass: 'large',
    life: '1',
    move: '7',
    range: '1',
    attack: '3',
    defense: '3',
    points: '130',
    figures: '3',
    hexes: '2',
    setWave: 'Wave 8: Defenders of Kinsland',
    abilities: [
      {
        name: 'Marro Warlord Bonding',
        desc: 'Before taking a turn with Grok Riders, you may first take a turn with any Marro Warlord you control.',
      },
      {
        name: 'Mark of the Warlord',
        desc: 'When attacking a figure that is adjacent to any Marro Warlord, Grok Riders add 2 to their attack dice.',
      },
      {
        name: 'Grok Training',
        desc: 'Marro Hive cannot rebirth Grok Riders with its rebirth special power.',
      },
    ],
  },
  {
    name: 'Atlaga the Kyrie Warrior',
    singleName: 'Atlaga the Kyrie Warrior',
    armyCardID: 'hs1136',
    image: 'atlagathekyriewarrior.jpg',
    portraitPattern: 'atlagathekyriewarrior-portrait',
    general: 'ullar',
    race: 'kyrie',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'confident',
    height: '6',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '5',
    attack: '4',
    defense: '3',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Kyrie Leadership',
        desc: 'All Kyrie you control, except Atlaga, move one additional space.',
      },
      {
        name: "Ullar's Bolt of the Witherwood",
        desc: "After moving and before attacking with Atlaga, you may choose any opponent's figure within 5 clear sight spaces of Atlaga. Roll the 20-sided die. If you roll a 1 - 15, nothing happens. If you roll a 16 or higher, the chosen figure is destroyed. Atlaga may attempt to use this power only once per game.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Atlaga's movement, ignore elevations. Atlaga may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Atlaga starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Migol Ironwill',
    singleName: 'Migol Ironwill',
    armyCardID: 'hs1137',
    image: 'migolironwill.jpg',
    portraitPattern: 'migolironwill-portrait',
    general: 'aquilla',
    race: 'dwarf',
    type: 'unique hero',
    cardClass: 'leader',
    personality: 'resolute',
    height: '3',
    heightClass: 'small',
    life: '5',
    move: '5',
    range: '1',
    attack: '2',
    defense: '4',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Deadly Strike',
        desc: 'When attacking with Migol Ironwill each skull rolled counts as one additional hit.',
      },
      {
        name: 'One Shield Defense',
        desc: 'When rolling defense dice, if Migol Ironwill rolls at least one shield, the most wounds Migol Ironwill can take for this attack is one',
      },
      {
        name: 'Climb X2',
        desc: 'When moving up or down levels of terrain, Migol Ironwill may double his Height.',
      },
    ],
  },
  {
    name: 'Tul-Bak-Ra',
    singleName: 'Tul-Bak-Ra',
    armyCardID: 'hs1138',
    image: 'tulbakra.jpg',
    portraitPattern: 'tulbakra-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'unique hero',
    cardClass: 'overlord',
    personality: 'terrifying',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Mind Blast Special Attack',
        desc: 'Range 3. Attack 3. Tul-Bak-Ra does not need clear line of sight to attack with the Mind Blast Special Attack.',
      },
      {
        name: 'Teleportation',
        desc: 'Instead of moving Tul-Bak-Ra normally, you may choose any empty space that is on the same level and within 10 spaces of Tul-Bak-Ra. Place Tul-Bak-Ra on the chosen space. When Tul-Bak-Ra starts to teleport, if he is engaged he will not take any leaving engagement attacks.',
      },
      {
        name: 'Teleport Reinforments',
        desc: "When Tul-Bak-Ra receives one or more wounds from an opposing figure's Normal or Special attack but is not destroyed, you may choose one friendly Marro Squad figure for every wound Tul-Bak-Ra just received. Place the chosen figure(s) on any empty space(s) adjacent to Tul-Bak-Ra. If the teleported figure(s) are engaged, they will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Brave Arrow',
    singleName: 'Brave Arrow',
    armyCardID: 'hs1139',
    image: 'bravearrow.jpg',
    portraitPattern: 'bravearrow-portrait',
    general: 'aquilla',
    race: 'human',
    type: 'unique hero',
    cardClass: 'tribesman',
    personality: 'fearsome',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '3',
    points: '50',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Tracking',
        desc: 'While moving, Brave Arrow may add 2 to his move number. If he does, he cannot attack this turn.',
      },
      {
        name: 'Scout Melee Attack Enhancement',
        desc: 'All friendly scouts adjacent to Brave Arrow receive an additional attack die when attacking a figure adjacent to them.',
      },
      {
        name: 'Concealment 10',
        desc: 'If Brave Arrow is targeted and receives one or more wounds from an attacking figure who is not adjacent, you must roll the 20-sided die. Count the minimum number of spaces between the attacker and Brave Arrow. Add this number to your die roll. If you roll a 10 or higher, ignore any wounds Brave Arrow just received.',
      },
    ],
  },
  {
    name: 'Kumiko',
    singleName: 'Kumiko',
    armyCardID: 'hs1140',
    image: 'kumiko.jpg',
    portraitPattern: 'kumiko-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'ninja',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '3',
    move: '6',
    range: '1',
    attack: '3',
    defense: '5',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Ninjutsu Barrage Special Attack',
        desc: 'Range 1. Attack 3. Instead of moving and attacking normally with Kumiko, you may move Kumiko up to 3 spaces. Kumiko can attack up to 3 times with Ninjutsu Barrage Special Attack at any point before, during, or after this move as long as Kumiko is on a space where she could end her movement. Kumiko cannot attack the same figure more than once on a single turn.',
      },
      {
        name: 'Phantom Walk',
        desc: 'Kumiko can move through all figures and is never attacked when leaving an engagement.',
      },
    ],
  },
  {
    name: 'Marro Dividers',
    singleName: 'Marro Divider',
    armyCardID: 'hs1141',
    image: 'marrodividers.jpg',
    portraitPattern: 'marrodividers-portrait',
    general: 'utgar',
    race: 'marro',
    type: 'common squad',
    cardClass: 'dividers',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Cell Divide',
        desc: "When a Marro Divider you control receives one or more wounds from a Normal or Special Attack by an opponent's figure, you may roll the 20-sided die before removing that figure. If you roll a 17 or higher, ignore any wounds that figure just received and, if possible, place one of your previously destroyed Marro Dividers on a same-level space adjacent to the defending Marro Divider.",
      },
      {
        name: 'Self Replicating',
        desc: 'Marro Hive cannot rebirth Marro Dividers with its Marro Rebirth special power.',
      },
    ],
  },
  {
    name: 'Protectors of Ullar',
    singleName: 'Protector of Ullar',
    armyCardID: 'hs1142',
    image: 'protectorsofullar.jpg',
    portraitPattern: 'protectorsofullar-portrait',
    general: 'ullar',
    race: 'kyrie',
    type: 'common squad',
    cardClass: 'protectors',
    personality: 'confident',
    height: '6',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '5',
    attack: '3',
    defense: '3',
    points: '110',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Combined Arbalest',
        desc: 'When attacking with a Protector of Ullar, roll 1 additional attack die for each wound that has been inflicted on the defending figure this turn by Protectors of Ullar you control.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Protectors of Ullar's movement, ignore elevations. Protectors of Ullar may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Protectors of Ullar starts to fly, if he is engaged he will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'The Axe Grinders of Burning Forge',
    singleName: 'Axe Grinder',
    armyCardID: 'hs1143',
    image: 'axegrindersofburningforge.jpg',
    portraitPattern: 'axegrindersofburningforge-portrait',
    general: 'aquilla',
    race: 'dwarves',
    type: 'common squad',
    cardClass: 'fighter',
    personality: 'fearless',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '4',
    range: '1',
    attack: '3',
    defense: '3',
    points: '70',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Dwarven Strategic Bonding',
        desc: "Before taking a turn with The Axegrinders of Burning Forge, you may first take a turn with any Dwarf Hero you control. If you do not take a turn with any Dwarf Hero you control, add 2 to the Axegrinder's movement number.",
      },
      {
        name: 'Fearless Advantage',
        desc: 'An Axegrinder of Burning Forge rolls an additional die when attacking or defending against large or huge figures.',
      },
      {
        name: 'Climb X2',
        desc: 'When moving up or down levels of terrain, The Axegrinders of Burning Forge may double their height.',
      },
    ],
  },
  {
    name: 'Omnicron Repulsors',
    singleName: 'Omnicron Repulsor',
    armyCardID: 'hs1144',
    image: 'omnicronrepulsors.jpg',
    portraitPattern: 'omnicronrepulsors-portrait',
    general: 'jandar',
    race: 'soulborg',
    type: 'common squad',
    cardClass: 'repulsors',
    personality: 'precise',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '5',
    range: '7',
    attack: '1',
    defense: '3',
    points: '40',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Circuitry Overload',
        desc: 'After moving and before attacking, you must roll the 20 sided die once for each Soulborg figure adjacent to any Omnicron Repulsors you control. If the figure is a squad figure and you roll a 13 or higher, destroy that figure. If the figure is a Hero figure and you roll a 16 or higher, that figure receives a wound. Omicron Repulsors are not affected by Circuitry Overload.',
      },
      {
        name: 'Targeting Beacon',
        desc: 'When attacking a non-adjacent figure, all Soulborgs you control who follow Jandar add 1 extra die to their attack if at least one Omnicron Repulsor is adjacent to the defending figure.',
      },
      {
        name: 'EMP Response',
        desc: 'If an Omnicron Repulsor you control successfully defends an attack by a Soulborg figure, you must roll the 20 sided die. If you roll a 14 or higher, the attacking figure must immediately end its turn and all order markers must be removed from its Army Card (or cards if your opponent has more than one).',
      },
    ],
  },
  {
    name: 'Mohican River Tribe',
    singleName: 'Mohican',
    armyCardID: 'hs1145',
    image: 'mohicanrivertribe.jpg',
    portraitPattern: 'mohicanrivertribe-portrait',
    general: 'aquilla',
    race: 'human',
    type: 'common squad',
    cardClass: 'scouts',
    personality: 'fearsome',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '6',
    attack: '2',
    defense: '1',
    points: '70',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Battle Fury',
        desc: 'If a Mohican Rive Tribesman is engaged, add 1 to his attack dice and 2 to his defense dice.',
      },
      {
        name: 'War Cry',
        desc: 'After taking a turn with the Mohican River Tribe, if at least two Mohican River Tribesmen you control are engaged, you may immediately take a turn with one Unique Tribesman Hero you control.',
      },
      {
        name: 'Concealment 19',
        desc: 'If a Mohican River Tribesman is targeted and receives one or more wounds from an attacking figure who is not adjacent, you must roll the 20-sided die. Count the minimum number of spaces between the attacker and the Mochican River Tribesman. Add this number to your die roll. If you roll a 19 or higher, ignore any wounds the Mohican River Tribesman just received.',
      },
    ],
  },
  {
    name: 'Capuan Gladiators',
    singleName: 'Capuan Gladiator',
    armyCardID: 'hs1146',
    image: 'capuangladiators.jpg',
    portraitPattern: 'capuangladiators-portrait',
    general: 'einar',
    race: 'human',
    type: 'common squad',
    cardClass: 'gladiators',
    personality: 'rebellious',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '70',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 9: Blackmoon Siege',
    abilities: [
      {
        name: 'Human Gladiator Bonding',
        desc: 'Before taking a turn with the Capuan Gladiators, you may first take a turn with any Human Gladiator Hero you control.',
      },
      {
        name: 'Initiative Advantage',
        desc: "If all of your Order Markers are on Gladiator Army Cards, you may add 1 to your initiative roll for every Order Marker on the Capuan Gladiators' Army Card, up to a maximum of +3 for Initiative Advantage.",
      },
    ],
  },
  {
    name: 'Sir Hawthorne',
    singleName: 'Sir Hawthorne',
    armyCardID: 'hs1147',
    image: 'sirhawthorne.jpg',
    portraitPattern: 'sirhawthorne-portrait',
    general: 'utgar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'relentless',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: "Wave 10: Valkrill's Gambit",
    abilities: [
      {
        name: 'Blind Rage Special Attack',
        desc: 'Range 1. Attack 3. If Sir Hawthorne rolls at least 2 skulls with his Blind Rage Special Attack, Sir Hawthorne may attack again with his Blind Rage Special Attack. Sir Hawthorn may continue to attack with his Blind Rage Special Attack until he rolls fewer than 2 skulls.',
      },
      {
        name: 'Stab in the Back',
        desc: 'After you take a turn with Sir Hawthorne, you must roll the 20-sided die. If you roll a 1, choose an opponent. That opponent now controls Sir Hawthorne. Remove any order markers on this Army Card, then give the card to that opponent.',
      },
    ],
  },
  {
    name: 'Nerak the Glacian Swog Rider',
    singleName: 'Nerak the Glacian Swog Rider',
    armyCardID: 'hs1148',
    image: 'neraktheglacianswogrider.jpg',
    portraitPattern: 'neraktheglacianswogrider-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'wild',
    height: '6',
    heightClass: 'large',
    life: '3',
    move: '8',
    range: '1',
    attack: '3',
    defense: '3',
    points: '50',
    figures: '1',
    hexes: '2',
    setWave: "Wave 10: Valkrill's Gambit",
    abilities: [
      {
        name: 'Disengage',
        desc: 'Nerak is never attacked when leaving an engagement.',
      },
      {
        name: 'Orc Defensive Aura 1',
        desc: "All Orc figures within 4 clear sight spaces of Nerak add 1 to their defense dice. Nerak's Orc Defensive Aura does not affect Nerak.",
      },
      {
        name: 'Snow Strength',
        desc: "Add 1 to Nerak's attack and defense while on a snow space.",
      },
    ],
  },
  {
    name: 'Elite Onyx Vipers',
    singleName: 'Elite Onyx Viper',
    armyCardID: 'hs1149',
    image: 'eliteonyxvipers.jpg',
    portraitPattern: 'eliteonyxvipers-portrait',
    general: 'ullar',
    race: 'marro',
    type: 'unique squad',
    cardClass: 'scouts',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '7',
    range: '1',
    attack: '3',
    defense: '2',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 10: Valkrill's Gambit",
    abilities: [
      {
        name: 'Slither',
        desc: 'Elite Onyx Vipers do not have to stop their movement when entering water spaces',
      },
      {
        name: 'Frenzy',
        desc: 'After you take a turn with the Elite Onyx Vipers, roll the 20-sided die. If you roll a 16 or higher you may take another turn with the Elite Onyx Vipers.',
      },
      {
        name: 'Evasive 8',
        desc: 'When an Elite Onyx Viper rolls defense dice against an attacking figure who is not adjacent, add 8 defense dice to the defending Viper.',
      },
    ],
  },
  {
    name: 'Granite Guardians',
    singleName: 'Granite Guardian',
    armyCardID: 'hs1150',
    image: 'graniteguardians.jpg',
    portraitPattern: 'graniteguardians-portrait',
    general: 'aquilla',
    race: 'elementar',
    type: 'common squad',
    cardClass: 'guards',
    personality: 'dauntless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '3',
    range: '1',
    attack: '3',
    defense: '5',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 10: Valkrill's Gambit",
    abilities: [
      {
        name: 'Rock Throw',
        desc: "If a Granite Guardian has a height advantage on an opponent's figure, it may add 2 to its range when attacking that figure.",
      },
      {
        name: 'Landslide',
        desc: "A Granite Guardian with a height advantage on an adjacent opponent's figure rolls an additional attack die when attacking that figure.",
      },
      {
        name: 'Gain High Ground',
        desc: 'After taking a turn with Granite Guardians, you may move each Granite Guardian you control up to 1 space. This space may be up to 4 levels higher.',
      },
    ],
  },
  {
    name: 'Quasatch Hunters',
    singleName: 'Quasatch Hunter',
    armyCardID: 'hs1151',
    image: 'quasatchhunters.jpg',
    portraitPattern: 'quasatchhunters-portrait',
    general: 'aquilla',
    race: 'quasatch',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'ferocious',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '4',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: "Wave 10: Valkrill's Gambit",
    abilities: [
      {
        name: 'Feral Rage',
        desc: 'When a Quasatch Hunter attacks, it may attack up to 2 additional times. A Quasatch Hunter cannot attack the same figure more than once per turn.',
      },
      {
        name: 'Techno Hatred',
        desc: 'When attacking a Soulborg figure, Quasatch Hunters receive one additional attack die.',
      },
      {
        name: 'Jungle Tracking',
        desc: 'If a Quasatch Hunter starts its turn next to an Evergreen Tree or a Jungle Piece, it may move 2 additional spaces.',
      },
    ],
  },
  {
    name: 'Earth Elemental',
    singleName: 'Earth Elemental',
    armyCardID: 'hs1152',
    image: 'earthelemental.jpg',
    portraitPattern: 'earthelemental-portrait',
    general: 'einar',
    race: 'elemental',
    type: 'common hero',
    cardClass: 'construct',
    personality: 'fearless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '4',
    defense: '4',
    points: '35',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Earth Slam Special Attack',
        desc: 'Range 1. Attack 3. Any non-flying figures adjacent to this Earth Elemental are affected by Earth Slam Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense separately.',
      },
      {
        name: 'Underground Movement',
        desc: "Instead of moving normally with an Earth Elemental, you may immediately place it on any empty non-water space that is within 4 spaces of that Earth Elemental and is no higher than 1 level above that Earth Elemental's height or 3 levels below that Earth Elemental's base. If an Earth Elemental is engaged when it starts its Underground Movement, it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Fire Elemental',
    singleName: 'Fire Elemental',
    armyCardID: 'hs1153',
    image: 'fireelemental.jpg',
    portraitPattern: 'fireelemental-portrait',
    general: 'utgar',
    race: 'elemental',
    type: 'common hero',
    cardClass: 'construct',
    personality: 'mindless',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '35',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Lava Resistant',
        desc: 'Any non-flying figures adjacent to this Earth Elemental are affected by Earth Slam Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense separately.',
      },
      {
        name: 'Searing Intensity',
        desc: 'After moving and before attacking, you must roll the 20-sided die once for each figure adjacent to at least one Fire Elemental you control. If you roll a 14 or higher, that figure receives 1 wound. Figures with the Lava Resistant special power are not affected by Searing Intensity.',
      },
      {
        name: 'Negative Element',
        desc: 'A Fire Elemental can never roll defense dice while it is on a water space.',
      },
    ],
  },
  {
    name: 'Water Elemental',
    singleName: 'Water Elemental',
    armyCardID: 'hs1154',
    image: 'waterelemental.jpg',
    portraitPattern: 'waterelemental-portrait',
    general: 'aquilla',
    race: 'elemental',
    type: 'common hero',
    cardClass: 'construct',
    personality: 'resolute',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '5',
    attack: '3',
    defense: '4',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Water Mastery',
        desc: 'While a Water Elemental is on a water space, add 1 to its Attack and Defense.',
      },
      {
        name: 'Water Tunnel',
        desc: 'If a Water Elemental ends its normal movement on a water space, you may immediately place it on any empty same-level water space that is within 5 spaces. If a Water Elemental is engaged when it starts its Water Tunnel, it will not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Air Elemental',
    singleName: 'Air Elemental',
    armyCardID: 'hs1155',
    image: 'airelemental.jpg',
    portraitPattern: 'airelemental-portrait',
    general: 'jandar',
    race: 'elemental',
    type: 'common hero',
    cardClass: 'construct',
    personality: 'relentless',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Swirling Vortex',
        desc: "When an opponent's small or medium figure moves onto a space within 2 clear sight spaces of an Air Elemental you control, that figure must end its move there. Figures can never move through any figure affected by Swirling Vortex.",
      },
      {
        name: 'Air Mastery',
        desc: 'Figures that have the Flying or Stealth Flying special power subtract 1 from their defense dice when attacked by an Air Elemental.',
      },
      {
        name: 'Stealth Flying',
        desc: 'When counting spaces for an Air Elemental’s movement, ignore elevations. An Air Elemental may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When an Air Elemental starts to fly, if it is engaged it will not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Wyvern',
    singleName: 'Wyvern',
    armyCardID: 'hs1156',
    image: 'wyvern.jpg',
    portraitPattern: 'wyvern-portrait',
    general: 'aquilla',
    race: 'wyvern',
    type: 'uncommon hero',
    cardClass: 'predator',
    personality: 'ferocious',
    height: '7',
    heightClass: 'large',
    life: '4',
    move: '7',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Talon Grab',
        desc: "While this Wyvern is flying, you may choose one opponent's small or medium figure that it passed over this turn. Place the chosen figure on an empty same-level space adjacent to this Wyvern at the end of its move. If the chosen figure is engaged when it is moved by Talon Grab, it will not take any leaving engagement attacks. Figures under overhangs can never be moved by Talon Grab.",
      },
      {
        name: 'Venomous Sting',
        desc: 'When rolling attack dice, if this Wyvern rolls a skull on every die, the defending figure can not roll defense dice. Venomous Sting does not affect destructible objects.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for this Wyvern's movement, ignore elevations. It may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When it starts to fly, if it is engaged it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Greenscale Warriors',
    singleName: 'Greenscale Warrior',
    armyCardID: 'hs1157',
    image: 'greenscalewarriors.jpg',
    portraitPattern: 'greenscalewarriors-portrait',
    general: 'ullar',
    race: 'lizardfolkd',
    type: 'common squad',
    cardClass: 'warriors',
    personality: 'loyal',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '3',
    points: '60',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Loyalty To The Lizard King',
        desc: 'At the start of the game, choose a unique Lizardfolk Hero you control, or a unique large or huge Dragon Hero you control, to be the Greenscale Warriors Lizard King. A Greenscale Warrior rolls 1 additional attack and defense die when its chosen Lizard King is within 2 clear sight spaces. You can choose only one Lizard King for all the Greenscale Warriors you control.',
      },
      {
        name: 'Lizard King Bonding',
        desc: 'Before taking a turn with the Greenscale Warriors, you may first take a turn with their chosen Lizard King if it is still under your control.',
      },
    ],
  },
  {
    name: 'Drow Chainfighter',
    singleName: 'Drow Chainfighter',
    armyCardID: 'hs1158',
    image: 'drowchainfighter.jpg',
    portraitPattern: 'drowchainfighter-portrait',
    general: 'utgar',
    race: 'drow',
    type: 'common hero',
    cardClass: 'guard',
    personality: 'wild',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '25',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Chain Grab',
        desc: "After moving and before attacking with a Drow Chainfighter, you may choose one opponent's small or medium figure within 3 clear sight spaces whose base is no more than 6 levels above that Drow Chainfighter's height or 6 levels below that Drow Chainfighter's base. Roll the 20-sided die. If you roll a 9 or higher, place the chosen figure on any empty same-level space adjacent to that Drow Chainfighter. If the chosen figure is engaged when it is moved by Chain Grab, it will not take any leaving engagement attacks.",
      },
      {
        name: 'Hide in Darkness',
        desc: 'If a Drow Chainfighter is attacked with a normal attack and at least 1 skull is rolled, roll the 20-sided die. If that Drow Chainfighter is on a dungeon space add 3 to your die roll, if he is on a shadow space add 6 to your die roll. If you roll a 1 - 15, roll defense dice normally. If you roll a 16 or higher, ignore all damage that would be inflicted by the attack.',
      },
    ],
  },
  {
    name: 'Greater Ice Elemental',
    singleName: 'Greater Ice Elemental',
    armyCardID: 'hs1159',
    image: 'greatericeelemental.jpg',
    portraitPattern: 'greatericeelemental-portrait',
    general: 'jandar',
    race: 'elemental',
    type: 'uncommon hero',
    cardClass: 'construct',
    personality: 'dauntless',
    height: '8',
    heightClass: 'huge',
    life: '4',
    move: '5',
    range: '1',
    attack: '6',
    defense: '4',
    points: '130',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Ice Cold',
        desc: 'While a Greater Ice Elemental is on a water or ice space, that space and all same-level water spaces adjacent to that Greater Ice Elemental are considered normal ice spaces. Figures do not have to stop their movement on normal ice spaces',
      },
      {
        name: 'Ice Spikes 15',
        desc: "If an opponent's figure moves adjacent to this Greater Ice Elemental, roll the 20-sided die. If this Greater Ice Elemental is on at least one ice or snow space, add 2 to your die roll. If you roll a 15 or higher, the opponent's figure recieves 1 wound. Figures can be affected by Ice Spikes only as they move into engagement with this Greater Ice Elemental.",
      },
      {
        name: 'Cold Healing',
        desc: "After taking a turn with this Greater Ice Elemental, if it is on at least one snow or ice space, remove 1 wound marker from this Greater Ice Elemental's Army Card.",
      },
    ],
  },
  {
    name: 'Phantom Knights',
    singleName: 'Phantom Knight',
    armyCardID: 'hs1160',
    image: 'phantomknights.jpg',
    portraitPattern: 'phantomknights-portrait',
    general: 'utgar',
    race: 'undead',
    type: 'common squad',
    cardClass: 'knights',
    personality: 'tormented',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '4',
    points: '70',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Insubstantial 3',
        desc: 'A Phantom Knight rolls 3 additional defence dice when rolling defense against a normal attack from a figure who is not adjacent.',
      },
      {
        name: 'Stealth Flying',
        desc: "When counting spaces for a Phantom Knight's movement, ignore elevations. A Phantom Knight may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. If a Phantom Knight is engaged when it starts to fly, it will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Sahuagin Raider',
    singleName: 'Sahuagin Raider',
    armyCardID: 'hs1161',
    image: 'sahuaginraider.jpg',
    portraitPattern: 'sahuaginraider-portrait',
    general: 'utgar',
    race: 'sahuagin',
    type: 'common hero',
    cardClass: 'raider',
    personality: 'ferocious',
    height: '4',
    heightClass: 'medium',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '3',
    points: '25',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Blood Frenzy',
        desc: "When attacking with a Sahuagin Raider, it receives 1 additional attack die for each Wound Marker on the defending figure's Army Card, for a maximum of 3 additional attack dice for Blood Frenzy.",
      },
      {
        name: 'Amphibious',
        desc: 'While a Sahuagin Raider is on a water space, add 2 to its defense. If a Sahuagin Raider starts its turn on a water space, add 1 to its movement for that turn. A Sahuagin Raider does not have to stop its movement when entering a water space.',
      },
    ],
  },
  {
    name: 'Fen Hydra',
    singleName: 'Fen Hydra',
    armyCardID: 'hs1162',
    image: 'fenhydra.jpg',
    portraitPattern: 'fenhydra-portrait',
    general: 'utgar',
    race: 'hydra',
    type: 'uncommon hero',
    cardClass: 'marauder',
    personality: 'ferocious',
    height: '8',
    heightClass: 'huge',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '6',
    points: '120',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Hydra Heads',
        desc: '',
      },
      {
        name: 'Reach',
        desc: "If an opponent's figure is within 2 spaces of this Fen Hydra, and its base is no more than 3 levels above this Fen Hydra's height or 3 levels below this Fen Hyda's base, this Fen Hydra may add 1 to its Range when attacking that figure.",
      },
      {
        name: 'Slither',
        desc: 'The Fen Hydra does not have to stop its movement when entering water spaces.',
      },
    ],
  },
  {
    name: 'Estivara',
    singleName: 'Estivara',
    armyCardID: 'hs1163',
    image: 'estivara.jpg',
    portraitPattern: 'estivara-portrait',
    general: 'utgar',
    race: 'drow',
    type: 'unique hero',
    cardClass: 'arachnomancer',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '80',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Venom Ray Special Attack',
        desc: "Range 5. Attack 3. If Estivara inflicts at least 1 wound with Venom Ray Special Attack, roll the 20-sided die for Venom Damage. If you roll a 1-9, the defending figure receives no additional wounds for Venom Damage. If you roll a 10-19, add 1 additional wound marker to the defending figure's Army Card and roll again for Venom Damage. If you roll a 20, destroy the defending figure.",
      },
      {
        name: "Lolth's Judgment Aura",
        desc: 'All Arachnid figures you control within 6 clear sight spaces of Estivara roll 1 additional attack die when attacking normally.',
      },
      {
        name: 'Cloud of Darkness',
        desc: 'After taking a turn with Estivara, you may roll the 20-sided die. If you roll a 16 or higher, while she remains on that space, Estivara has no visible Hit Zone until the next time you reveal an order marker.',
      },
    ],
  },
  {
    name: 'Torin',
    singleName: 'Torin',
    armyCardID: 'hs1164',
    image: 'torin.jpg',
    portraitPattern: 'torin-portrait',
    general: 'utgar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'hewer',
    personality: 'terrifying',
    height: '7',
    heightClass: 'large',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '120',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Battle Axe',
        desc: "If Torin is attacking a small or medium figure, add 2 dice to Torin's attack.",
      },
      {
        name: 'Evil Eye Protection',
        desc: 'When Torin, or any friendly small or medium Utgar figure adjacent to Torin, rolls defense dice against a normal attack from a non-adjacent attacking figure, all excess shields count as unblockable hits on the attacking figure.',
      },
    ],
  },
  {
    name: 'Sharwin Wildborn',
    singleName: 'Sharwin Wildborn',
    armyCardID: 'hs1165',
    image: 'sharwinwildborn.jpg',
    portraitPattern: 'sharwinwildborn-portrait',
    general: 'ullar',
    race: 'eladrin',
    type: 'unique hero',
    cardClass: 'battle mage',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '2',
    defense: '3',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Arcane Bolt Special Attack',
        desc: 'Range 5 + Special. Attack 3. The first target of Arcane Bolt Special Attack must be within a Range of 5. After attacking with Arcane Bolt Special Attack, if the defending figure receives at least 1 wound, you may choose a figure that was adjacent to the defending figure at the beginning of that attack and attack that chosen figure with Arcane Bolt Special Attack. You may continue attacking with Arcane Bolt Special Attack in this manner until you fail to inflict a wound. A figure cannot be attacked more than once in a turn with Arcane Bolt Special attack.',
      },
      {
        name: 'Arcane Riposte',
        desc: 'When Sharwin Wildborn rolls defense dice against a normal attack, all excess shields count as unblockable hits on the attacking figure.',
      },
      {
        name: 'Shocking Grasp',
        desc: 'When rolling attack dice for a normal attack, Sharwin Wildborn always adds 1 automatic skull to whatever is rolled.',
      },
    ],
  },
  {
    name: 'Brandis Skyhunter',
    singleName: 'Brandis Skyhunter',
    armyCardID: 'hs1166',
    image: 'brandisskyhunter.jpg',
    portraitPattern: 'brandisskyhunter-portrait',
    general: 'ullar',
    race: 'half-elf',
    type: 'unique hero',
    cardClass: 'ranger',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '5',
    attack: '3',
    defense: '3',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Sky Watcher',
        desc: "If Brandis Skyhunter is attacking a non-adjacent figure that has the Flying or Stealth Flying special power, add 2 dice to Brandis Skyhunter's attack.",
      },
      {
        name: "Archer's Glory",
        desc: "Each time Brandis Skyhunter destroys an opponent's non-adjacent Unique Hero, you may take another turn with Brandis Skyhunter.",
      },
    ],
  },
  {
    name: 'Mogrimm Forgehammer',
    singleName: 'Mogrimm Forgehammer',
    armyCardID: 'hs1167',
    image: 'mogrimmforgehammer.jpg',
    portraitPattern: 'mogrimmforgehammer-portrait',
    general: 'aquilla',
    race: 'dwarf',
    type: 'unique hero',
    cardClass: 'warlord',
    personality: 'inspiring',
    height: '4',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '2',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 11: Champions of the Forgotten Realms',
    abilities: [
      {
        name: 'Combat Leader',
        desc: 'If at least one order marker is on Mogrimm Forgehammer, you may add 3 to your initiative roll.',
      },
      {
        name: "Commander's Strike",
        desc: "After moving and before attacking with Mogrimm Forgehammer, you may choose any opponent's figure within 5 clear sight spaces that is engaged with any other figure you control. Roll the 20-sided die. If you roll a 15 or higher, the chosen figure receives 1 wound.",
      },
      {
        name: 'Tough',
        desc: 'When rolling defense dice against a normal attack, Mogrimm Forgehammer always adds 1 automatic shield to whatever is rolled.',
      },
    ],
  },
  {
    name: 'Iron Golem',
    singleName: 'Iron Golem',
    armyCardID: 'hs1168',
    image: 'irongolem.jpg',
    portraitPattern: 'irongolem-portrait',
    general: 'vydar',
    race: 'golem',
    type: 'uncommon hero',
    cardClass: 'construct',
    personality: 'mindless',
    height: '7',
    heightClass: 'large',
    life: '3',
    move: '5',
    range: '1',
    attack: '6',
    defense: '6',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Iron Tough',
        desc: 'When rolling defense dice against a Special Attack, this Iron Golem always adds 2 automatic shields to whatever is rolled.',
      },
      {
        name: 'Lava Resistant',
        desc: 'This Iron Golem never rolls for molten lava damage or lava field damage, and it does not have to stop on molten lava spaces.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Raelin's movement, ignore elevations. Raelin may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Raelin starts to fly, if she is engaged she will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Black Wyrmling',
    singleName: 'Black Wyrmling',
    armyCardID: 'hs1169',
    image: 'blackwyrmling.jpg',
    portraitPattern: 'blackwyrmling-portrait',
    general: 'vydar',
    race: 'dragon',
    type: 'common hero',
    cardClass: 'wyrmling',
    personality: 'wild',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Fledgling Acid Breath',
        desc: 'Instead of attacking with a Black Wyrmling, you may choose one small or medium figure within 4 clear sight spaces of it. Roll the 20-sided die. If you roll a 1 - 10, nothing happens. If you roll an 11 - 15, the chosen figure recieves 1 wound. If you roll a 16 or higher, the chosen figure recieves 2 wounds.',
      },
      {
        name: 'Wyrmling Bonding',
        desc: "After revealing an order marker on a Black Wyrmling Army Card, before taking that Black Wyrmling's turn, you may take a turn with one other Wyrmling you control.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for the Black Wyrmling's movement, ignore elevations. The Black Wyrmling may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When the Black Wyrmling starts to fly, if it is engaged it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'White Wyrmling',
    singleName: 'White Wyrmling',
    armyCardID: 'hs1170',
    image: 'whitewyrmling.jpg',
    portraitPattern: 'whitewyrmling-portrait',
    general: 'jandar',
    race: 'dragon',
    type: 'common hero',
    cardClass: 'wyrmling',
    personality: 'ferocious',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '3',
    defense: '4',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Fledgling Ice Shards Special Attack',
        desc: 'Range 5. Attack 2. When a White Wyrmling attacks with its Fledgling Ice Shards Special Attack, it may attack 1 additional time. It cannot attack the same figure more than once this turn.',
      },
      {
        name: 'Wyrmling Bonding',
        desc: "After revealing an order marker on a White Wyrmling Army Card, before taking that White Wyrmling's turn, you may take a turn with one other Wyrmling you control.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for the White Wyrmling's movement, ignore elevations. The White Wyrmling may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When the White Wyrmling starts to fly, if it is engaged it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Blue Wyrmling',
    singleName: 'Blue Wyrmling',
    armyCardID: 'hs1171',
    image: 'bluewyrmling.jpg',
    portraitPattern: 'bluewyrmling-portrait',
    general: 'aquilla',
    race: 'dragon',
    type: 'common hero',
    cardClass: 'wyrmling',
    personality: 'precise',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '5',
    range: '1',
    attack: '4',
    defense: '3',
    points: '35',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Fledgling Lightning Breath Special Attack',
        desc: 'Range 4 + Special. Attack 2. Choose a figure to attack. You may choose one other figure within 3 clear sight spaces of the targeted figure to be affected by Fledgling Lightning Breath Special Attack. Roll attack dice once for both figures. Each figure rolls defense dice separately. Fledgling Lightning Breath Special Attack does not affect destructible objects.',
      },
      {
        name: 'Wyrmling Bonding',
        desc: "After revealing an order marker on a Blue Wyrmling Army Card, before taking that Blue Wyrmling's turn, you may take a turn with one other Wyrmling you control.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for the Blue Wyrmling's movement, ignore elevations. The Blue Wyrmling may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When the Blue Wyrmling starts to fly, if it is engaged it will take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Red Wyrmling',
    singleName: 'Red Wyrmling',
    armyCardID: 'hs1172',
    image: 'redwyrmling.jpg',
    portraitPattern: 'redwyrmling-portrait',
    general: 'einar',
    race: 'dragon',
    type: 'common hero',
    cardClass: 'wyrmling',
    personality: 'disciplined',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '30',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Fledgling Fires Special Attack',
        desc: 'Range 5. Attack 3. Common Squad figures roll 2 fewer defense dice against Fledgling Fires Special Attack.',
      },
      {
        name: 'Wyrmling Bonding',
        desc: "After revealing an order marker on a Red Wyrmling Army Card, before taking that Red Wyrmling's turn, you may take a turn with one other Wyrmling you control.",
      },
      {
        name: 'Flying',
        desc: "When counting spaces for the Red Wyrmling's movement, ignore elevations. The Red Wyrmling may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When the Red Wyrmling starts to fly, if it is engaged it will take any leaving engagement attacks",
      },
    ],
  },
  {
    name: 'Ogre Warhulk',
    singleName: 'Ogre Warhulk',
    armyCardID: 'hs1173',
    image: 'ogrewarhulk.jpg',
    portraitPattern: 'ogrewarhulk-portrait',
    general: 'utgar',
    race: 'ogre',
    type: 'uncommon hero',
    cardClass: 'warhulk',
    personality: 'wild',
    height: '7',
    heightClass: 'large',
    life: '8',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '150',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Flail Hurricane',
        desc: 'After moving and before attacking with this Ogre Warhulk, you must roll the 20-sided die. If you roll a 1-10, it may attack normally. If you roll an 11 or higher, instead of attacking normally, it must attack each figure adjacent to it, if possible. Roll each attack separately.',
      },
      {
        name: 'Mine!',
        desc: 'When you roll the 20-sided die for a Treasure Glyph trap with this Ogre Warhulk, you must subtract 4 from your die roll.',
      },
    ],
  },
  {
    name: 'Goblin Cutters',
    singleName: 'Goblin Cutter',
    armyCardID: 'hs1174',
    image: 'goblincutters.jpg',
    portraitPattern: 'goblincutters-portrait',
    general: 'utgar',
    race: 'goblin',
    type: 'common squad',
    cardClass: 'cutters',
    personality: 'skittish',
    height: '3',
    heightClass: 'small',
    life: '1',
    move: '6',
    range: '1',
    attack: '2',
    defense: '1',
    points: '50',
    figures: '4',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Mob Attack',
        desc: 'When attacking with a Goblin Cutter you control, if the defending figure is engaged with at least 2 other Goblin Cutters you control, it subtracts 2 from its defense dice.',
      },
      {
        name: 'Scurry',
        desc: "When a Goblin Cutter you control is destroyed by a normal attack from an opponent's figure, you may move 2 Goblin Cutters you control up to 4 spaces each, and all Goblin Cutters you control no longer have any visible Hit Zones for the duration of the attacking figure's turn while they remain unengaged. Goblin Cutters will never take any leaving engagement attacks when using Scurry.",
      },
      {
        name: 'Scale',
        desc: 'When moving up or down levels of terrain, Goblin Cutters may add 2 to their height.',
      },
    ],
  },
  {
    name: 'Warforged Soldiers',
    singleName: 'Warforged Soldier',
    armyCardID: 'hs1175',
    image: 'warforgedsoldiers.jpg',
    portraitPattern: 'warforgedsoldiers-portrait',
    general: 'vydar',
    race: 'warforged',
    type: 'common squad',
    cardClass: 'soldiers',
    personality: 'loyal',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '3',
    defense: '2',
    points: '80',
    figures: '3',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Tactical Switch',
        desc: "When a Warforged Soldier you control attacks an opponent's small or medium figure, you may switch that Warforged Soldier with the defending figure. After switching spaces, the Warforged Soldier must continue that attack, if possible, and cannot attack any other figure this turn. Figures moved by Tactical Switch never take any leaving engagement attacks. An opponent's figure may be moved by Tactical Switch only once per turn.",
      },
      {
        name: 'Warforged Resolve',
        desc: 'When rolling defense dice against a normal or special attack, a Warforged Soldier always adds 1 automatic shield to whatever is rolled.',
      },
    ],
  },
  {
    name: 'Mind Flayer Mastermind',
    singleName: 'Mind Flayer Mastermind',
    armyCardID: 'hs1176',
    image: 'mindflayermastermind.jpg',
    portraitPattern: 'mindflayermastermind-portrait',
    general: 'utgar',
    race: 'mind flayer',
    type: 'uncommon hero',
    cardClass: 'psychic',
    personality: 'terrifying',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Psionic Blast Special Attack',
        desc: "Range 3 Attack 3. This Mind Flayer Mastermind does not need clear line of sight to attack with Psionic Blast Special Attack. If a figure recieves 1 or more wounds from Psionic Blast Special Attack, remove one unrevealed order marker at random from that figure's Army Card (or cards if your opponent has more than one Common card for that figure).",
      },
      {
        name: 'Enslave 17',
        desc: "When revealing an order marker on this Mind Flayer Mastermind, after taking this Mind Flayer Mastermind's turn, you may choose any Unique Hero figure within 4 clear sight spaces of this Mind Flayer Mastermind. Roll the 20-sided die. If you roll a 17 or higher, take temporary control of the chosen Hero and immediately take a turn with that Hero. At the end of that turn, control of the chosen Hero returns to its previous owner. All order markers that were on the figure's Army Card will stay on the Army Card. Enslave does not affect Mindflayers.",
      },
    ],
  },
  {
    name: 'Werewolf Lord',
    singleName: 'Werewolf Lord',
    armyCardID: 'hs1177',
    image: 'werewolflord.jpg',
    portraitPattern: 'werewolflord-portrait',
    general: 'utgar',
    race: 'lycanthrope',
    type: 'uncommon hero',
    cardClass: 'darklord',
    personality: 'relentless',
    height: '9',
    heightClass: 'huge',
    life: '6',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '140',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Lycanthropy',
        desc: "This Werewolf Lord starts the game with 3 green Lycanthropy Markers on its Army Card. If an opponent's Unique Hero receives at least 1 wound from this Werewolf Lord, you may place a Lycanthropy Marker on that figure's Army Card. For the duration of the game, that figure loses its Species, Class, and Personality. Instead, it has the following characteristics: Species: Hybrid, Class: Hunter, Personality: Tormented. Lycanthropy never affects Constructs, Lycanthropes, Soulborgs, or Destructible Objects.",
      },
      {
        name: 'Moon Frenzy',
        desc: "After revealing an Order Marker on this Werewolf Lord, before taking this Werewolf Lord's turn, roll the 20-sided die. If you roll an 11 or higher, you may first take a turn with any Hybrid Hero in play. If you take a turn with an oppenent's Hybrid Hero, you control that Hero for the duration of its turn. At the end of its turn, control of the Hybrid Hero returns to its previous playerID. All Order Markers that were on the figure's Army Card will stay on the Army Card.",
      },
    ],
  },
  {
    name: 'Kurrok the Elementalist',
    singleName: 'Kurrok the Elementalist',
    armyCardID: 'hs1178',
    image: 'kurroktheelementalist.jpg',
    portraitPattern: 'kurroktheelementalist-portrait',
    general: 'utgar',
    race: 'hobgoblin',
    type: 'unique hero',
    cardClass: 'sorceror',
    personality: 'devout',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Summon Elemental',
        desc: 'After moving and before attacking with Kurrok the Elementalist, you may roll the 20-sided die. If you roll an 11 or higher, you may, if possible, place one of your previously destroyed small or medium Elementals on an empty space within 3 clear sight spaces of Kurrok the Elementalist.',
      },
      {
        name: 'Master of the Elements',
        desc: 'After revealing an Order Marker on Kurrok the Elementalist, instead of taking that turn with Kurrok, you may take a turn with up to three small or medium Elementals you control that are within 8 clear sight spaces of Kurrok the Elementalist.',
      },
    ],
  },
  {
    name: 'Rhogar Dragonspine',
    singleName: 'Rhogar Dragonspine',
    armyCardID: 'hs1179',
    image: 'rhogardragonspine.jpg',
    portraitPattern: 'rhogardragonspine-portrait',
    general: 'aquilla',
    race: 'dragonspine',
    type: 'unique hero',
    cardClass: 'paladin',
    personality: 'ferocious',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Dragon Breath Special Attack',
        desc: 'Range: Special. Attack 3. Choose 3 spaces in a straight line from Rhogar Dragonspine. All figures on those spaces that are in line of sight are affected by Dragon Breath Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately.',
      },
      {
        name: 'Lay On Hands',
        desc: "After moving and before attacking, you may choose a wounded friendly Hero figure adjacent to Rhogar Dragonspine and roll the 20-sided die. If you roll a 6 or higher, remove 1 wound marker from the chosen figure's Army Card.",
      },
    ],
  },
  {
    name: 'Shurrak',
    singleName: 'Shurrak',
    armyCardID: 'hs1180',
    image: 'shurrak.jpg',
    portraitPattern: 'shurrak-portrait',
    general: 'utgar',
    race: 'giant',
    type: 'unique hero',
    cardClass: 'warrior',
    personality: 'militiaristic',
    height: '8',
    heightClass: 'huge',
    life: '6',
    move: '6',
    range: '1',
    attack: '5',
    defense: '4',
    points: '160',
    figures: '1',
    hexes: '2',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Sweeping Sword Special Attack',
        desc: 'Range 1. Attack 5. Choose a figure to attack. Any figures adjacent to both Shurrak and the chosen figure are also affected by Sweeping Sword Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately.',
      },
      {
        name: 'Knockback 14',
        desc: 'When a small or meduim figure rolls defense dice and is not destroyed by a normal or special attack from Shurrak, immediately roll the 20-sided die. If you roll a 14 or higher, you may Knockback that figure by placing it on any empty space within 3 clear sight spaces of Shurrak. The space must be on the same level as or lower than its current location. A figure moved by Knockback never takes any leaving engagement attack. A non-flying figure moved lower by Knockback can recieve any falling damage that may apply.',
      },
      {
        name: 'Lava Resistant',
        desc: 'Shurrak never rolls for molten lava damage or lava field damage, and he does not have to stop on molten lava spaces.',
      },
    ],
  },
  {
    name: 'Heirloom',
    singleName: 'Heirloom',
    armyCardID: 'hs1181',
    image: 'heirloom.jpg',
    portraitPattern: 'heirloom-portrait',
    general: 'vydar',
    race: 'warforged',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '1',
    attack: '4',
    defense: '2',
    points: '90',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Force Orb Special Attack',
        desc: "Range 5. Attack 3. Choose an opponent's figure to attack. Each opponent's figure adjacent to the chosen figure is also affected by Force Orb Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately.",
      },
      {
        name: 'Mage Hand',
        desc: 'After moving and before attacking with Heirloom, you may choose a Treasure Glyph on an unoccupied space within 4 spaces of Heirloom. You may move that Treasure Glyph onto an empty same-level space adjacent to Heirloom. Symbol-side-up Treasure Glyphs cannot be revealed, and power-side-up Treasure Glyphs must be placed power-side-up when moved by Heirloom.',
      },
      {
        name: 'Warforged Resolve',
        desc: 'When rolling defense dice against a normal or special attack, Heirloom always adds 1 automatic shield to whatever is rolled.',
      },
    ],
  },
  {
    name: 'Mika Connour',
    singleName: 'Mika Connour',
    armyCardID: 'hs1182',
    image: 'mikaconnour.jpg',
    portraitPattern: 'mikaconnour-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'shadow assassin',
    personality: 'tricky',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Wave 12: Warriors of Eberron',
    abilities: [
      {
        name: 'Shadow Ambush',
        desc: 'If Mika Connour starts her turn unengaged and on a shadow space, add 3 to her attack this turn.',
      },
      {
        name: 'Shadow Dance ',
        desc: 'If Mika Connour starts her turn on a shadow space, instead of moving normally, you may place her on any other empty shadow space within 8 spaces of her current location. If Mika Connour is engaged when she starts her Shadow Dance, she will take any leaving engagement attacks.',
      },
      {
        name: 'Blend Into Shadow',
        desc: 'Mika Connour starts each game with up to 2 shadow tiles on her Army Card (included in expansion pack). If Mika Connour ends her turn on an empty land space, you may place a shadow tile from her Army Card onto the space she occupies if the shadow tile fits normally onto that space.',
      },
    ],
  },
  {
    name: 'Death Chasers of Thesk',
    singleName: 'Death Chaser of Thesk',
    armyCardID: 'hs1183',
    image: 'deathchasersofthesk.jpg',
    portraitPattern: 'deathchasersofthesk-portrait',
    general: 'valkrill',
    race: 'orcs',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '4',
    defense: '2',
    points: '55',
    figures: '3',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Taskmaster Bonding',
        desc: 'Before taking a turn with the Death Chasers of Thesk, you may first take a turn with any large wild Hero you control.',
      },
      {
        name: 'Orc Battle Rush',
        desc: 'At the start of the first round of a game, after all order markers have been placed, you may move all Death Chasers of Thesk you control up to 5 spaces each. They must end their Orc Battle Rush movement on an empty space.',
      },
    ],
  },
  {
    name: 'Horned Skull Brutes',
    singleName: 'Horned Skull Brute',
    armyCardID: 'hs1184',
    image: 'hornedskullbrutes.jpg',
    portraitPattern: 'hornedskullbrutes-portrait',
    general: 'valkrill',
    race: 'goblin',
    type: 'common squad',
    cardClass: 'brutes',
    personality: 'merciless',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '75',
    figures: '3',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Expendable Rabble ',
        desc: "If a Horned Skull Brute that you control is attacked with a normal attack against an opponent's figure and receives 1 or more wounds, you may destroy a small Goblin figure that you control that is adjacent to that Horned Skull Brute to ignore any wounds that Horned Skull Brute just received. ",
      },
      {
        name: 'Barge Into Battle ',
        desc: 'After moving and before attacking, if a Horned Skull Brute you control ends its movement unengaged, you may choose an engaged small or medium friendly squad figure within 3 clear sight spaces of that Horned Skull Brute whose base is no more than 2 levels higher or lower than the base of the Horned Skull Brute. You may switch that Horned Skull Brute with the chosen squad figure. Figures moved by Barge Into Battle never take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Mezzodemon Warmongers',
    singleName: 'Mezzodemon Warmonger',
    armyCardID: 'hs1185',
    image: 'mezzodemonwarmongers.jpg',
    portraitPattern: 'mezzodemonwarmongers-portrait',
    general: 'valkrill',
    race: 'demons',
    type: 'common squad',
    cardClass: 'warmongers',
    personality: 'ferocious',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '4',
    attack: '3',
    defense: '3',
    points: '65',
    figures: '2',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Poison Cloud',
        desc: 'When a Mezzodemon Warmonger attacks a Common figure, add 1 to its attack value.',
      },
      {
        name: 'Exoskeleton',
        desc: 'At the start of the game, place 1 copper Exoskeleton marker on a Mezzodemon Warmongers Army Card for each Mezzodemon Warmonger figure in your Army. After a Mezzodemon Warmonger rolls defense dice against a normal attack, you may remove 1 Exoskeleton marker from its Army Card to ignore all wounds inflicted by that attack.',
      },
    ],
  },
  {
    name: 'Death Knights of Valkrill',
    singleName: 'Death Knight of Valkrill',
    armyCardID: 'hs1186',
    image: 'deathknightsofvalkrill.jpg',
    portraitPattern: 'deathknightsofvalkrill-portrait',
    general: 'valkrill',
    race: 'undead',
    type: 'common squad',
    cardClass: 'death knights',
    personality: 'tormented',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '2',
    defense: '5',
    points: '60',
    figures: '2',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Soul Weapons',
        desc: 'Figures attacked by a Death Knight of Valkrill subtract two from their defense dice. Destructible Objects are not affected by Soul Weapons.',
      },
      {
        name: 'Unholy Bonding',
        desc: 'Before taking a turn with the Death Knights of Valkrill, you may first take a turn with any small, medium or large relentless Hero you control.',
      },
    ],
  },
  {
    name: 'Ogre Pulverizer',
    singleName: 'Ogre Pulverizer',
    armyCardID: 'hs1187',
    image: 'ogrepulverizer.jpg',
    portraitPattern: 'ogrepulverizer-portrait',
    general: 'utgar',
    race: 'ogre',
    type: 'uncommon hero',
    cardClass: 'pulverizer',
    personality: 'wild',
    height: '6',
    heightClass: 'large',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '2',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Double Attack',
        desc: 'When this Ogre Pulverizer attacks, it may attack one additional time.',
      },
      {
        name: 'Lumbering Bully',
        desc: 'When attacking with this Ogre Pulverizer, all excess shields rolled by the defending figure count as unblockable hits inflicted by the defending figure on this Ogre Pulverizer.',
      },
    ],
  },
  {
    name: 'Frost Giant of Morh',
    singleName: 'Frost Giant of Morh',
    armyCardID: 'hs1188',
    image: 'frostgiantofmorh.jpg',
    portraitPattern: 'frostgiantofmorh-portrait',
    general: 'utgar',
    race: 'giant',
    type: 'uncommon hero',
    cardClass: 'brute',
    personality: 'fearless',
    height: '9',
    heightClass: 'huge',
    life: '6',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Indomitable',
        desc: 'If this Frost Giant of Morh begins its turn unengaged, add 2 to its Move value this turn. If it begins its turn engaged, add 2 to its Attack value this turn.',
      },
      {
        name: 'Battle Frenzy',
        desc: 'After attacking with this Frost Giant of Morh, roll the 20-sided die. If you roll a 16 or higher, you may attack again with this Frost Giant of Morh.',
      },
      {
        name: 'Dying Swipe',
        desc: "If this Frost Giant of Morh is attacked with a normal attack by an opponent's figure and receives enough wounds to be destroyed, roll 3 attack dice. One at a time for each skull rolled, you may inflict 1 wound on any figure that is adjacent to this Frost Giant of Morh. Figures affected by Dying Swipe cannot roll any defense dice and can receive more than 1 wound each. After using Dying Swipe, remove this Frost Giant of Morh from the battlefield.",
      },
    ],
  },
  {
    name: 'Ice Troll Berserker',
    singleName: 'Ice Troll Berserker',
    armyCardID: 'hs1189',
    image: 'icetrollberserker.jpg',
    portraitPattern: 'icetrollberserker-portrait',
    general: 'utgar',
    race: 'troll',
    type: 'uncommon hero',
    cardClass: 'beast',
    personality: 'ferocious',
    height: '5',
    heightClass: 'large',
    life: '4',
    move: '5',
    range: '1',
    attack: '5',
    defense: '2',
    points: '85',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Ice Troll Charge',
        desc: 'After moving and before attacking with this Ice Troll Berserker, roll the 20-sided die. If this Ice Troll Berserker is on a snow or ice space, add 3 to your die roll. If you roll a 13 or higher, you may move this Ice Troll Berserker again.',
      },
      {
        name: 'Cold Regenerate',
        desc: "After taking a turn with this Ice Troll Berserker, remove 1 wound marker from this Ice Troll Berserker's Army Card. If it is on a snow or ice space, remove 1 additional wound marker.",
      },
    ],
  },
  {
    name: 'Master of the Hunt',
    singleName: 'Master of the Hunt',
    armyCardID: 'hs1190',
    image: 'masterofthehunt.jpg',
    portraitPattern: 'masterofthehunt-portrait',
    general: 'einar',
    race: 'firbolg',
    type: 'unique hero',
    cardClass: 'hunter',
    personality: 'bold',
    height: '6',
    heightClass: 'large',
    life: '6',
    move: '6',
    range: '4',
    attack: '4',
    defense: '4',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Mortal Strike',
        desc: "After attacking an opponent's Unique Hero with this Master of the Hunt's normal attack, roll 1 attack die for each wound inflicted in that attack. For each additional skull rolled, place 1 additional wound Marker on the defending figure's Army Card.",
      },
      {
        name: 'Javelin',
        desc: "After moving and before attacking with this Master of the Hunt, you may roll the 20-sided die. If you roll a 16 or higher, add 3 to this Master of the Hunt's Range value for the duration of its turn.",
      },
    ],
  },
  {
    name: 'Eltahale',
    singleName: 'Eltahale',
    armyCardID: 'hs1191',
    image: 'eltahale.jpg',
    portraitPattern: 'eltahale-portrait',
    general: 'jandar',
    race: 'goliath',
    type: 'unique hero',
    cardClass: 'warden',
    personality: 'fearless',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '6',
    range: '1',
    attack: '5',
    defense: '4',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Thunder Ram Assault Special Attack',
        desc: 'Range 1 + Special. Attack 4. Choose a figure to attack. You may also choose up to two other figures within 2 clear sight spaces of the targeted figure to be affected by Thunder Ram Assault Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately. You can only use Thunder Ram Assault Special Attack if Eltahale was not adjacent to the targeted figure at the start of this turn. You cannot use Thunder Ram Assault Special Attack if you used Thunder Step this turn.',
      },
      {
        name: 'Thunder Step',
        desc: "Instead of moving Eltahale normally, you may choose an opponent's figure within 5 clear sight spaces of Eltahale. Place Eltahale on any empty space adjacent to the chosen figure. For the duration of Eltahale's turn, add 1 to her Attack value. At the end of her turn, roll 1 attack die. If a skull is rolled, place 1 wound marker on Eltahale's Army Card. If Eltahale is engaged when she starts Thunder Step, she will not take any leaving engagement attacks.",
      },
    ],
  },
  {
    name: 'Siege',
    singleName: 'Siege',
    armyCardID: 'hs1192',
    image: 'siege.jpg',
    portraitPattern: 'siege-portrait',
    general: 'vydar',
    race: 'warforged',
    type: 'unique hero',
    cardClass: 'juggernaut',
    personality: 'stoic',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '5',
    defense: '5',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Crag of Steel',
        desc: "When revealing a numbered order marker on Siege's Army Card, you may also reveal an \"X\" order marker that is on Siege's Army Card to activate Crag of Steel for the duration of the round. While Crag of Steel is active, add 3 to Siege's Defense value and subtract 2 from Siege's Attack and Move values. When Siege attacks while Crag of Steel is active, he may attack any or all figures adjacent to him. Roll each attack separately.",
      },
    ],
  },
  {
    name: 'Evar Scarcarver',
    singleName: 'Evar Scarcarver',
    armyCardID: 'hs1193',
    image: 'evarscarcarver.jpg',
    portraitPattern: 'evarscarcarver-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'frostrager',
    personality: 'reckless',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '4',
    attack: '2',
    defense: '3',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Double Assault',
        desc: 'When Evar Scarcarver attacks an adjacent figure or destructible object, he may attack one additional time.',
      },
      {
        name: 'Frost Rage',
        desc: "When revealing a numbered order marker on Evar Scarcarver's Army Card, you may also reveal an \"X\" order marker that is on Evar Scarcarver's Army Card to activate Frost Rage for the duration of the round. While Frost Rage is active, add 1 to Evar Scarcarver's Attack and Defense values for each wound marker on his Army Card.",
      },
    ],
  },
  {
    name: 'Moltenclaw',
    singleName: 'Moltenclaw',
    armyCardID: 'hs1194',
    image: 'moltenclaw.jpg',
    portraitPattern: 'moltenclaw-portrait',
    general: 'utgar',
    race: 'dragon',
    type: 'unique hero',
    cardClass: 'prince',
    personality: 'fearless',
    height: '8',
    heightClass: 'huge',
    life: '6',
    move: '5',
    range: '1',
    attack: '5',
    defense: '3',
    points: '170',
    figures: '1',
    hexes: '1',
    setWave: "Wave 13: Moltenclaw's Invasion",
    abilities: [
      {
        name: 'Burning Breath Special Attack',
        desc: 'Range 4 Special. Attack 4. Choose a figure to attack, you may also choose 4 spaces in a straight line from the targeted figure. All figures on those spaces that are within line of sight of Moltenclaw are affected by Burning Breath Special Attack. Roll attack dice once for all affected figures. Each figure rolls defense dice separately, Moltenclaw is not affected by Burning Breath Special Attack',
      },
      {
        name: 'Lava Resistant',
        desc: 'Moltenclaw never rolls for molten lava damage and lava field damage, and he does not have to stop on molten lava spaces.',
      },
      {
        name: 'Flying',
        desc: "When counting spaces for Moltenclaw's movement, ignore elevations. Moltenclaw may fly over water without stopping, pass over figures without becoming engaged, and fly over obstacles such as ruins. When Moltenclaw starts to fly, if it is engaged it will take any leaving engagement attacks",
      },
    ],
  },
  {
    name: 'Dumutef Guard',
    singleName: 'Dumutef Guard',
    armyCardID: 'hs1195',
    image: 'dumutefguard.jpg',
    portraitPattern: 'dumutefguard-portrait',
    general: 'utgar',
    race: 'fiantooth',
    type: 'common hero',
    cardClass: 'guard',
    personality: 'relentless',
    height: '7',
    heightClass: 'large',
    life: '1',
    move: '6',
    range: '1',
    attack: '4',
    defense: '4',
    points: '25',
    figures: '1',
    hexes: '2',
    setWave: 'Terrain Set 1: Road to the Forgotten Forest',
    abilities: [
      {
        name: 'Road Strength',
        desc: "Add 1 to Dumutef Guard's attack and defense while on a road space.",
      },
      {
        name: 'Devourer Attack Enhancement',
        desc: 'All friendly Devourers adjacent to a Dumutef Guard receive an additional attack die.',
      },
    ],
  },
  {
    name: 'Obsidian Guards',
    singleName: 'Obsidian Guard',
    armyCardID: 'hs1196',
    image: 'obsidianguards.jpg',
    portraitPattern: 'obsidianguards-portrait',
    general: 'utgar',
    race: 'moltarn',
    type: 'common squad',
    cardClass: 'guards',
    personality: 'ferocious',
    height: '5',
    heightClass: 'medium',
    life: '1',
    move: '4',
    range: '1',
    attack: '4',
    defense: '4',
    points: '100',
    figures: '3',
    hexes: '1',
    setWave: 'Terrain Set 2: Volcarren Wasteland',
    abilities: [
      {
        name: 'Lava Resistant',
        desc: 'Obsidian Guards never roll for molten lava damage or lava field damage and they do not have to stop in molten lava spaces.',
      },
      {
        name: 'Lava Throw',
        desc: 'When an Obsidian Guard is on a molten lava space it may add 2 to its range.',
      },
      {
        name: 'Water Weakness',
        desc: 'An Obsidian Guard on a water space rolls 2 fewer defense dice.',
      },
    ],
  },
  {
    name: 'Dzu-Teh',
    singleName: 'Dzu-Teh',
    armyCardID: 'hs1197',
    image: 'dzuteh.jpg',
    portraitPattern: 'dzuteh-portrait',
    general: 'jandar',
    race: 'Dzu-Teh',
    type: 'common squad',
    cardClass: 'hunters',
    personality: 'wild',
    height: '6',
    heightClass: 'medium',
    life: '1',
    move: '5',
    range: '1',
    attack: '4',
    defense: '3',
    points: '75',
    figures: '3',
    hexes: '1',
    setWave: 'Terrain Set 3: Thaelenk Tundra',
    abilities: [
      {
        name: 'Glacier Traverse',
        desc: 'If a Dzu-Teh is adjacent to a Glacier Mountain, the Dzu-Teh may Glacier Traverse instead of moving normally. You may do this with any or all Dzu-Teh you control each turn. To Glacier Traverse, move to any unoccupied space adjacent to that Glacier Mountain.',
      },
      {
        name: 'Glacier Camouflage',
        desc: "If a Dzu-Teh is adjacent to a Glacier Mountain, opponents' figures must be adjacent to that Dzu-Teh to attack it with a normal attack.",
      },
      {
        name: 'Snow and Ice Enhanced Movement',
        desc: 'Slippery Ice and Heavy Snow only count as 1 space when moving (Whereas other figures would have to count them as 2 spaces).',
      },
    ],
  },
  {
    name: 'Fyorlag Spiders',
    singleName: 'Fyorlag Spider',
    armyCardID: 'hs1198',
    image: 'fyorlagspiders.jpg',
    portraitPattern: 'fyorlagspiders-portrait',
    general: 'aquilla',
    race: 'scouts',
    type: 'common squad',
    cardClass: 'wild',
    personality: 'merciful',
    height: '2',
    heightClass: 'small',
    life: '1',
    move: '7',
    range: '1',
    attack: '2',
    defense: '2',
    points: '40',
    figures: '3',
    hexes: '1',
    setWave: 'Terrain Set 4: Ticalla Jungle',
    abilities: [
      {
        name: 'Climb X3',
        desc: 'When moving up or down levels of terrain, Fyorlag Spiders may triple their height.',
      },
      {
        name: 'Entangling Web',
        desc: "After moving and before attacking, you may choose any one small or medium opponent's figure that is engaged with at least three Fyorlag Spiders that you control. Roll the 20-sided die. If you roll a 16 or higher, remove one unrevealed order marker at random from the chosen figure's Army Card (or cards if your opponent has more than one Common Army Card for that figure).",
      },
      {
        name: 'Predator Bonding',
        desc: 'Before taking a turn with Fyorlag Spiders, you may first take a turn with any Predator you control.',
      },
    ],
  },
  {
    name: 'Master Win Chiu Woo',
    singleName: 'Master Win Chiu Woo',
    armyCardID: 'hs1199',
    image: 'masterwinchiuwoo.jpg',
    portraitPattern: 'masterwinchiuwoo-portrait',
    general: 'aquilla',
    race: 'human',
    type: 'unique hero',
    cardClass: 'monk',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '4',
    defense: '4',
    points: '140',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release',
    abilities: [
      {
        name: "Master's Influence",
        desc: 'All Monk squad figures you control may leap an additional 13 levels up or down when using their Stealth Leap special power. All Monks you control within 2 clear sight spaces of Master Woo add 1 to their attack dice and 1 to their defense dice.',
      },
      {
        name: "Master's Assault",
        desc: 'Master Woo may attack any or all figures adjacent to him. Roll each attack seperately.',
      },
      {
        name: 'Stealth Leap 25',
        desc: 'Instead of his normal move, Master Woo may use Stealth Leap 25. Stealth Leap 25 has a move of 3. When counting spaces for Stealth Leap 25, ignore elevations. Master Woo may leap over water without stopping, leap over figures without becoming engaged, and leap over obstacles such as ruins. Master Woo may not leap more than 25 levels up or down in a single leap. If Master Woo is engaged when he starts his Stealth Leap, he will not take any leaving engagement attacks.',
      },
    ],
  },
  {
    name: 'Agent Skahen',
    singleName: 'Agent Skahen',
    armyCardID: 'hs1200',
    image: 'agentskahen.jpg',
    portraitPattern: 'agentskahen-portrait',
    general: 'vydar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'agent',
    personality: 'tricky',
    height: '4',
    heightClass: 'medium',
    life: '3',
    move: '6',
    range: '7',
    attack: '3',
    defense: '3',
    points: '120',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release',
    abilities: [
      {
        name: 'Double Attack',
        desc: 'When Agent Skahen attacks, she may attack one additional time.',
      },
      {
        name: 'Cover Fire',
        desc: 'When attacking an opponents figure, if Agent Skahen inflicts one or more wounds, you may move one Tricky figure you control who follows Vydar and is within 8 clear sight spaces of Agent Skahen up to 4 spaces. You cannot use cover fire to move Agent Skahen.',
      },
      {
        name: 'Stealth Dodge',
        desc: 'When Agent Skahen rolls defense dice against an attacking figure who is not adjacent, one shield will block all damage.',
      },
    ],
  },
  {
    name: 'Sir Gilbert',
    singleName: 'Sir Gilbert',
    armyCardID: 'hs1201',
    image: 'sirgilbert.jpg',
    portraitPattern: 'sirgilbert-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '1',
    attack: '3',
    defense: '4',
    points: '105',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: "Jandar's Dispatch",
        desc: 'After you take a turn with Sir Gilbert, roll 12 Jandar Valkyrie Dice. You may move up to 4 squad figures you control who follow Jandar up to X spaces. X equals the number of Jandar symbols rolled. Any squad figures moved with Jandar’s Dispatch must be within 8 clear sight spaces of Sir Gilbert prior to moving.',
      },
      {
        name: 'Attack Aura 1',
        desc: 'All friendly figures adjacent to Sir Gilbert with a range of 1 add 1 die to their normal attack',
      },
    ],
  },
  {
    name: 'Acolarh',
    singleName: 'Acolarh',
    armyCardID: 'hs1202',
    image: 'acolarh.jpg',
    portraitPattern: 'acolarh-portrait',
    general: 'ullar',
    race: 'elf',
    type: 'unique hero',
    cardClass: 'wizard',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '3',
    defense: '2',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: 'Leaf of the Home Tree Aura',
        desc: 'When any Elf you control within 8 clear sight spaces of Acolarh receives enough wounds to be destroyed, you may roll 10 Ullar Valkyrie dice before removing the figure. If you roll at least 3 Ullar symbols, ignore any wounds that figure just received. Acolarh’s Leaf of the Home Tree Aura does not affect Acolarh',
      },
      {
        name: "Ullar's Amulet",
        desc: 'All friendly figures who follow Ullar and start their turn adjacent to Acolarh may move 2 additional spaces.',
      },
    ],
  },
  {
    name: 'Laglor',
    singleName: 'Laglor',
    armyCardID: 'hs1203',
    image: 'laglor.jpg',
    portraitPattern: 'laglor-portrait',
    general: 'vydar',
    race: 'primadon',
    type: 'unique hero',
    cardClass: 'alphallon',
    personality: 'precise',
    height: '5',
    heightClass: 'medium',
    life: '6',
    move: '5',
    range: '7',
    attack: '3',
    defense: '3',
    points: '110',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: 'Vydar’s Range Enhancement Aura',
        desc: 'All friendly figures with a range of 4 or more who follow Vydar and are within 4 clear sight spaces of Laglor add 2 spaces to their Range number. Vydar’s Range Enhancement Aura does not affect Laglor.',
      },
      {
        name: 'Autoload Special Attack',
        desc: 'Range 7. Attack 3. When attacking with Autoload Special Attack, you may roll Vydar Valkyrie Dice. If you roll at least one Vydar symbol, you may attack again using Autoload Special Attack.',
      },
    ],
  },
  {
    name: 'Hatamoto Taro',
    singleName: 'Hatamoto Taro',
    armyCardID: 'hs1204',
    image: 'hatamototaro.jpg',
    portraitPattern: 'hatamototaro-portrait',
    general: 'einar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'samurai',
    personality: 'disciplined',
    height: '5',
    heightClass: 'medium',
    life: '5',
    move: '5',
    range: '1',
    attack: '2',
    defense: '2',
    points: '130',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: 'Heroic Defense Aura',
        desc: 'When defending against a normal attack by an adjacent figure with any Samurai or Ashigaru figures you control within 8 clear sight spaces of Hatamoto Taro, you may roll Einar Valkyrie dice. Each Einar symbol rolled counts as an additional shield. Heroic Defense Aura does not affect Hatamoto Taro.',
      },
      {
        name: 'Adjacent Tough 1',
        desc: 'When rolling defense dice for Hatamoto Taro, if Hatamoto Taro is adjacent to at least one figure who follows Einar that you control, add one automatic shield to the defense roll.',
      },
    ],
  },
  {
    name: 'Ornak',
    singleName: 'Ornak',
    armyCardID: 'hs1205',
    image: 'ornak.jpg',
    portraitPattern: 'ornak-portrait',
    general: 'utgar',
    race: 'orc',
    type: 'unique hero',
    cardClass: 'champion',
    personality: 'wild',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '6',
    range: '1',
    attack: '3',
    defense: '3',
    points: '100',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: 'Red Flag of Fury Aura',
        desc: 'If Order Marker 1 is placed on Ornak, then instead of taking that turn with Ornak, you may take a turn with up to 2 Unique Heroes you control who follow Utgar. Ornak cannot be one of the 2 Unique Heroes. Any Unique Hero that is taking a turn instead of Ornak must be within 8 clear sight spaces of Ornak prior to its movement.',
      },
      {
        name: 'Orc Battle Cry',
        desc: 'When attacking with any Orc Warrior figures you control adjacent to Ornak, you may roll Utgar Valkyrie dice. Each Utgar symbol rolled counts for an additional skull.',
      },
    ],
  },
  {
    name: 'Samuel Brown',
    singleName: 'Samuel Brown',
    armyCardID: 'hs1206',
    image: 'samuelbrown.jpg',
    portraitPattern: 'samuelbrown-portrait',
    general: 'jandar',
    race: 'human',
    type: 'unique hero',
    cardClass: 'patriot',
    personality: 'valiant',
    height: '5',
    heightClass: 'medium',
    life: '4',
    move: '5',
    range: '7',
    attack: '3',
    defense: '3',
    points: '60',
    figures: '1',
    hexes: '1',
    setWave: 'Special Release: Crest of the Valkyrie',
    abilities: [
      {
        name: 'Fire And Rush Special Attack',
        desc: 'Range: Special. Attack: Special. If Samuel Brown begins his turn unengaged, instead of moving normally, he may use his Fire and Rush Special Attack. Choose a figure within 5 spaces of Samuel Brown to attack and roll 3 attack dice. If the defending figure receives one or more wounds from that attack, you may move Samuel Brown up to 5 spaces. If Samuel Brown ends that move engaged, he may attack again by rolling 4 attack dice.',
      },
    ],
  },
]
export const MS1Cards: ICoreHeroscapeCard[] =
  coreHeroscapeCards.filter(filterMasterSetROTV)
export const MS1Heroes = MS1Cards.filter(filterHero)
export const MS1Squads = MS1Cards.filter(filterSquad)

export const neGokSa = MS1Cards.find((unit) => unit.name === 'Ne-gok-sa')
export const marroWarriors = MS1Cards.find(
  (unit) => unit.name === 'Marro Warriors'
)
export const mimring = MS1Cards.find((unit) => unit.name === 'Mimring')
export const syvarris = MS1Cards.find((unit) => unit.name === 'Syvarris')
export const sgtDrake = MS1Cards.find((unit) => unit.armyCardID === 'hs1003')
export const deathwalker9000 = MS1Cards.find(
  (unit) => unit.name === 'Deathwalker 9000'
)

export const agentCarr = MS1Cards.find((unit) => unit.name === 'Agent Carr')
export const kravMagaAgents = MS1Cards.find(
  (unit) => unit.name === 'Krav Maga Agents'
)

function filterMasterSetROTV(card: ICoreHeroscapeCard) {
  return card.setWave === 'Master Set: Rise of the Valkyrie'
}
function filterHero(card: ICoreHeroscapeCard) {
  return card.type.includes('hero')
}
function filterSquad(card: ICoreHeroscapeCard) {
  return card.type.includes('squad')
}
